import React, { useState } from "react";
import { Button as MuiButton, InputAdornment, Stack } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import validateURL from "../utils/validateURL";

import { Button } from "../basic/Button";
import { TextField } from "../basic/TextField";

function ensureHttps(url) {
  if (!url.startsWith('https://')) {
    return 'https://' + url;
  }
  return url;
}

const LinkInsert = (props) => {
  
  const [state, setState] = useState({input: "", error: false});

  const onTextChange = (event) => {
    if(validateURL(event.currentTarget.value)) {
        setState({
          ...state, 
          input: ensureHttps(event.currentTarget.value), 
          error: false
        });
    } else {
        setState({...state, error: true})
    }
  }

  const onClickHandlerValidated = () => {
    if(validateURL(state.input)) {
        props?.onClickHandler(state.input);
        props.callback && props.callback();
    } else {
        alert("Not a valid URL")
    }
  }


  return (
    <Stack spacing={2}>
      <TextField
        size="small"
        error={state.error}
        fullWidth
        placeholder="Link"
        onChange={onTextChange}
        onBlur={onTextChange}
        defaultValue={"https://"}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LinkIcon />
            </InputAdornment>
          ),
        }}
      />
      <Button disabled={state.error} onClick={onClickHandlerValidated}>Add link to post</Button>
    </Stack>
  );
};

export default LinkInsert;
