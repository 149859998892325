const getActivity = (id) => `query MyQuery {
  getActivity(id: "${id}") {
    id
    createdByID
    name
    description
    currency
    price
    address
    addressLine
    activityStart
    activityEnd
    conditions
    fromAge
    toAge
    numberOfParticipants
    status
    privacy
    interest {
      id
      parentID
      title
      parentInterest {
        id
        title
      }
    }
    userObject {
      familyName
      givenName
      id
      images(limit: 1) {
        items {
          url
        }
      }
      interests {
        selectedInterests
      }
      numberOfVotes
      numberOfInvitesSent
      status
    }
    paymentMethod
    paymentLink
    locationType
    longitude
    latitude
    locationDescription
    link {
      title
      url
      info
      imageUrl
    }
    images {
      items {
        url
        index
        id
      }
    }
    languageIDS
    city
    cancellationReason
    activityAttendance {
      items {
        id
        status
        sumOfVotes
        user {
          familyName
          givenName
          id
          images(limit: 1) {
            items {
              url
            }
          }
        }
      }
    }
  }
}`;


export default getActivity;