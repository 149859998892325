import React from "react";
import { Box, Container } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

/**
 * Primary UI component for user interaction
 */
export const SplashScreen = ({ ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#fff", // Set background color as needed
        }}
      >
        <img
          src={`${process.env.REACT_APP_WEBSITE}/sociogram_splash_4x.png`} // Replace with the path to your 64x64 logo
          alt="Logo"
          style={{ width: 128 }}
        />
      </Box>
    </ThemeProvider>
  );
};

// export const SplashScreenLayout = (props) => {
//   return (
//     <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
//       <SplashScreen {...props} />
//     </ResponsiveLayoutSidebar>
//   )
// }

SplashScreen.propTypes = {};

SplashScreen.defaultProps = {
  color: "primary",
};
