const sendChatMessageToUser = (text, receiverUserID) => `mutation MyMutation {
  sendChatMessageToUser(input: {messageToken: "", type: TEXT, content: {text: "${text}", data: ""}, receiverUserID: "${receiverUserID}"}) {
    id
    messageToken
    content {
      data
      text
    }
    createdByID
    updatedAt
    createdAt
    type
    channelID
    createdBy {
      email
      familyName
      givenName
      id
      images {
        items {
          id
          index
          url
        }
      }
      phoneNumber
    }
    channel {
      createdAt
      updatedAt
      id
      type
      name
      activityID
      clanID
      createdByID
      latestMessageAt
      activity {
        activityEnd
        activityStart
        status
      }
      clan {
        images {
          items {
            id
            index
            url
          }
        }
      }
      chatChannelMembers {
        items {
          user {
            email
            id
            familyName
            givenName
            phoneNumber
            numberOfVotes
            sumOfVotes
            images {
              items {
                id
                url
                index
              }
            }
            contacts(filter: {contactID: {eq: "$userID"}}){
              nextToken
              items {
                contactID
                requesterID
                status
                contact {
                  givenName
                  familyName
                  id
                  email
                  phoneNumber
                  status
                  numberOfVotes
                  sumOfVotes
                }
              }
            }
          }
          id
          userID
          channelID
          createdAt
          updatedAt
          status
          role
        }
      }
      latestMessage {
        content {
          data
          text
        }
        createdAt
        updatedAt
        type
        createdByID
        id
        channelID
        messageToken
        createdBy {
          email
          familyName
          givenName
          id
          phoneNumber
          images {
            items {
              id
              index
              url
            }
          }
        }
      }
    }
  }
}`

export default sendChatMessageToUser;