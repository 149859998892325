/* src/Router.js */
import React, { useContext } from "react";
import {
  createBrowserRouter,
  Navigate,
} from "react-router-dom";
import AppContext from "./AppContext.js";
import Protected from "./Protected.js";
import { SignIn } from "./stories/screens/SignIn.jsx";
import { LoadingScreen } from "./stories/screens/LoadingScreen.jsx";
import { HomeScreenLayout } from "./stories/screens/HomeScreen.jsx";
import { PostViewScreenLayout } from "./stories/screens/PostViewScreen.jsx";
import { CommentsScreenLayout } from "./stories/screens/CommentsScreen.jsx";
import { InviteContactsScreenLayout } from "./stories/screens/InviteContactsScreen.jsx";
import { CreateActivityScreenLayout } from "./stories/screens/CreateActivityScreen.jsx";
import { EditActivityScreenLayout } from "./stories/screens/EditActivityScreen.jsx";
import { ViewActivityScreenLayout } from "./stories/screens/ViewActivityScreen.jsx";
import {
  UpdatePhotosScreenLayout,
  UpdatePhotosScreenOnboardingLayout,
} from "./stories/screens/UpdatePhotosScreen.jsx";
import { ActivityPrivacyLayout } from "./stories/screens/ActivityPrivacy.jsx";
import { ActivityCreatedScreenLayout } from "./stories/screens/ActivityCreatedScreen.jsx";
import { PostCreatedScreenLayout } from "./stories/screens/PostCreatedScreen.jsx";
import { ProfileCreatedScreenOnboardingLayout } from "./stories/screens/ProfileCreatedScreen.jsx";
import { CreatePostScreenLayout } from "./stories/screens/CreatePostScreen.jsx";
import { CategorySelectionScreenLayout } from "./stories/screens/CategorySelectionScreen.jsx";
import { SubcategorySelectionScreenLayout } from "./stories/screens/SubcategorySelectionScreen.jsx";
import { ProfileInformationScreenLayout } from "./stories/screens/ProfileInformation.jsx";
import { SelectLocationScreenLayout } from "./stories/screens/SelectLocationScreen.jsx";
import { SelectInterestsScreenOnboardingLayout } from "./stories/screens/SelectInterestScreen.jsx";
import { SubcategorySelectionScreenOnboardingLayout } from "./stories/screens/SubcategorySelectionScreen.jsx";
import { EditProfileScreenLayout } from "./stories/screens/EditProfileScreen.jsx";
import { EditProfileScreenOnboardingLayout } from "./stories/screens/EditProfileScreen.jsx";
import { PhotosScreenLayout } from "./stories/screens/PhotosScreen.jsx";
import {
  InternationalCodeSelectionScreenLayout,
  InternationalCodeSelectionScreenLayoutMain,
} from "./stories/screens/InternationalCodeSelectionScreen.jsx";
import { PhoneNumberScreenLayout } from "./stories/screens/PhoneNumberScreen.jsx";
import { OTPScreenLayout } from "./stories/screens/OTPScreen.jsx";
import { SearchActivitiesLayout } from "./stories/screens/SearchActivities.jsx";
import { SearchPostsLayout } from "./stories/screens/SearchPosts.jsx";
import { SearchSiteScreenLayout } from "./stories/screens/SearchSiteScreen.jsx";
import { ChatScreenLayout } from "./stories/screens/ChatScreen.jsx";
import { MessagesScreenLayout } from "./stories/screens/MessagesScreen.jsx";
import { ContactsScreenLayout } from "./stories/screens/ContactsScreen.jsx";
import { InvitationsScreenLayout } from "./stories/screens/InvitationsScreen.jsx";
import { ProfilePageScreenLayout } from "./stories/screens/ProfilePageScreen.jsx";
import { AddPeopleScreen } from "./stories/screens/AddPeopleScreen.jsx";
import { ChatNotificationsScreenLayout } from "./stories/screens/ChatNotificationsScreen.jsx";
import { NotificationsScreenLayout } from "./stories/screens/NotificationsScreen.jsx";
import { SelectLanguageScreenLayout } from "./stories/screens/SelectLanguageScreen.jsx";
import ScrollToTop from "./ScrollToTop.js";

import { SplashScreen } from "./stories/screens/SplashScreen.jsx";
import AuthenticatedRoute from "./AuthenticatedRoute.js";
import Dial from "./stories/complex/Dial.js";

import { Alert, Typography } from "@mui/material";
import { Page404Screen, Page404ScreenLayout } from "./stories/screens/Page404Screen.jsx";

const AppShell = (props) => {
  const [globalState, setGlobalState, q, p, connectionStatus] =
    useContext(AppContext);

  return (
    <>
      <ScrollToTop />

      <Alert
        severity={connectionStatus === 0 ? "error" : "success"}
        sx={{
          overflow: "hidden",
          margin: 0,
          paddingY: `0px !important`,
          transition: "all 0.5s",
          position: "fixed",
          top: connectionStatus > -1 ? 0 : -40,
          width: "calc(100% - 16px)",
          zIndex: 10000,
        }}
      >
        <Typography variant="caption">
          {connectionStatus !== 0
            ? "You're back online"
            : "You're currently offline"}
        </Typography>
      </Alert>
      <div
        style={{
          transition: "all 0.5s",
          marginTop: connectionStatus > -1 ? 40 : 0,
        }}
      >
        {props.children}
      </div>
    </>
  );
};

const SignInSwitch = (props) => {
  const [
    globalState,
    setGlobalState,
    q,
    p,
    connectionStatus,
    isBackgroundLoaded,
  ] = useContext(AppContext);

  return globalState?.userData?.email ? (
    <Navigate to="/" replace />
  ) : isBackgroundLoaded ? (
    <SignIn />
  ) : (
    <SplashScreen />
  );
};

const IDPResponse = (props) => {
  const [
    globalState,
    setGlobalState,
    q,
    p,
    connectionStatus,
    isBackgroundLoaded,
  ] = useContext(AppContext);
  return globalState.userData?.email ? (
    <Navigate to="/profile-information" replace />
  ) : (
    <LoadingScreen />
  );
};

const dataRouter = createBrowserRouter([
  {
    path: "/",
    element: (
      <AppShell>
        <AuthenticatedRoute />
      </AppShell>
    ), // Authentication or layout component
    children: [
      {
        path: "/",
        element: <HomeScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/edit-profile",
        element: <EditProfileScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/edit-profile/photos",
        element: <UpdatePhotosScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/edit-activity/category",
        element: <CategorySelectionScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/edit-activity/category/:subcategory",
        element: <SubcategorySelectionScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/comments/:id",
        element: <CommentsScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/create-activity",
        element: <CreateActivityScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/create-activity/category",
        element: <CategorySelectionScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/create-activity/category/:subcategory",
        element: <SubcategorySelectionScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/create-activity/select-location",
        element: <SelectLocationScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/create-activity/select-language",
        element: <SelectLanguageScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/create-activity/photos",
        element: <UpdatePhotosScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/create-activity/privacy",
        element: <ActivityPrivacyLayout waitForUserData="yes" />,
      },
      {
        path: "/create-activity/share/:id",
        element: <ActivityCreatedScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/activity/:id",
        element: <ViewActivityScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/edit-activity/:id",
        element: <EditActivityScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/edit-activity/photos",
        element: <UpdatePhotosScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/edit-activity/privacy",
        element: <ActivityPrivacyLayout waitForUserData="yes" />,
      },
      {
        path: "/edit-activity/select-location",
        element: <SelectLocationScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/edit-activity/select-language",
        element: <SelectLanguageScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/invite",
        element: <InvitationsScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/create-post",
        element: <CreatePostScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/create-post/search-activities",
        element: <SearchActivitiesLayout waitForUserData="yes" />,
      },
      {
        path: "/create-post/search-posts",
        element: <SearchPostsLayout waitForUserData="yes" />,
      },
      {
        path: "/create-post/add-people",
        element: <AddPeopleScreen waitForUserData="yes" />,
      },
      {
        path: "/create-post/share/:post",
        element: <PostCreatedScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/posts/:post",
        element: <PostViewScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/notifications",
        element: <NotificationsScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/messages0",
        element: <ChatNotificationsScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/messages",
        element: <MessagesScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/contacts",
        element: <ContactsScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/contacts/add",
        element: <InviteContactsScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/contacts/add/phone",
        element: (
          <InternationalCodeSelectionScreenLayoutMain waitForUserData="yes" />
        ),
      },
      {
        path: "/chat/:id",
        element: <ChatScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/profile/:id",
        element: <ProfilePageScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/update-photos",
        element: <UpdatePhotosScreenLayout waitForUserData="yes" />,
      },
      {
        path: "/404",
        element: <Page404ScreenLayout />
      },
    ],
  },
  {
    path: "/search",
    element: <SearchSiteScreenLayout />,
  },
  {
    path: "/splash",
    element: <SplashScreen />,
  },
  {
    path: "/loading",
    element: <LoadingScreen />,
  },
  {
    path: "/dial",
    element: <Dial />,
  },
  {
    path: "/photos/:id",
    element: <PhotosScreenLayout />,
  },
  {
    path: "/signin",
    element: <SignInSwitch />,
  },
  {
    path: "/phone-number",
    element: <PhoneNumberScreenLayout />,
  },
  {
    path: "/profile-information",
    element: <ProfileInformationScreenLayout waitForUserData="yes" />,
  },
  {
    path: "/profile-information/bio",
    element: <EditProfileScreenOnboardingLayout waitForUserData="yes" />,
  },
  {
    path: "/profile-information/photos/update",
    element: <UpdatePhotosScreenOnboardingLayout waitForUserData="yes" />,
  },
  {
    path: "/profile-information/select-interests",
    element: <SelectInterestsScreenOnboardingLayout waitForUserData="yes" />,
  },
  {
    path: "/profile-information/select-interests/:subcategory",
    element: (
      <SubcategorySelectionScreenOnboardingLayout waitForUserData="yes" />
    ),
  },
  {
    path: "/welcome",
    element: <ProfileCreatedScreenOnboardingLayout waitForUserData="yes" />,
  },
  {
    path: "/country-selection",
    element: <InternationalCodeSelectionScreenLayout />,
  },
  {
    path: "/otp",
    element: <OTPScreenLayout />,
  },
  {
    path: "/oauth2/idpresponse",
    element: <IDPResponse />,
  },
  {
    path: "/protected",
    element: <Protected />,
  },
]);

export default dataRouter;
