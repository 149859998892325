import React from 'react';
import { Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { ProgressCircles } from '../basic/ProgressCircles';

const theme = createTheme({
    palette: {
    },
    components: {
        // MuiTextField: {
        //   styleOverrides: {
        //     root: {
        //     },
        //   },
        // },
    },
});

/**
 * Primary UI component for user interaction
 */
export const LoadingScreen = ({ ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: '100%',
          minHeight: '100vh',
          minWidth: '100vw',
          boxSizing: 'border-box',
          width: '100%', 
          background: `url(${process.env.REACT_APP_WEBSITE}/bg-2.jpg)`,
          backgroundSize: 'cover',
          position: 'relative'
        }}
      >
      <Box sx={{
          width: '100%', 
          minHeight: '100vh', 
          height: '100%', 
          position: 'absolute', 
          top: 0, 
          left: 0, 
          background: 'rgba(45, 156, 219, 0.5)',
        }}/>

        <Box sx={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}><ProgressCircles /></Box>
      </Box>
    </ThemeProvider>
  );
};

LoadingScreen.propTypes = {
};

LoadingScreen.defaultProps = {
  color: 'primary',
};
