const inviteToActivity = (activityID, userIDS) => `mutation MyMutation {
  inviteToActivity(input: {
        activityID: "${activityID}", 
        userIDS: ${userIDS}
    }) {
    id
  }
}`;

export default inviteToActivity;
