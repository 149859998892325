import React, { useEffect, useState } from 'react';
import {Link as ReactLink} from 'react-router-dom';
import { Container } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import {
  fetchAuthSession,
  getCurrentUser,
  fetchUserAttributes,
} from 'aws-amplify/auth';
import './AWSAuthenticator.css'

import { Button } from '../basic/Button';
import PhoneIcon from '@mui/icons-material/Phone';

const theme = createTheme({
  palette: {
  },
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

/**
 * Primary UI component for user interaction
 */
export const AWSAuthenticator = ({ ...props }) => {

  const formFields = {
    signIn: {
      username: {
        placeholder: 'Enter Your Email Here!!!',
        isRequired: true,
        label: 'Email:',
        dialCode: '+227'
      },
    },
    signUp: {
      phone_number: {
        dialCode: '+227',
        label: "BBB",
        placeholder: "AAA"
      }
    }
  }

  useEffect(() => {
    // console.log("AD")
    checkUser()
  }, [])

  const [user, setUser] = useState({})
  async function checkUser() {
    try {
      const {
        username,
        // authFlowType: authenticationFlowType
      } = await getCurrentUser();

      const {
        email
      } = await fetchUserAttributes();


      // Note that session will no longer contain refreshToken and clockDrift
      const {
        tokens: session
      } = await fetchAuthSession();

      const userInfo = {
        username,
        email,
        phone_number: ""
      }

      setUser(userInfo)
    } catch (err) { console.log('error: ', err.message) }
  }
  return (
    <ThemeProvider theme={theme}>
      <Container>
        {/* <h1>Profile</h1>
        <h2>Username: {user.username}</h2>
        <h3>Email: {user.email}</h3>
        <h4>Phone: {user.phone_number}</h4> */}

        <Button 
        component={ReactLink}
        to="/phone-number"
        startIcon={PhoneIcon} sx={{height: 56, width: '100%', marginBottom: '12px'}} 
        size={'large'}>Sign {props.signUp ? 'up' : 'in'} with Phone</Button>

        {props.signUp && <Authenticator
          initialState="signUp"
          // hideSignUp={true}
        >
        </Authenticator>}

      </Container>
    </ThemeProvider>
  );

};

AWSAuthenticator.propTypes = {
};

AWSAuthenticator.defaultProps = {
  color: 'primary',
};
