import React, { useContext, useEffect, useRef, useState } from "react";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  Skeleton,
} from "@mui/material";
import AppContext from "../../AppContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import { Button } from "../basic/Button";
import { SearchTextField } from "../basic/SearchTextField";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import { LanguagesList } from "../complex/LanguagesList.jsx";
import Search from "@mui/icons-material/Search";
import Fuse from "fuse.js";
import listLanguages from "./queries/listLanguages";
import { generateClient } from "aws-amplify/api";
const client = generateClient();

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

/**
 * Primary UI component for user interaction
 */
export const SelectLanguageScreen = ({ ...props }) => {
  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const [state, setState] = useState({
    loading: true,
    submission: p.submission,
  });
  const navigate = useNavigate();

  const fuse = useRef();
  useEffect(() => {
    if (state.languages && !fuse.search) {
      fuse.current = new Fuse(state.languages, {
        threshold: 0.2,
        keys: ["name", "nativeName"],
      });
    }
  }, [state.languages]);

  useEffect(() => {
    (async () => {
      p.prevPage = p.currentPage || "select-languages";
      p.currentPage = "select-languages";

      try {
        const res = await client.graphql({
          query: listLanguages(),
        });
        setState({
          ...state,
          languages: res.data?.listLanguages?.items?.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          }),
          loading: false,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        setState({
          ...state,
          loading: false,
        });
      }
    })();
  }, []);

  const handleLanguageSearch = (event) => {
    setState({
      ...state,
      searchPattern: event.currentTarget.value,
      data: event.currentTarget.value
        ? fuse?.current?.search(event.currentTarget.value)
        : [],
    });
  };

  const onSelectItem = (event) => {
    const val = event.currentTarget.getAttribute("value");
    const id = event.currentTarget.getAttribute("id");

    const newLanguageSelection = p.submission?.selectedLanguages
      ? [...p.submission?.selectedLanguages]
      : [];

    const newLanguageSelectionNamed = p.submission?.selectedLanguagesNamed
      ? [...p.submission?.selectedLanguagesNamed]
      : [];

    // If language was selected before, deselect it
    if (newLanguageSelection.includes(id)) {
      const foundIndex = newLanguageSelection.indexOf(id);
      newLanguageSelection.splice(foundIndex, 1);
      newLanguageSelectionNamed.splice(foundIndex, 1);
    }
    // Otherwise, add it to the selection
    else {
      newLanguageSelection.push(id);
      newLanguageSelectionNamed.push(val);
    }

    // Update the global state
    p.submission = {
      ...p.submission,
      abc: 123,
      languageIDS: newLanguageSelection,
      selectedLanguages: newLanguageSelection,
      selectedLanguagesNamed: newLanguageSelectionNamed,
    };

    setState({
      ...state,
      submission: {
        ...state.submission,
        abc: 123,
        languageIDS: newLanguageSelection,
        selectedLanguages: newLanguageSelection,
        selectedLanguagesNamed: newLanguageSelectionNamed,
      },
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"sm"}>
        <Box>
          <ScreenNavHeader title="Select Language">
            <Button component={ReactLink} onClick={() => navigate(-1)}>
              Confirm
            </Button>
          </ScreenNavHeader>
        </Box>
        <Box mb={2.5}>
          <SearchTextField
            fullWidth={true}
            size="medium"
            placeholder="Search"
            sx={{ borderRadius: "100%" }}
            onChange={handleLanguageSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="Search" edge="end">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Container>

      <Box>
        {state.loading && (
          <Box mt={4}>
            {(() => {
              let arr = [];
              for (let i = 0; i < 20; i++) arr.push(i);
              return arr;
            })().map((v, i) => (
              <Skeleton
                variant="rounded"
                sx={{
                  height: 32,
                  width: "calc(100% - 48px)",
                  margin: "0 auto 16px auto",
                }}
              ></Skeleton>
            ))}
          </Box>
        )}
        {!state.loading && (
          <LanguagesList
            currentCategory={state?.submission?.currentCategory}
            filtered={state.data?.length > 0}
            filteredData={state.data}
            languages={state.languages}
            selectedLanguages={state?.submission?.selectedLanguages}
            onSelectItem={onSelectItem}
          />
        )}
      </Box>
    </ThemeProvider>
  );
};

export const SelectLanguageScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
      <SelectLanguageScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

SelectLanguageScreen.propTypes = {};

SelectLanguageScreen.defaultProps = {
  color: "primary",
};
