const updateUser = (input) => `
mutation MyMutation {
  updateUser(${input}) {
    id
    createdAt
    onboardingStatus
    address
    bio
    city
    email
    gender
    givenName
    phoneNumber
    relationshipStatus
    familyName
    birthday
    longitude
    latitude
    sumOfVotes
    numberOfVotes
    numberOfInvitesSent
    userContactIDs
    contacts {
      items {
        id
        contactID
        status
        sortedUserIDSPK
      }
    }
    languages {
      items {
        language {
          name
          id
        }
      }
    }
    images {
      items {
        index
        url
        id
      }
    }
    interests {
      categories {
        id
        interests {
          id
          title
          type
        }
        title
        type
      }
      selectedInterests
    }
    invitesSent {
      items {
        phoneNumber
        status
      }
    }
    activity(sortDirection: DESC) {
      items {
        id
        name
        address
        activityStart
        activityEnd
        numberOfParticipants
        locationDescription
        privacy
        createdByID
        interest {
          title
          parentInterest {
            id
            title
          }
        }
        activityFeedback {
          items {
              feedback
              createdAt
            }
          }
          userObject {
            id
            familyName
            givenName
            phoneNumber
            numberOfVotes
            sumOfVotes
            images {
              items {
                id
                index
                url
              }
            }
            interests {
              selectedInterests
              categories {
                id
                title
                type
              }
            }
            images {
              items {
                id
                index
                url
              }
            }
          }
          activityAttendance {
            items {
              activityID
              createdByID
              status
              user {
                id
                givenName
                familyName
                phoneNumber
                images {
                  items {
                    id
                    index
                    url
                  }
                }
              }
              id
              initStatus
            }
          }
        }
      nextToken
    }
  }
}`;


// mutation MyMutation {
//   updateUser(
//      input: {givenName: "", familyName: "", email: "", bio: ""}, 
//      condition: {username: {eq: ""}}
//    )
// }

export default updateUser;