import React, { forwardRef } from "react";
import { AppDialog } from "../complex/AppDialog";
import PropTypes from "prop-types";
import DatePicker from "./DatePicker";


const DateDialog = forwardRef(function(props, ref) {
  return (
    <AppDialog
    maxButtonWidth={305}
    {...props}>
      <DatePicker {...props} onChangeCallback={props.onChangeCallback} ref={ ref }/>
    </AppDialog>
  );
})

DateDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default DateDialog