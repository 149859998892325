function isValidURL(string) {
  // Links with space at the end are not valid
  if(string.slice(-1) === " ") return false;
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}

export default isValidURL;