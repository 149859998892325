const createComment = (input) => `mutation CreateCommentMutation {
  createComment(input: ${input}) {
    id
    comment
    createdAt
    userObject {
      id
      givenName
      familyName
      phoneNumber
      numberOfVotes
      sumOfVotes
      email
      images {
        items {
          id
          index
          url
        }
      }
    }
  }
}`;

// {categoryID: "$postID", category: POST, comment: \$comment}

export default createComment;