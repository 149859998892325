import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../AppContext.js";
import { styled } from "@mui/material/styles";
import { uploadData, getUrl } from "aws-amplify/storage";
import { Amplify } from "aws-amplify";
import { Box, Button as MuiButton } from "@mui/material";
import createPresignedURL from "../screens/queries/createPresignedURL.js";
import createImage from "../screens/queries/createImage.js";
import { generateClient, post } from "aws-amplify/api";

const client = generateClient();

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const acceptableFiles = ['jpeg', 'jpg', 'png']

export const UploaderButtonActivity = (props) => {
  const [globalState, setGlobalState] = useContext(AppContext);
  const [state, setState] = useState({loading: false})

  const uploadDataInBrowser = async (event) => {
    if (event?.target?.files) {

      const file = event.target.files[0];
      const slashIndex = file.type.lastIndexOf('/');
      const fileExtension =  file.type.substring(slashIndex + 1).toLowerCase();

      if( !acceptableFiles.includes(fileExtension)) {
        alert("This file format is not allowed.");
        return;
      } 

      console.group("Uploading image to S3 and DynamoDB");
      try {

        // 1. Create presignedURL
        const presignedData = await client.graphql({
          query: createPresignedURL(),
          variables: {
            category: "PROFILE",
            type: "PNG",
          },
        });
        console.log("1. Create presignedURL", presignedData);

        // 2. Upload presigned image
        const uploadDataResult = uploadData({
          key: `/features/user/profile/photos/${presignedData.data.createPresignedURL.fileName}.${fileExtension}`,
          data: file,
          options: {
            "isPublic": true,
            // accessLevel: 'guest',
            onProgress: ({ transferredBytes, totalBytes }) => {
              if (totalBytes) {
                console.log(
                  `Upload progress ${
                    Math.round(transferredBytes / totalBytes) * 100
                  } %`
                );
              }
            },
          },
        });
        console.log('2. Upload presigned URL', uploadDataResult);

        // 3. Update database
        const updateDatabaseResult = await client.graphql({
          query: createImage(
            `"${presignedData.data.createPresignedURL.url}"`, 
            `PROFILE`, 
            `"PUBLISHED"`,
            null,
            null,
            null,
            `"${globalState?.userData?.userId}"`,
            0
          ),
        });
        console.log("3. Update database", updateDatabaseResult);


      } catch (error) {
        console.error("Error uploading data:", error);
      }
      console.groupEnd();
    }
  };

  return (
    <React.Fragment>
      <MuiButton component="label" {...props} sx={{ ...props.sx }}>
        {props.children}
        <VisuallyHiddenInput type="file" onChange={uploadDataInBrowser} />
      </MuiButton>
    </React.Fragment>
  );
};

export default UploaderButtonActivity;
