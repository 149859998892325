import { cleanText } from "../../ui-components/utils";
export default (globalState, mode) => {
  const {
    link,
    activityDescription,
    addressLine,
    activityName,
    activityStart,
    activityEnd,
    activityID,
    city,
    conditions,
    countryKey,
    currency,
    files,
    fromAge,
    interestID,
    interestLocationType,
    languageIDS,
    location,
    numberOfParticipants,
    payment,
    privacy="PUBLIC",
    status,
    toAge,
  } = globalState.submission;


  var queryString = {
    input: {
      description: cleanText(activityDescription),
      name: cleanText(activityName),
      status: status,
      privacy: privacy.toUpperCase(),
      locationType: interestLocationType,
      // "countryKey": countryKey,
      // "city": city,
      activityEnd: activityEnd,
      activityStart: activityStart,
      numberOfParticipants: numberOfParticipants,
      interestID: interestID,
      price: payment?.price || 0,
    },
  };

  if(addressLine) {
    queryString["input"]["addressLine"] = `addressLine: "${cleanText(addressLine)}",`;
  }

  // Optional fields
  if (link?.url) {
    queryString["input"]["link"] = `link: {
    url: "${link.url}"
  },`;
  }

  if (languageIDS?.length > 0) {
    queryString["input"]["languageIDS"] = `languageIDS: [${languageIDS.map(
      (s) => `"${s}"`
    )}],`;
  }

  if (conditions?.length > 0) {
    queryString["input"]["conditions"] = `conditions: [${conditions.map(
      (s) => `"${cleanText(s.content)}"`
    )}],`;
  }

  if (fromAge) {
    queryString["input"]["fromAge"] = `fromAge: ${fromAge},`;
  }

  if (toAge) {
    queryString["input"]["toAge"] = `toAge: ${toAge},`;
  }

  if (payment?.paymentMethod) {
    queryString["input"]["paymentMethod"] = `paymentMethod: ${payment?.paymentMethod
      .replace(" ", "_")
      .toUpperCase()},`;
  }

  if(payment?.link?.url) {
    queryString["input"]["paymentLink"] = `paymentLink: "${payment?.link?.url}",`;
  }

  if (payment?.currency) {
    queryString["input"]["currency"] = `currency: "${payment?.currency}",`;
  }

  if (location?.latitude && location?.longitude) {
    console.log("location", location)
    queryString["input"]["location"] = ``
    + `latitude: ${location.latitude}, `
    + `longitude: ${location.longitude} `
    + (location.q ? `, address: "${cleanText(location.q)}"` : "");
  }

  // console.log(JSON.stringify(queryString));

  const queryStringComplete = mode === "create" ? 
    `mutation NewActivity{
        createActivity(
            input: {
            description: "${cleanText(activityDescription)}",
            name: "${cleanText(activityName)}",
            status: ${status},
            privacy: ${privacy.toUpperCase()},
            locationType: ${interestLocationType},
            activityEnd: "${activityEnd}",
            activityStart: "${activityStart}",
            numberOfParticipants: ${numberOfParticipants},
            interestID: "${interestID}",
            price: ${payment?.price || 0},
            ${queryString["input"]?.["addressLine"] || ""}
            ${queryString["input"]?.["link"] || ""}
            ${queryString["input"]?.["languageIDS"] || ""}
            ${queryString["input"]?.["conditions"] || ""}
            ${queryString["input"]?.["fromAge"] || ""}
            ${queryString["input"]?.["toAge"] || ""}
            ${queryString["input"]?.["paymentMethod"] || ""}
            ${queryString["input"]?.["location"] || ""}
            ${queryString["input"]?.["paymentMethod"] || ""}
            ${queryString["input"]?.["currency"] || ""}
            ${queryString["input"]?.["paymentLink"] || ""}
            }
        )
        {
            id
        } 
    }` : 
    `mutation NewActivity{
      updateActivity(
          input: {
          id: "${activityID}",
          description: "${cleanText(activityDescription)}",
          name: "${cleanText(activityName)}",
          privacy: ${privacy.toUpperCase()},
          locationType: ${interestLocationType},
          activityEnd: "${activityEnd}",
          activityStart: "${activityStart}",
          numberOfParticipants: ${numberOfParticipants},
          interestID: "${interestID}",
          price: ${payment?.price || 0},
          ${queryString["input"]?.["addressLine"] || ""}
          ${queryString["input"]?.["link"] || ""}
          ${queryString["input"]?.["languageIDS"] || ""}
          ${queryString["input"]?.["conditions"] || ""}
          ${queryString["input"]?.["fromAge"] || ""}
          ${queryString["input"]?.["toAge"] || ""}
          ${queryString["input"]?.["paymentMethod"] || ""}
          ${queryString["input"]?.["location"] || ""}
          ${queryString["input"]?.["paymentMethod"] || ""}
          ${queryString["input"]?.["currency"] || ""}
          ${queryString["input"]?.["paymentLink"] || ""}
          }
      )
      {
          id
      } 
  }`

  console.log("queryStringComplete", queryStringComplete)
  return queryStringComplete;
};
