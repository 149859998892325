/*
 * userId: String: ID of user
 * usersContactId: String: ID of the other user
 * ine: String: Ineqeuality comparison for createdAt
 * nextToken: String: Token for loading next documents
 */
const getUser_paired = (userId, usersContactId, nextToken = null, ine = "ge", createdAt="2020-09-10T09:22:40.952Z") =>
  `
query MyQuery {
  user1: getUser(id: "${userId}") {
    id
    createdAt
    onboardingStatus
    address
    bio
    city
    email
    gender
    givenName
    phoneNumber
    relationshipStatus
    familyName
    birthday
    countryID
    longitude
    latitude
    sumOfVotes
    numberOfVotes
    numberOfInvitesSent
    userContactIDs
    contacts {
      items {
        id
        contactID
        status
        sortedUserIDSPK
        requesterID
      }
    }
    country {
      flagUrl
      flag
      internationalName
    }
    languages {
      items {
        language {
          name
          id
        }
      }
    }
    images {
      items {
        index
        url
        id
      }
    }
    interests {
      categories {
        id
        interests {
          id
          title
          type
        }
        title
        type
      }
      selectedInterests
    }
    invitesSent {
      items {
        phoneNumber
        status
      }
    }
    activity(sortDirection: DESC) {
      items {
        id
        name
        address
        activityStart
        activityEnd
        currency
        price
        numberOfParticipants
        locationDescription
        privacy
        createdByID
        interest {
          title
          parentInterest {
            id
            title
          }
        }
        activityFeedback {
          items {
              feedback
              createdAt
            }
          }
          userObject {
            id
            familyName
            givenName
            phoneNumber
            numberOfVotes
            sumOfVotes
            images {
              items {
                id
                index
                url
              }
            }
            interests {
              selectedInterests
              categories {
                id
                title
                type
              }
            }
            images {
              items {
                id
                index
                url
              }
            }
          }
          activityAttendance {
            items {
              activityID
              createdByID
              status
              user {
                id
                givenName
                familyName
                phoneNumber
                images {
                  items {
                    id
                    index
                    url
                  }
                }
              }
              id
              initStatus
            }
          }
        }
      nextToken
    }
  }
  rsvpTotal: getActivityAttendancesByUserID(userID: "${usersContactId}") {
    items{
      id
    }
  }
  listActivityAttendance: listActivityAttendance(
    status: CONFIRMED, 
    activityStart: {${ine}: "${new Date().toISOString()}"}, 
    filter: {userID: {eq: "${usersContactId}"}}, 
    sortDirection: DESC,
    nextToken: ${nextToken}
  ) {
    nextToken
    items {
      activity {
        id
        name
        address
        activityStart
        activityEnd
        currency
        price
        numberOfParticipants
        locationDescription
        
        paymentMethod
        paymentLink
        link {
          url
        }
        privacy
        createdByID
        interest {
          title
          parentInterest {
            id
            title
          }
        }
        userObject {
          id
          familyName
          givenName
          phoneNumber
          numberOfVotes
          sumOfVotes
          images {
            items {
              id
              index
              url
            }
          }
          interests {
            selectedInterests
            categories {
              id
              title
              type
            }
          }
          images {
            items {
              id
              index
              url
            }
          }
        }
        activityAttendance {
          items {
            activityID
            createdByID
            status
            user {
              id
              givenName
              familyName
              phoneNumber
              images {
                items {
                  id
                  index
                  url
                }
              }
            }
            id
            initStatus
          }
        }
      }
    }
  }
  posts1: listPostsByStatus(
      status: PUBLISHED, 
      sortDirection: DESC, 
      limit: 35,
      filter: {createdByID: {eq: "${usersContactId}"}},
      createdAt: {ge: "${createdAt}"}
    ) {
    items {
      createdByID
      reactionsCount
      reactions(filter: {createdByID: {eq: "${userId}"}}) {
        nextToken
        items {
          id
          type
        }
      }
      status
      text
      commentsCount
      createdAt
      id
      images {
        items {
          assetEntityID
          url
          id
          index
        }
      }
      userObject {
        ratePrivacy
        id
        phoneNumber
        givenName
        familyName
        numberOfVotes
        sumOfVotes
        images(limit: 1) {
          items {
            id
            index
            url
          }
        }
      }
      link {
        imageUrl
        title
        url
      }
      postTagUsers {
        items {
          user {
            id
            givenName
            familyName
            phoneNumber
            images(limit: 1) {
              items {
                id
                url
                index
              }
            }
          }
        }
      }
      postObject {
        createdByID
        reactionsCount
        status
        text
        commentsCount
        createdAt
        id
        activityObject {
          name
          activityEnd
          activityStart
          address
          description
          currency
          id
          locationDescription
          numberOfParticipants
          price
          privacy
          interest {
            id
            parentID
            title
            parentInterest {
              id
              title
            }
          }
          userObject {
            ratePrivacy
            id
            phoneNumber
            givenName
            familyName
            numberOfVotes
            sumOfVotes
            images(limit: 1) {
              items {
                id
                index
                url
              }
            }
          }
          activityAttendance {
            items {
              activityID
              createdByID
              status
              initStatus
              user {
                id
                familyName
                givenName
                phoneNumber
                images(limit: 1) {
                  items {
                    id
                    index
                    url
                  }
                }
              }
            }
          }
        }
        postTagUsers {
          items {
            user {
              id
              givenName
              familyName
              phoneNumber
              images(limit: 1) {
                items {
                  id
                  url
                  index
                }
              }
            }
          }
        }
        userObject {
          ratePrivacy
          id
          phoneNumber
          givenName
          familyName
          numberOfVotes
          sumOfVotes
          images {
            items {
              id
              index
              url
            }
          }
        }
        link {
          imageUrl
          title
          url
        }
        images {
          items {
            assetEntityID
            url
            id
            index
          }
        }
      }
      activityObject {
        status
        paymentMethod
        createdByID
        activityEnd
        activityStart
        address
        description
        currency
        id
        locationDescription
        numberOfParticipants
        price
        privacy
        interest {
          id
          parentID
          parentInterest {
            id
            title
          }
        }
        userObject {
          ratePrivacy
          id
          phoneNumber
          givenName
          familyName
          numberOfVotes
          sumOfVotes
          images(limit: 1) {
            items {
              id
              index
              url
            }
          }
        }
        activityAttendance {
          items {
            activityID
            createdByID
            status
            initStatus
            user {
              id
              familyName
              givenName
              phoneNumber
              images(limit: 1) {
                items {
                  id
                  index
                  url
                }
              }
            }
          }
        }
      }
    }
    nextToken
  }
  user2: getUser(id: "${usersContactId}") {
    id
    createdAt
    onboardingStatus
    address
    bio
    city
    email
    gender
    givenName
    phoneNumber
    relationshipStatus
    familyName
    birthday
    countryID
    longitude
    latitude
    sumOfVotes
    numberOfVotes
    numberOfInvitesSent
    userContactIDs
    contacts {
      items {
        id
        contactID
        status
        sortedUserIDSPK
        requesterID
      }
    }
    country {
      flagUrl
      flag
      internationalName
    }
    languages {
      items {
        language {
          name
          id
        }
      }
    }
    images {
      items {
        index
        url
        id
      }
    }
    interests {
      categories {
        id
        interests {
          id
          title
          type
        }
        title
        type
      }
      selectedInterests
    }
    invitesSent {
      items {
        phoneNumber
        status
      }
    }
    activity(sortDirection: DESC) {
      items {
        id
        name
        address
        activityStart
        activityEnd
        numberOfParticipants
        locationDescription
        privacy
        createdByID
        interest {
          title
          parentInterest {
            id
            title
          }
        }
        activityFeedback {
          items {
              feedback
              createdAt
            }
          }
          userObject {
            id
            familyName
            givenName
            phoneNumber
            numberOfVotes
            sumOfVotes
            images {
              items {
                id
                index
                url
              }
            }
            interests {
              selectedInterests
              categories {
                id
                title
                type
              }
            }
            images {
              items {
                id
                index
                url
              }
            }
          }
          activityAttendance {
            items {
              activityID
              createdByID
              status
              user {
                id
                givenName
                familyName
                phoneNumber
                images {
                  items {
                    id
                    index
                    url
                  }
                }
              }
              id
              initStatus
            }
          }
        }
      nextToken
    }
  }
}
`;

// contacts $filterInput{
//   nextToken
//   items {
//     contactID
//     requesterID
//     status
//     contact {
//       givenName
//       familyName
//       id
//       email
//       phoneNumber
//       status
//       numberOfVotes
//       sumOfVotes
//     }
//   }
// }
export default getUser_paired;
