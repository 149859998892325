import React from 'react';
import { Box, Stack } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import './StepperProgress.css'

const theme = createTheme({
    palette: {
    },
    components: {
        // MuiTextField: {
        //   styleOverrides: {
        //     root: {
        //     },
        //   },
        // },
    },
});

/**
 * Primary UI component for user interaction
 */
export const StepperProgress = ({ ...props }) => {

  // const [step, setStep] = React.useState(props.step || 0);

  const colorSuffix = props.color === 'blue' ? '-blue' : '';

  function onClickHandler(evt) {
    const nextStep = parseInt(evt.target.getAttribute('step'));
    props.onClick(nextStep);
  }

  return (
    <ThemeProvider theme={theme}>
        <Stack direction="row" spacing={1}>
          {
            props.steps?.map(
              (v,i) => {
                return <div 
                key={`stack-item-${i}`}
                onClick={props.clickable && onClickHandler}
                step={v}
                className={i === props.step ? `Rectangle-1${colorSuffix}` : "Ellipse-2"}></div>
              }
            )
          }
        </Stack>
    </ThemeProvider>
  );
};

StepperProgress.propTypes = {
};

StepperProgress.defaultProps = {
  color: 'primary',
};
