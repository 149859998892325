import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Fuse from "fuse.js";
import AppContext from "../../AppContext";
import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { generateClient } from "aws-amplify/api";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import { SearchTextField } from "../basic/SearchTextField";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import Search from "@mui/icons-material/Search";
import listInterests from "./queries/listInterests";
import { CategoryChip } from "../basic/CategoryChip";

const client = generateClient();

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

/**
 * Primary UI component for user interaction
 */
export const CategorySelectionScreen = ({ ...props }) => {
  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const [state, setState] = useState({
    data: [],
    filteredData: [],
    searchPattern: "",
    loading: true,
    submission: p.submission,
  });

  const navigate = useNavigate();
  const fuse = useRef();

  useEffect(() => {
    if (state.data && !fuse.search) {
      fuse.current = new Fuse(state.data, {
        threshold: 0.2,
        keys: ["title"],
      });
    }
  }, [state.data]);

  useEffect(() => {
    (async () => {
      setState({
        ...state,
        loading: true,
      });

      try {

        // If the user is selecting a physical interest, and we haven't fetched the physical interests yet, fetch them
        if (
          p.submission?.interestLocationType === "PHYSICAL" &&
          p.configs["categories"].physicalInterests.length === 0
        ) {
          const res = await client.graphql({
            query: listInterests(
              `limit: 10000
              filter: { type: {eq: ${p.submission?.interestLocationType}} }
              `
            ),
          });

          p.configs["categories"].physicalInterests =
            res.data?.listInterests?.items;
        }

        // If the user is selecting an online interest, and we haven't fetched the online interests yet, fetch them
        if (
          p.submission?.interestLocationType === "ONLINE" &&
          p.configs["categories"].onlineInterests.length === 0
        ) {
          const res = await client.graphql({
            query: listInterests(
              `limit: 10000
              filter: { type: {eq: ${p.submission?.interestLocationType}} }
              `
            ),
          });

          p.configs["categories"].onlineInterests =
            res.data?.listInterests?.items;
        }

        // Set the state after fetching the data
        setState({
          ...state,
          data:
            p.submission?.interestLocationType === "PHYSICAL"
              ? p.configs["categories"].physicalInterests
              : p.configs["categories"].onlineInterests,
          loading: false,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        setState({
          ...state,
          loading: false,
        });
      }

      p.prevPage = p.currentPage || "category-selection";
      // p.currentPage = "category-selection"

      if (p.prevPage === "category-selection" || p.prevPage === "home") {
        navigate("/");
      }

      if (p.prevPage === "create-activity") {
        p.journey = "create-activity";
      } else if (p.prevPage === "edit-activity") {
        p.journey = "edit-activity";
      } else {
        p.journey = p.prevPage;
      }
      console.log("journey", p.journey);
    })();
  }, []);

  const onClickChip = (event) => {
    const category = event.currentTarget.getAttribute("category");

    p.transitionOrigin = "CREATE_ACTIVITY";
    p.submission = {
      ...p.submission,
      currentCategory: event.currentTarget.getAttribute("category"),
      currentCategoryID: event.currentTarget.getAttribute("id"),
    };

    navigate(`/${p.journey}/category/${category}`);
  };

  const handleActivitySearch = (event) => {
    setState({
      ...state,
      searchPattern: event.currentTarget.value,
      filteredData: event.currentTarget.value
        ? fuse?.current?.search(event.currentTarget.value)
        : [],
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"sm"}>
        <Box mb={0}>
          <ScreenNavHeader to="/create-activity" title="Choose a category" />
        </Box>
        {/* <Box mb={2.5}>
        <SearchTextField
          fullWidth={true}
          size="medium"
          placeholder="Search"
          sx={{ borderRadius: "100%" }}
          onChange={handleActivitySearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="Search" edge="end">
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box> */}
      </Container>

      <Box px={{ xs: 2, sm: 3 }}>
        <Typography
          variant="body1"
          fontWeight={700}
          sx={{ marginTop: 6, marginBottom: 3 }}
        >
          All{" "}
          {state.submission?.interestLocationType === "PHYSICAL"
            ? "physical"
            : "online"}{" "}
          interests
        </Typography>
        <Stack direction={"row"} flexWrap={"wrap"}>
          {state.loading &&
            [1, 2, 3, 4, 5, 6, 7].map((v, i) => (
              <Skeleton
                variant="rounded"
                sx={{
                  width: 165,
                  height: 48,
                  marginRight: 1,
                  marginBottom: 1,
                  borderRadius: 16,
                }}
              ></Skeleton>
            ))}
          {!state.loading &&
            state.filteredData?.length === 0 &&
            state.data.map((categoryObj, i) => (
              <CategoryChip
                sx={{ marginRight: 1, marginBottom: 1 }}
                key={`category-chip-${i}`}
                onClick={onClickChip}
                category={categoryObj.title}
                id={categoryObj.id}
                label={categoryObj.title}
              />
            ))}
          {!state.loading &&
            state.filteredData?.map((categoryObj, i) => (
              <CategoryChip
                sx={{ marginRight: 1, marginBottom: 1 }}
                key={`category-chip-${i}`}
                onClick={onClickChip}
                category={categoryObj.item.title}
                id={categoryObj.item.id}
                label={categoryObj.item.title}
              />
            ))}
        </Stack>
      </Box>
    </ThemeProvider>
  );
};

export const CategorySelectionScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
      <CategorySelectionScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

CategorySelectionScreen.propTypes = {};

CategorySelectionScreen.defaultProps = {
  color: "primary",
};
