import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../AppContext";
import {
  Link as ReactLink,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Typography,
  Skeleton,
  Stack,
} from "@mui/material";
import { Button } from "../basic/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import { OnboardingScreenResponsiveLayout } from "../complex/OnboardingScreenResponsiveLayout";
import { Switch } from "../basic/Switch";
import useMediaQuery from "@mui/material/useMediaQuery";
import listInterests from "./queries/listInterests";
import uploadImages from "./sharedFunctions/uploadImages";
import transitions from "./transitions.json";
import { generateClient } from "aws-amplify/api";
import { createNewProfile } from "./createFunctions";
const client = generateClient();

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

/**
 * Primary UI component for user interaction
 */
export const SelectInterestScreen = ({ ...props }) => {
  const navigate = useNavigate();
  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const [state, setState] = useState({
    interests: [],
    loading: true,
    interestLocationType: p?.submission?.interestLocationType || "PHYSICAL",
  });
  const [uploadProgress, setUploadProgress] = useState(null);

  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    (async () => {
      setState({
        ...state,
        loading: true,
      });

      try {
        const interestLocationType =
          p?.submission?.interestLocationType || "PHYSICAL";

        if (interestLocationType === "PHYSICAL" && p?.configs?.["interests"]?.physicalInterests?.length === 0) {
          console.log("Fetching physical interests...");
          const res = await client.graphql({
            query: listInterests(
              `limit: 10000
                filter: { type: {eq: ${interestLocationType}} }
                `
            ),
          });

          p.configs["interests"].physicalInterests = res.data?.listInterests?.items;
        }
        else if (interestLocationType === "ONLINE" && p?.configs?.["interests"]?.onlineInterests?.length === 0) {
          console.log("Fetching online interests...");
          const res = await client.graphql({
            query: listInterests(
              `limit: 10000
                filter: { type: {eq: ${interestLocationType}} }
                `
            ),
          });

          p.configs["interests"].onlineInterests = res.data?.listInterests?.items;
        }

        setState({
          ...state,
          interests: interestLocationType === "PHYSICAL" 
          ? p.configs["interests"].physicalInterests 
          : p.configs["interests"].onlineInterests,
          loading: false,
          submission: p.submission,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        setState({
          ...state,
          loading: false,
        });
      }
    })();
  }, [p?.submission?.interestLocationType]);

  const onSwitch = (switchIndex) => {
    // Set to gte if switch is upcoming and to lte if recent
    p.submission = {
      ...p.submission,
      locationType: switchIndex === 0 ? "PHYSICAL" : "ONLINE",
      interestLocationType: switchIndex === 0 ? "PHYSICAL" : "ONLINE",
    };
    setState({
      ...state,
      submission: p.submission,
    });
  };

  const onClickChip = (event) => {
    const category = event.currentTarget.getAttribute("category");
    p.submission = {
      ...p.submission,
      currentCategory: event.currentTarget.getAttribute("category"),
      currentCategoryID: event.currentTarget.getAttribute("id"),
    };

    // setState({
    //   ...state,
    //   submission: p.submission,
    // });
    navigate(`/profile-information/select-interests/${category}`);
  };

  const arrayStringCreator = (arr) => {
    var str = "";

    arr.forEach((val, i) => {
      if (i > 0) str += ",";
      str += `"${val}"`;
    });

    return (str = `[${str}]`);
  };

  const onConfirmHandler = async () => {
    setState({
      ...state,
      loading: true,
    });

    console.log("p.submission 1", p.submission);
    try {
      // Create new user data in db
      q.updateState(globalState);
      const res = await q.doQuery(
        "createNewProfile",
        "Profile created succefully",
        "An error occured",
        {
          submission: p.submission,
        },
        p,
        p.userData.userId,
        p.submission.givenName,
        p.submission.familyName,
        p.submission.bio,
        p.submission.email,
        `
createdAt: "${new Date(Date.now()).toISOString()}",
phoneNumber: "${p.userData?.phone_number}", 
interests: {
  selectedInterests: ${arrayStringCreator(p.submission?.interestsFlat)}
},
numberOfInvitesSent: 0
        `,
        p.submission?.files
          ? () => {}
          : () => {
              navigate(`${transitions[location.pathname]?.confirm}`);
            }
      );

      console.log("p.submission 2", p.submission);

      // Upload photos (if any)
      if (p.submission?.files) {
        console.log("IMAGES HERE");
        await uploadImages(
          "PROFILE",
          p.submission?.files,
          p?.userData?.userId,
          "user/profile",
          //Callback for when images are done uploading
          () => {
            navigate(`${transitions[location.pathname]?.confirm}`);
          },
          (uploadProgress) => {
            setUploadProgress(uploadProgress);
          }
        );
      } else {
        // setState({
        //   ...state,
        //   loading: false,
        // });
        navigate(`${transitions[location.pathname]?.confirm}`);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"sm"}>
        <Box>
          <ScreenNavHeader
            to={`${transitions[location.pathname]?.return}`}
            title="Select Your Interests"
          >
            <Button
              disabled={
                state.loading || state.submission?.interestsFlat?.length < 3
              }
              onClick={onConfirmHandler}
            >
              Confirm
            </Button>
          </ScreenNavHeader>
        </Box>

        <Stack mb={2} ml={1} alignItems={"center"}>
          <Typography variant="body1" color="textSecondary">
            Select at least 3 interests.
          </Typography>
        </Stack>
        <Stack mb={4} alignItems="center">
          <Switch
            defaultIs0={
              p.submission?.interestLocationType === "PHYSICAL" ||
              !p.submission?.interestLocationType
                ? true
                : false
            }
            callback={onSwitch}
            optionA={"Physical"}
            optionB={"Online"}
            // width={useMediaQuery(theme.breakpoints.up("sm")) ? 552 : 320}
            width={useMediaQuery(theme.breakpoints.up("sm")) ? 320 : 220}
          />
        </Stack>

        <Box
          maxWidth={395}
          sx={{ margin: "0 auto" }}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid container spacing={2}>
            {state.loading &&
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
                <Grid item xs={6}>
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    sx={{
                      cursor: "pointer",
                      position: "relative",
                      width: "185px",
                      height: "calc(.81 * 185px)",
                      borderRadius: 6,
                      overflow: "hidden",
                    }}
                  />
                </Grid>
              ))}

            {!state.loading &&
              state.interests?.map((obj, i) => (
                <Grid item xs={6}>
                  <Box
                    onClick={onClickChip}
                    category={obj.title}
                    id={obj.id}
                    sx={{
                      cursor: "pointer",
                      position: "relative",
                      width: "185px",
                      height: "calc(.81 * 185px)",
                      borderRadius: 6,
                      background: `url(${obj.image})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      overflow: "hidden",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      sx={{
                        width: "calc(100% - 32px)",
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        background: "rgba(0,0,0,0.5)",
                        padding: theme.spacing(2),
                      }}
                    >
                      <Typography variant="h6" textAlign={"left"} color="white">
                        {obj.title}{" "}
                      </Typography>
                      {state.submission?.subcategories?.[obj.id]?.length >
                        0 && (
                        <Box
                          component={"span"}
                          sx={{
                            background: "white",
                            padding: 1,
                            width: 12,
                            height: 12,
                            textAlign: "center",
                            borderRadius: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {state.submission?.subcategories?.[obj.id]?.length}
                        </Box>
                      )}
                    </Stack>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export const SelectInterestScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
      <SelectInterestScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

export const SelectInterestsScreenOnboardingLayout = (props) => {
  return (
    <OnboardingScreenResponsiveLayout
      reponsivePositionBoxSx={{
        // paddingBottom: 0,
        // paddingTop: 0,
        position: "static",
        top: "initial",
        left: "initial",
        transform: "translate(0,0) !important",
      }}
      hideLogo={true}
    >
      <SelectInterestScreen {...props} />
    </OnboardingScreenResponsiveLayout>
  );
};

SelectInterestScreen.propTypes = {};

SelectInterestScreen.defaultProps = {
  color: "primary",
};
