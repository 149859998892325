import React, { useContext, useEffect, useRef, useState } from "react";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import AppContext from "../../AppContext.js";
import {
  Avatar,
  Box,
  Button as MuiButton,
  Container,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton as MuiListItemButton,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import Search from "@mui/icons-material/Search";
import Fuse from "fuse.js";
import { Button } from "../basic/Button";
import { AvatarLink } from "../basic/AvatarLink.jsx";
import { ScreenNavHeader } from "../complex/ScreenNavHeader.jsx";
import { SearchTextField } from "../basic/SearchTextField.js";
import AddIcon from "@mui/icons-material/Add";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar.jsx";
import contactsByUserID from "./queries/contactsByUserID.js";
import { generateClient } from "aws-amplify/api";
const client = generateClient();

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});
export const ListItemButton = styled(MuiListItemButton)(
  ({ theme }) => `
  &.Mui-selected,
  &.Mui-selected:hover{
    background: #d6eefb
  }
  &:hover {
    background: transparent;
  }
`
);

const SelectItem = (props) => {
  const { callback, userObject, index, globalState } = props;
  return (
    <Box mb={2}>
      <ListItemButton
        disabled={userObject?.contact?.activityAttendances?.items?.length > 0}
        onClick={callback}
        selected={globalState?.selectedUsers?.includes(userObject.contact?.id)}
        value={props.text}
        id={userObject.contact?.id}
        givenName={userObject.contact?.givenName}
        familyName={userObject.contact?.familyName}
        image={userObject?.contact?.images?.items?.[0]?.url}
      >
        <Stack
          key={`comment-${index}`}
          direction={"row"}
          spacing={2}
          alignItems={"flex-start"}
          sx={{ flexGrow: 1 }}
        >
          <AvatarLink
            to={`/profile/${userObject?.contact?.id}`}
            sx={{ width: 48, height: 48 }}
            src={userObject?.contact?.images?.items?.[0]?.url}
          />
          <Stack direction={"column"} sx={{ flexGrow: 1 }}>
            <Typography variant="body1" sx={{ width: "fit-content" }}>
              {userObject?.contact?.givenName} {userObject?.contact?.familyName}
            </Typography>
            <Typography variant="body2">
              {contactStatusMap[userObject?.status]}
            </Typography>
          </Stack>
        </Stack>
        {userObject?.contact?.activityAttendances?.items?.length > 0 && (
          <Typography variant="body2">Invited</Typography>
        )}
      </ListItemButton>
    </Box>
  );
};

const contactStatusMap = {
  REQUESTED: "Invitation sent",
  ACCEPTED: "Contact",
};
/**
 * Primary UI component for user interaction
 */
export const InvitationsScreen = ({ ...props }) => {
  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const [state, setState] = useState({
    data: [],
    nextToken: null,
    loading: true,
  });
  const timeoutId = useRef(null);
  const fuse = useRef();
  const originalData = useRef();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const res = await client.graphql({
        // query: contactsByUserID("9d1d5590-7b65-4e09-8bce-64035ecfbed5")
        query: contactsByUserID(
          globalState?.userData?.userId,
          `,activityAttendances(filter: {
            activityID: {eq: "${p.createdActivityID}"
          }}) {
            items {
              status
            }
          }`
        ),
      });

      originalData.current = [...res.data.contactsByUserID.items];
      // console.log( 'res', res.data.contactsByUserID.items )

      setState({
        ...state,
        data: [...res.data.contactsByUserID.items],
        nextToken: res.data.contactsByUserID.nextToken,
        loading: false,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    (async () => {
      if (!globalState?.userData?.userId) return;
      fetchData();
    })();
  }, [globalState?.userData]);

  const searchContacts = async (event) => {
    const keyword = event.currentTarget.value;

    // Clear previous search invocation if called prior to timeout
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    // Set new timeout id
    const newTimeoutId = setTimeout(async () => {
      if (!keyword) {
        setState({ ...state, data: originalData.current });
        return;
      } else {
        setState({
          ...state,
          data: new Fuse(originalData.current, {
            threshold: 0.7,
            keys: ["contact.givenName", "contact.familyName"],
          })
            .search(keyword)
            .map((obj) => {
              return { ...obj.item };
            }),
        });
      }
    }, [500]);

    // Set the timeout id in state
    timeoutId.current = newTimeoutId;
  };

  const selectItem = (event) => {
    const newSelectedUsers =
      globalState?.selectedUsers?.length > 0
        ? [...globalState?.selectedUsers]
        : [];

    const newSelectedUsersData =
      globalState?.selectedUsersData?.length > 0
        ? [...globalState?.selectedUsersData]
        : [];

    const id = event.currentTarget.getAttribute("id");
    const givenName = event.currentTarget.getAttribute("givenName");
    const familyName = event.currentTarget.getAttribute("familyName");
    const image = event.currentTarget.getAttribute("image");

    if (newSelectedUsers?.includes(id)) {
      const idToRemove = newSelectedUsers.indexOf(id);
      newSelectedUsers.splice(idToRemove, 1);
      newSelectedUsersData.splice(idToRemove, 1);
    } else {
      newSelectedUsers.push(id);
      newSelectedUsersData.push({ id, givenName, familyName, image });
    }
    setGlobalState({
      ...globalState,
      selectedUsers: newSelectedUsers,
      selectedUsersData: newSelectedUsersData,
    });
  };

  const arrayStringCreator = (arr) => {
    var str = "";

    arr.forEach((val, i) => {
      if (i > 0) str += ",";
      str += `"${val}"`;
    });

    return (str = `[${str}]`);
  };

  const onSendInvites = async () => {
    q.updateState(globalState);
    const res = await q.doQuery(
      "inviteToActivity",
      "Invitations sent",
      "An error occured",
      {
        selectedUsers: [],
        selectedUsersData: [],
      },
      p,
      p.createdActivityID,
      arrayStringCreator(globalState?.selectedUsers)
    );

    p.lastUsedActivityID = p.createdActivityID;
    navigate(`/activity/${p.lastUsedActivityID}`);
    p.createdActivityID = null;
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"sm"}>
        <Box>
          <ScreenNavHeader title={p.journey === "create-post" ? "Tag People" : "Invite"}>
            <Button
              disabled={
                state.loading ||
                globalState?.queryLoading ||
                globalState?.selectedUsers?.length === 0
              }
              size={"large"}
              onClick={p.journey === "create-post" ? ()=>navigate(-1) : onSendInvites}
            >
              {p.journey === "create-post" ? "Confirm" : "Send"}
            </Button>
          </ScreenNavHeader>
        </Box>

        <Box mb={2.5}>
          <SearchTextField
            fullWidth={true}
            size="medium"
            placeholder="Search"
            sx={{ borderRadius: "100%" }}
            onChange={searchContacts}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="Search" edge="end">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box>
          {!state.loading &&
            state.data.map((userObject, i) => (
              <SelectItem
                globalState={globalState}
                callback={selectItem}
                userObject={userObject}
                index={i}
              />
            ))}

          {state.loading &&
            state.data.length === 0 &&
            [1].map((v, i) => (
              <Stack
                key={`skeleton-activity-${i}`}
                direction={"column"}
                spacing={1}
                mb={4}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing={2}
                  sx={{ width: "100%" }}
                >
                  <Skeleton
                    animation={"wave"}
                    variant="circular"
                    width={48}
                    height={48}
                  />
                  <Stack
                    direction={"row"}
                    spacing={1}
                    flexGrow={1}
                    justifyContent={"space-between"}
                  >
                    <Stack direction={"column"} spacing={1}>
                      <Skeleton variant="rounded" width={160} height={10} />
                      <Skeleton
                        variant="rounded"
                        width={100}
                        height={10}
                        sx={{ flexGrow: 1 }}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            ))}
        </Box>

        {/* <AppDialog open={true}>
          <Avatar>S</Avatar>
          <Stack>
            <Button>Send a Message</Button>
          </Stack>
        </AppDialog> */}
      </Container>
    </ThemeProvider>
  );
};

export const InvitationsScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
      <InvitationsScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

InvitationsScreen.propTypes = {};

InvitationsScreen.defaultProps = {
  color: "primary",
};
