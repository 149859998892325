import React from 'react';
import {Chip as MUIChip} from '@mui/material';
import {SvgIcon } from '@mui/material';
import UserIcon from '@heroicons/react/24/solid/UserIcon';
import { styled } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import './chip.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#8EC8E9'
    },
    error: {
        main: '#EE3F3F'
    },
    secondary: {
        main: '#FFF'
    }
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          paddingLeft: 12,
          paddingRight: 12,
          minWidth: 64,
          width: 'fit-content',
          '& .MuiChip-label': {
            padding: 0,
            color: '#132B39',
            display: 'block',
            fontWeight: 'normal'
          },
          '& .MuiChip-icon': {
            color: '#132B39',
            margin: 0,
            marginLeft: -6
          }
        },
      },
    },
  },
});

const StyledMUIChip = styled(MUIChip)(
    ({ theme }) => `
    width: fit-content;
    border-radius: 3em;
    text-transform: none;
`);


/**
 * Primary UI component for user interaction
 */
export const Chip = ({ primary, backgroundColor, size, color, label, ...props }) => {
  //const mode = primary ? 'storybook-chip--primary' : 'storybook-chip--secondary';
  const {startIcon} = props
  return (
    <ThemeProvider theme={theme}>
        <StyledMUIChip
        type="chip"
        //   className={['storybook-chip', `storybook-chip--${size}`, mode].join(' ')}
        variant={color === "secondary" ? 'contained' : 'contained'}
        disableElevation
        fullWidth={props.fullWidth}
        color={color}
        size={size}
        icon={
          props.startIcon && 
          <SvgIcon 
          sx={{
            color: '#132B39',
            maxHeight: 14
          }} size={/*`props.size`*/'small'}>
            {React.createElement(props.startIcon, {color: '#132B39'})}
          </SvgIcon>
        }
        onDelete={props.deleteIcon ? ()=>{} : null}
        deleteIcon={props.deleteIcon}
        sx={
           { ...props.sx}
        }
        label={label} 
      />
    </ThemeProvider>
  );
};

Chip.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'success']),
  /**
   * What background color to use
   */
//   backgroundColor: PropTypes.string,
  /**
   * How large should the chip be?
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Chip contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Start icon
   */
  startIcon: PropTypes.elementType,

  /**
   * End icon
   */
  endIcon: PropTypes.elementType,
};

Chip.defaultProps = {
  color: 'primary',
  size: 'large',
  onClick: undefined,
};
