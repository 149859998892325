import React from 'react';
import {Button as MUIButton} from '@mui/material';
import {SvgIcon } from '@mui/material';
import UserIcon from '@heroicons/react/24/solid/UserIcon';
import { styled } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import './button.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2D9CDB'
    },
    error: {
        main: '#EE3F3F'
    },
    secondary: {
        main: '#fff',
        dark: '#f3f4f5'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          //minWidth: 200,
          // Common styles for all Button instances
          '&.MuiButton-containedSecondary': {
            // backgroundColor: '#fff', // Custom secondary color for the Button
            color: '#0C2F43', // Text color for the Button
            '&:hover': {
              // backgroundColor: '#f0f0f0', // Custom hover color
            },
          },
          '& .MuiButton-startIcon': {
            marginRight: '4px',
            marginTop: '0px'
          }
        },
      },
    },
  },
});

const StyledMUIButton = styled(MUIButton)(
    ({ theme }) => `
    border-radius: 3em;
    text-transform: none;
`);


/**
 * Primary UI component for user interaction
 */
export const Button = ({ primary, backgroundColor, size, color, ...props }) => {
  //const mode = primary ? 'storybook-button--primary' : 'storybook-button--secondary';
    
  return (
    <ThemeProvider theme={theme}>
        <StyledMUIButton
        {...props}
        type="button"
        //   className={['storybook-button', `storybook-button--${size}`, mode].join(' ')}
        variant={color === "secondary" ? 'contained' : 'contained'}
        disableElevation
        fullWidth={props.fullWidth}
        color={color}
        size={size}
        startIcon={
          props.startIcon && 
          <SvgIcon sx={{maxHeight: 14}} size={/*`props.size`*/'small'}>
            {React.createElement(props.startIcon, {})}
          </SvgIcon>
        }
        endIcon={
          props.endIcon && 
          <SvgIcon sx={{maxHeight: 14}} size={/*`props.size`*/'small'}>
            {React.createElement(props.endIcon, {})}
          </SvgIcon>
        }
        sx={
            {
              ...props.sx,
              backgroundColor
            }
        }
        >
            
            {props.children}
        </StyledMUIButton>
    </ThemeProvider>
  );
};

Button.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'success']),
  /**
   * What background color to use
   */
//   backgroundColor: PropTypes.string,
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Start icon
   */
  startIcon: PropTypes.elementType,

  /**
   * End icon
   */
  endIcon: PropTypes.elementType,
};

Button.defaultProps = {
  color: 'primary',
  size: 'large',
  onClick: undefined,
};
