import React from 'react';
import {Link as ReactLink} from 'react-router-dom';
import { 
  Box, 
  ButtonBase,
  Stack,
  Typography
} from '@mui/material';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import logoShape from '../assets/logo-shape.svg'
import logoShapeBlack from '../assets/logo-shape-black.svg'

const theme = createTheme({
    palette: {
    },
    components: {
        // MuiTextField: {
        //   styleOverrides: {
        //     root: {
        //     },
        //   },
        // },
    },
});

const LogoBox = styled(Box)(({ theme }) => ({
  // width: 64,
  // [theme.breakpoints.down('sm')]: {
  //   width: 32
  // }
}));

const ConditionalLink = (props) => {
  if(props.linkToHome) {
    return (<ReactLink {...props}>{props.children}</ReactLink>)
  } else {
    return (<Box {...props}>{props.children}</Box>) 
  }
}

/**
 * Primary UI component for user interaction
 */
export const Logo = ({ ...props }) => {

  var size = props.size || 48;

  const logoSrc = (()=>{
    switch(props.color) {
      case 'white':
        return logoShape;
        
      case 'black':
        return logoShapeBlack
     
      case 'color':
        return `${process.env.REACT_APP_WEBSITE}/logo-color.png`

      default:
        return logoShapeBlack
    }
  })()

  return (
    <ThemeProvider theme={theme}>
        <Stack 
            flexDirection={'row'} 
            alignItems={'center'}
            {...props.sxStack}
          >       
            <ConditionalLink linkToHome={props.linkToHome} to="/" style={{display: 'flex', alignItems: 'center', textDecoration: 'none'}}>
              <LogoBox 
              variant="text"
              sx={{
                width: size,
              }}
              >
                <img width="100%" src={logoSrc} />
              </LogoBox>
              {
                props.withText &&
                <Box sx={{
                  marginTop: '-4px !important',
                  marginLeft: `${(0.2 * size)}px !important`
                }}>
                  <Typography 
                    sx={{ marginTop: 0 }}
                    component="p" 
                    color={props.lightMode ? 'white' : '#0c2f43'}
                    fontSize={`${(0.025 * size)}rem`} 
                    fontWeight={700}
                  >Sociogram</Typography>
                </Box>
              }
              </ConditionalLink>
        </Stack>
    </ThemeProvider>
  );
};

Logo.propTypes = {
  lightMode: PropTypes.bool,
  size: PropTypes.number
};

Logo.defaultProps = {
  lightMode: false,
  size: 64,
  withText: true
};
