import React, { useContext, useEffect, useRef, useState } from "react";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import AppContext from "../../AppContext";
import {
  Avatar,
  Box,
  Container,
  IconButton,
  InputAdornment,
  Rating,
  Skeleton,
  Stack,
  Typography,
  SvgIcon,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import { SearchTextField } from "../basic/SearchTextField";
import { Button } from "../basic/Button";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import SpeakerNotesOffOutlinedIcon from "@mui/icons-material/SpeakerNotesOffOutlined";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Search from "@mui/icons-material/Search";
import { AvatarLink } from "../basic/AvatarLink";
import { Tabs } from "../complex/Tabs";
import siteSearch from "./queries/siteSearch";

import { generateClient, post } from "aws-amplify/api";
import ActivityPostCardContainer from "../complex/ActivityPostCardContainer";
import ActivityCardContainer from "../complex/ActivityCardContainer";
const client = generateClient();

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

/**
 * Primary UI component for user interaction
 */
export const SearchSiteScreen = ({ ...props }) => {
  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const navigate = useNavigate();
  const [state, setState] = useState({
    searchPattern: "",
    data: [],
    keyword: globalState?.siteSearchKeywords,
    defaultTab: p.configs?.["/search"]?.defaultTab,
  });

  var timeoutId = useRef(null);

  const onSearchSite = async (event = false, predinedKeywords = false) => {
    if (event.currentTarget?.value) {
      p.searchingInMain = true;
    }

    const keyword =
      p.searchFromSidebar === 1
        ? globalState?.siteSearchKeywords
        : event.currentTarget?.value;

    setState({ ...state, keyword });

    p.searchFromSidebar = 0;

    setGlobalState({
      ...globalState,
      siteSearchKeywordsReference: event.currentTarget?.value, // Use for components that need to show search keywords (e.g, ActivityCardContainer)
      searchLoading: true,
    });

    // Clear previous search invocation if called prior to timeout
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    // Set new timeout id
    timeoutId.current = setTimeout(async () => {
      // try {
      const res = await client.graphql({
        query: siteSearch(
          // keyword
          keyword,

          // userUD
          globalState?.userData?.userId,

          true,

          true
        ),
      });

      setGlobalState({
        ...globalState,
        searchLoading: false,
        siteSearch: {
          searchPostsFuzzy: res.data.searchPostsFuzzy,
          searchActivitiesFuzzySorted:
            res.data.searchActivitiesFuzzySorted.items,
          searchUsersFuzzy: res.data.searchUsersFuzzy,
        },
      });

      // } catch (error) {
      //   console.error("Error fetching data:", error);
      // }
    }, [500]);
  };

  useEffect(() => {
    if (globalState?.siteSearchKeywords && p.searchFromSidebar === 1) {
      // setState({...state, keyword: globalState?.siteSearchKeywords})
      onSearchSite(false, globalState?.siteSearchKeywords);
      setState({ ...state, keyword: globalState?.siteSearchKeywords });
      p.searchFromSidebar = 0;
    }
  }, [globalState?.siteSearchKeywords]);

  // useEffect(() => {
  //   if (p.searchFromSidebar === 1) {
  //     setState({ ...state, keyword: globalState?.siteSearchKeywords });
  //   }
  // }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"sm"}>
        <Box>
          <Stack
            sx={{ minWidth: 300 }}
            mb={2}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            spacing={2}
          >
            <IconButton onClick={() => navigate(-1)} sx={{ marginRight: -4 }}>
              <SvgIcon>
                <ArrowBackIcon />
              </SvgIcon>
            </IconButton>

            <SearchTextField
              size="medium"
              placeholder="Search Sociogram"
              sx={{ borderRadius: "100%", flexGrow: 1 }}
              onChange={onSearchSite}
              value={state.keyword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="Search" edge="end">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Tabs
            defaultTab={state.defaultTab}
            callback={(newIndex) =>
              (p.configs["/search"] = {
                ...p.configs["/search"],
                defaultTab: newIndex,
              })
            }
            tabs={[
              {
                label: "Posts",
                resultsCount:
                  globalState?.siteSearch?.searchPostsFuzzy?.length || 0,
                content: (
                  <>
                    {globalState?.searchLoading ||
                    globalState?.siteSearch?.searchPostsFuzzy?.length > 0 ? (
                      <ActivityPostCardContainer
                        loading={globalState?.searchLoading}
                        data={globalState?.siteSearch?.searchPostsFuzzy}
                      />
                    ) : (
                      <Stack direction="column" alignItems={"center"}>
                        <SvgIcon sx={{ mb: 3 }}>
                          <ManageSearchIcon />
                        </SvgIcon>
                        <Typography
                          textAlign={"center"}
                          variant="h5"
                          fontWeight={700}
                          sx={{ mb: 2 }}
                        >
                          {state?.keyword ? (
                            <>No results for "{state?.keyword}" in Posts</>
                          ) : (
                            <>No results</>
                          )}
                        </Typography>
                        <Stack direction={"column"} alignItems={"center"}>
                          <Typography
                            gutterBottom
                            color="textSecondary"
                            textAlign={"center"}
                            variant="h6"
                            sx={{ mb: 2 }}
                          >
                            Share what you're up to with a post.
                          </Typography>
                          <Button component={ReactLink} to="/create-post">
                            Create Post
                          </Button>
                        </Stack>
                      </Stack>
                    )}
                  </>
                ),
              },
              {
                label: "Activities",
                resultsCount:
                  globalState?.siteSearch?.searchActivitiesFuzzySorted
                    ?.length || 0,
                content: (
                  <>
                    {globalState?.searchLoading ||
                    globalState?.siteSearch?.searchActivitiesFuzzySorted
                      ?.length > 0 ? (
                      <ActivityCardContainer
                        loading={globalState?.searchLoading}
                        data={
                          globalState?.siteSearch?.searchActivitiesFuzzySorted
                        }
                      />
                    ) : (
                      <Stack direction="column" alignItems={"center"}>
                        <SvgIcon sx={{ mb: 3 }}>
                          <ManageSearchIcon />
                        </SvgIcon>
                        <Typography
                          textAlign={"center"}
                          variant="h5"
                          fontWeight={700}
                          sx={{ mb: 2 }}
                        >
                          {state?.keyword ? (
                            <>No results for "{state?.keyword}" in Activities</>
                          ) : (
                            <>No results</>
                          )}
                        </Typography>
                        <Stack direction={"column"} alignItems={"center"}>
                          <Typography
                            sx={{ mb: 2 }}
                            variant="h6"
                            color="textSecondary"
                            textAlign={"center"}
                          >
                            Be the first create this activity, and share it with
                            others.
                          </Typography>
                          <Button component={ReactLink} to="/create-activity">
                            Create Activity
                          </Button>
                        </Stack>
                      </Stack>
                    )}
                  </>
                ),
              },
              {
                label: "People",
                resultsCount:
                  globalState?.siteSearch?.searchUsersFuzzy?.length || 0,
                content: (
                  <>
                    {globalState?.searchLoading ||
                    globalState?.siteSearch?.searchUsersFuzzy?.length > 0 ? (
                      <>
                        {globalState?.searchLoading &&
                          [1].map((v, i) => (
                            <Stack
                              key={`skeleton-activity-${i}`}
                              direction={"column"}
                              spacing={1}
                              mb={4}
                            >
                              <Stack
                                direction={"row"}
                                alignItems={"center"}
                                spacing={2}
                                sx={{ width: "100%" }}
                              >
                                <Skeleton
                                  animation={"wave"}
                                  variant="circular"
                                  width={48}
                                  height={48}
                                />
                                <Stack
                                  direction={"row"}
                                  spacing={1}
                                  flexGrow={1}
                                  justifyContent={"space-between"}
                                >
                                  <Stack direction={"column"} spacing={1}>
                                    <Skeleton
                                      variant="rounded"
                                      width={160}
                                      height={10}
                                    />
                                    <Skeleton
                                      variant="rounded"
                                      width={100}
                                      height={10}
                                      sx={{ flexGrow: 1 }}
                                    />
                                  </Stack>
                                </Stack>
                              </Stack>
                            </Stack>
                          ))}

                        {globalState?.searchLoading ||
                          (globalState?.siteSearch?.searchUsersFuzzy?.length >
                            0 &&
                            globalState?.siteSearch?.searchUsersFuzzy?.map(
                              (userObj, i) => {
                                return (
                                  <Stack
                                    key={`userObj-${i}`}
                                    direction="row"
                                    alignItems={"center"}
                                    spacing={2}
                                    sx={{ mb: 2 }}
                                  >
                                    <Stack>
                                      <AvatarLink
                                        to={`/profile/${userObj?.id}`}
                                        sx={{
                                          width: 42,
                                          height: 42,
                                          border: "solid 2px #faaf00",
                                        }}
                                        src={userObj.images?.items?.[0]?.url}
                                        avatarText={`${userObj.givenName} ${userObj.familyName}`}
                                      >
                                        {userObj.givenName[0]}
                                      </AvatarLink>
                                    </Stack>
                                  </Stack>
                                );
                              }
                            ))}
                      </>
                    ) : (
                      <Stack direction="column" alignItems={"center"}>
                        <SvgIcon sx={{ mb: 3 }}>
                          <ManageSearchIcon />
                        </SvgIcon>
                        <Typography
                          textAlign={"center"}
                          variant="h5"
                          fontWeight={700}
                          sx={{ mb: 2 }}
                        >
                          {state?.keyword ? (
                            <>No results for "{state?.keyword}" in People</>
                          ) : (
                            <>No results</>
                          )}
                        </Typography>
                        <Stack direction={"column"} alignItems={"center"}>
                          <Typography
                            sx={{ mb: 2 }}
                            variant="h6"
                            color="textSecondary"
                            textAlign={"center"}
                          >
                            Invite your friends to Sociogram to connect.
                          </Typography>
                          <Button component={ReactLink} to="/contacts/add">
                            Invite to Sociogram
                          </Button>
                        </Stack>
                      </Stack>
                    )}
                  </>
                ),
              },
            ]}
          />
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export const SearchSiteScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar pb={0} searchBarInSidebar={false}>
      <SearchSiteScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

SearchSiteScreen.propTypes = {};

SearchSiteScreen.defaultProps = {
  color: "primary",
};
