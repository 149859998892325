import React, { useContext, useEffect, useRef, useState } from "react";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import AppContext from "../../AppContext";
import { Box, InputAdornment, Skeleton, Stack } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { Button } from "../basic/Button";
import { TextField } from "../basic/TextField";
import EmailIcon from "@mui/icons-material/Email";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { OnboardingContainer } from "../complex/OnboardingContainer";
import { OnboardingScreenResponsiveLayout } from "../complex/OnboardingScreenResponsiveLayout";
import "./ProfileInformation.css";
import validateEmail from "../utils/validateEmail";

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

const capitalizeWords = (string) => {
  const words = string.split(" ");
  const newString = words.map(
    (word)=>{
      if(word.length > 0) {
        return word[0]?.toUpperCase() + word?.slice(1)
      } {
        return word;
      }
    }
  ).join(" ")
  return newString;
};
/**
 * Primary UI component for user interaction
 */
export const ProfileInformation = ({ ...props }) => {
  const navigate = useNavigate();
  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const [state, setState] = useState({
    submission: {
      givenName: p.userData?.dynamoDB?.givenName,
      familyName: p?.userData?.dynamoDB?.familyName,
      ...p.submission,
    },
  });

  var timeoutId = useRef();
  const givenNameField = useRef(null);
  const familyNameField = useRef(null);
  const emailField = useRef(null);

  useEffect(() => {
    p.prevPage = p.currentPage || "profile-information";
    p.currentPage = "profile-information";
    p.journey = "onboarding";
  }, []);

  useEffect(() => {
    if (p.submission) {
      if (p.submission.email) {
        return p.submission?.email;
      } else {
        return p?.userData?.email;
      }
    }

    setState({
      ...state,
      submission: {
        givenName: "", //p.userData?.dynamoDB?.givenName,
        familyName: "", //p?.userData?.dynamoDB?.familyName,
        email: "", //p?.userData?.email,
        ...p.submission,
      },
    });
  }, []);


  useEffect(() => {
    // Validate fields
    // ---------------------------------------------------------------

    const bioCondition = ((state.submission?.bio?.length > 0 && state.submission?.bio?.length <= 900) || state.submission?.bio?.length === 0 || !state.submission?.bio);
    if (
      state.submission?.givenName?.length <= 20 &&
      state.submission?.familyName?.length <= 20 &&
      state.submission?.givenName?.length >= 2 &&
      state.submission?.familyName?.length >= 2 &&
      validateEmail(state.submission?.email)
    ) {
      p.validated = true;
    } else {
      p.validated = false;
    }
    setState({
      ...state,
      errors: p.errors,
      validated: p.validated,
    });
  }, [
    state.submission?.givenName,
    state.submission?.familyName,
    state.submission?.email,
  ]);

  const onChangeGivenName = (event) => {
    const value = capitalizeWords(event.currentTarget.value);
    p.submission = {
      ...p.submission,
      givenName: value,
    };
    if (value.length >= 20) {
      p.errors = {
        ...p.errors,
        givenName: "Enter a valid first name.",
      };
    } else {
      p.errors = {
        ...p.errors,
        givenName: false,
      };
    }
    setState({
      ...state,
      submission: p.submission,
      errors: p.errors,
    });
  };

  const onChangeFamilyName = (event) => {
    const value = capitalizeWords(event.currentTarget.value);
    p.submission = {
      ...p.submission,
      familyName: value,
    };

    if (value.length >= 20) {
      p.errors = {
        ...p.errors,
        familyName: "Enter a valid family name.",
      };
    } else {
      p.errors = {
        ...p.errors,
        familyName: false,
      };
    }

    setState({
      ...state,
      submission: p.submission,
      errors: p.errors,
    });
  };

  const onChangeEmail = (event) => {
    const value = event.currentTarget.value;
    p.submission = {
      ...p.submission,
      email: value,
    };

    if (validateEmail(value)) {
      p.errors = {
        ...p.errors,
        email: false,
      };
    } else {
      p.errors = {
        ...p.errors,
        email: "Enter a valid first email address.",
      };
    }
    setState({
      ...state,
      submission: p.submission,
      errors: p.errors,
    });
  };
  
  const onClickContinue = () => {
    navigate(`/profile-information/bio`);
  };

  if (1 < 2) {
    return (
      <ThemeProvider theme={theme}>
        <OnboardingContainer
          title="Introduce Yourself"
          description="Provide your contact details for identification."
          btnLabel="Continue"
          btnPath="/"
        >
          <Stack my={2} direction="row" spacing={2}>
            <TextField
              onChange={onChangeGivenName}
              inputRef={(thisElem) => (givenNameField.current = thisElem)}
              value={state.submission?.givenName}
              fullWidth={true}
              autoFocus
              label="First Name"
              inputProps={{ maxLength: 30 }}
            />

            <TextField
              onChange={onChangeFamilyName}
              inputRef={(thisElem) => (familyNameField.current = thisElem)}
              value={state.submission?.familyName}
              fullWidth={true}
              label="Last Name"
              inputProps={{ maxLength: 30 }}
            />
          </Stack>
          <Box mb={2}>
            <TextField
              fullWidth={true}
              onChange={onChangeEmail}
              inputRef={(thisElem) => (emailField.current = thisElem)}
              value={state.submission?.email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
              label="Email"
            />
          </Box>

          <Button 
          disabled={!state.validated}
          startIcon={ChevronRightIcon} onClick={onClickContinue}>
            Continue
          </Button>
        </OnboardingContainer>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <OnboardingContainer
          title="Introduce Yourself"
          description="Provide your contact details for identification."
          btnLabel="Continue"
          btnPath="/"
        >
          <Stack mb={2} direction="row" spacing={2}>
            <Skeleton
              variant="rounded"
              sx={{ height: 56, width: 185 }}
            ></Skeleton>
            <Skeleton
              variant="rounded"
              sx={{ height: 56, width: 185 }}
            ></Skeleton>
          </Stack>
          <Skeleton
            variant="rounded"
            sx={{ height: 56, width: "100%" }}
          ></Skeleton>
        </OnboardingContainer>
      </ThemeProvider>
    );
  }
};

export const ProfileInformationScreenLayout = (props) => {
  return (
    <OnboardingScreenResponsiveLayout>
      <ProfileInformation {...props} />
    </OnboardingScreenResponsiveLayout>
  );
};

ProfileInformation.propTypes = {};

ProfileInformation.defaultProps = {
  color: "primary",
};
