export const searchUsersFuzzy = (keyword) => `query MyQuery {
  searchUsersFuzzy(keyword: "${keyword}") {
    id
    givenName
    familyName
    sumOfVotes
    status
    privacy
    ratePrivacy
    numberOfVotes
    images(limit: 1) {
      items {
        url
      }
    }
  }
}
`
export default searchUsersFuzzy;