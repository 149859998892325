import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Switch as MuiSwitch } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import "./switch.css";

export const StyledSwitch = styled((props) => (
  <MuiSwitch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  // width: width,
  // height: height,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 0,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      // transform: `translateX(${transform}px)`,
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#f3f4f5", //theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
    // '&::before': {
    //   content: `"Option A"`,
    //   color: "black",
    //   width: "100%",
    //   textAlign: "center",
    //   display: "block",
    //   position: "fixed",
    //   top: "50%",
    //   left: "50%",
    //   transform: "translate(-50%, -50%)",
    // },
    // '&::after': {
    //     content: `"Option B"`,
    //     color: "black",
    //     width: "100%",
    //     textAlign: "center",
    //     display: "block",
    //     position: "fixed",
    //     top: "50%",
    //     right: "50%",
    //     transform: "translate(-50%, -50%)",
    // }
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#8EC8E9",
    boxSizing: "border-box",
    boxShadow: "none",
    // width: width / 2,
    // height: height,
    // borderRadius: (width / 2) / 4,
  },
  "& .MuiSwitch-track": {
    //borderRadius: (width / 2) / 4,
    backgroundColor: "#f3f4f5", //theme.palette.mode === 'light' ? '#fff' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const Switch = (props) => {
  var width = props.width || 300;
  var height = 44;
  var transform = width / 2;

  const switchRef = React.useRef(props.defaultIs0);
  const [activeIndex, setActiveIndex] = React.useState(
    props.defaultIs0 === true ? 0 : 1
  );

  const handleSwitchChange = () => {
    const switchValue = switchRef.current.checked;
    const newSwitch = switchValue ? 1 : 0;
    props?.callback && props?.callback(newSwitch);
    setActiveIndex(newSwitch);
  };

  return (
    <Box
      sx={{
        width,
        position: "relative",
      }}
    >
      <StyledSwitch
        disabled={props.disabled}
        defaultChecked={!props.defaultIs0}
        sx={{
          opacity: props.disabled ? 0.25 : 1,
          width,
          height,
          "& .MuiSwitch-switchBase.Mui-checked": {
            transform: `translateX(${transform}px)`,
          },
          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            //backgroundColor: 'pink'
          },
          "& .MuiSwitch-thumb": {
            width: width / 2,
            height: height,
            borderRadius: width / 2 / 4,
          },
          "& .MuiSwitch-track": {
            borderRadius: width / 2 / 4,
            backgroundColor: "#f3f4f5",
          },
        }}
        onChange={handleSwitchChange}
        inputRef={switchRef}
      />
      <span
        style={{
          position: "absolute",
          left: "25%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          fontWeight: "normal",
          color: (() => {
            if (props.disabled) {
              return "#A3A3A3";
            } else {
              return activeIndex === 0 ? "#0C2F43" : "#A3A3A3";
            }
          })(),
          pointerEvents: "none",
        }}
      >
        {props.optionA}
      </span>
      <span
        style={{
          position: "absolute",
          right: "25%",
          top: "50%",
          transform: "translate(50%, -50%)",
          fontWeight: "normal",
          color: (() => {
            if (props.disabled) {
              return "#A3A3A3";
            } else {
              return activeIndex === 1 ? "#0C2F43" : "#A3A3A3";
            }
          })(),
          pointerEvents: "none",
        }}
      >
        {props.optionB}
      </span>
    </Box>
  );
};

Switch.propTypes = {
  width: PropTypes.number,
  optionA: PropTypes.string.isRequired,
  optionB: PropTypes.string.isRequired,
};

Switch.defaultProps = {
  optionA: "On",
  optionB: "Off",
};
