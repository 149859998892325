import React from 'react';
import { Box, Stack } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import './ProgressCircles.css'

const theme = createTheme({
    palette: {
    },
    components: {
        // MuiTextField: {
        //   styleOverrides: {
        //     root: {
        //     },
        //   },
        // },
    },
});

/**
 * Primary UI component for user interaction
 */
export const ProgressCircles = ({ ...props }) => {
  return (
    <ThemeProvider theme={theme}>

      <Stack direction="row" spacing={1}>
      {
        [1,2,3].map(
        (v,i)=>
          <Box 
            sx={{
              width: 32,
              height: 32,
              overflow: 'hidden',
              borderRadius: '100%',
              //animation: `scale 0.45s linear infinite alternate`,
              animation: 'rotate 30s linear infinite',
              // animationDelay: `${v/4}s`
            }}
          >
            <Box 
            sx={{
              width: '100%', 
              height: '100%', 
              overflow: 'hidden',
              borderRadius: '100%',
              animation: 'scale 0.35s ease-in-out infinite alternate',
              animationDelay: `${v/7}s`
            }}>
              <Box 
                component="img" 
                src={`/Rectangle${i}.png`}
                sx={{
                  width: '100%',
                  animation: 'scaleBg 10s ease-in-out infinite alternate',
                }}
              />
            </Box>
          </Box>
        )
      }
      </Stack>

    </ThemeProvider>
  );
};

ProgressCircles.propTypes = {
};

ProgressCircles.defaultProps = {
  color: 'primary',
};
