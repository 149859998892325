import React, { useContext, useEffect } from "react";
import { Box, IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import { useNavigate, Navigate, Route } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import ImageContext from "../../ImageContext";
import AppContext from "../../AppContext";

export const PhotosScreenLayout = () => {
  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const [previewImage] = useContext(ImageContext);
  const navigate = useNavigate()

  useEffect(()=>{
    p.prevPage = p.currentPage || "view-photo";
    p.currentPage = "view-photo";
  }, [])
  
  const onClickClose = () => {
    navigate(-1);
  }
  // https://cdn.qa.sociogram.com/app/features/post/photos/64a36d05-a763-4749-88c9-16db4b1abdee.jpeg
  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          height: "100vh",
          background: "rgba(0, 0, 0, 0.9)",
        }}
      >
        <IconButton
          onClick={onClickClose}
          size="medium"
          variant="contained"
          sx={{
            position: "absolute",
            top: 15,
            left: 15,
            zIndex: 10,
            background: "rgba(255,255,255,0.05)",
            "&:hover": {
              background: "rgba(255,255,255,0.1)",
            },
          }}
        >
          <SvgIcon sx={{ color: "white" }}>
            <CloseIcon />
          </SvgIcon>
        </IconButton>
        <Box
          component={"div"}
          sx={{
            background:
              `url("${previewImage}")`,
            backgroundSize: "contain",
            width: "100%",
            height: "100%",
            position: "absolute",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        ></Box>
      </Box>
    </Box>
  );
};

export default {};
