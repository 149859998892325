import { useContext, useEffect, useRef, useState, memo } from "react";
import { Link as ReactLink } from "react-router-dom";
import PropTypes from "prop-types";
import AppContext from "../../AppContext";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import EllipsisHorizontalIcon from "@heroicons/react/24/outline/EllipsisHorizontalIcon";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button as MuiButton,
  Card,
  CardContent,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
  Rating,
  Menu,
  MenuItem,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AvatarLink } from "../basic/AvatarLink";
import { Button } from "../basic/Button";
import { EmbeddedContent } from "../basic/EmbeddedContent";
import { ActivityCard } from "./ActivityCard";
import timestampdifference from "../utils/timestampdifference";
import ImageCarousel from "./ImageCarousel";
import validateURL from "../utils/validateURL";
import createReaction from "../screens/queries/createReaction";
import deleteReaction from "../screens/queries/deleteReaction";
import { generateClient } from "aws-amplify/api";
import copyToClipboard from "../utils/copyToClipboard.js";
import getPost from "../screens/queries/getPost.js";
const client = generateClient();

const theme = createTheme({
  palette: {
    primary: {
      main: "#2D9CDB",
      dark: "#3b4f5c",
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#E0C2FF",
      light: "#F5EBFF",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#47008F",
    },
  },
  typography: {
    fontFamily: [
      '"Lato"',
      "Roboto",
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export const ActivityPostCard = (props) => {
  var timeoutId = useRef(null);
  var newlikeReactionID = useRef(null);

  const [globalState, setGlobalState, q, p] = useContext(AppContext);

  const [state, setState] = useState({
    commentsCount: props.commentsCount,
    liked: props.liked,
    reactionsCount: props.reactionsCount,
    loadingReactions: true,
    postIsDeleted: p.deletedPosts?.includes(props.id) ? true : false,
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const openOptionsModal = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleReportPost = () => {
    // Implement report post functionality
    console.log("Report post");
    handleCloseMenu();
  };

  const handleDeletePost = async () => {
    // Implement delete post functionality
    console.log("Delete post");

    const confirmDelete = window.confirm(
      "Are you sure you want to delete this post?"
    );

    if (confirmDelete) {
      setState({
        ...state,
        postIsDeleted: true,
      });
      p.deletedPosts = p.deletedPosts
        ? [...p.deletedPosts, props.id]
        : [props.id];

      try {
        q.updateState(globalState);
        const res = await q.doQuery(
          "deletePost",
          "Post deleted",
          "An error occurred",
          {},
          p,
          props.id
        );
        if (props.deleteCallback) {
          props.deleteCallback();
        }
      } catch (error) {
        setState({
          ...state,
          postIsDeleted: false,
        });

        // Remove the post ID from the deletedPosts array if it exists when the query fails
        p.deletedPosts = p.deletedPosts.filter((id) => id !== props.id);
        console.error(error);
      }
    }
    handleCloseMenu();
  };

  useEffect(() => {
    (async () => {
      const res = await client.graphql({
        query: getPost(globalState?.userData?.userId, props?.id),
      });

      setState({
        ...state,
        commentsCount: res.data.getPost?.commentsCount,
        reactionsCount: res.data.getPost?.reactionsCount,
        liked:
          res.data.getPost?.reactions?.items?.[0]?.type === "HEART"
            ? true
            : false,
        loadingReactions: false,
      });
    })();
  }, [globalState?.userData?.userId]);

  const onLikeHandler = async (event) => {
    const postID = event.currentTarget.getAttribute("postID");

    setState({
      ...state,
      liked: !state.liked,
      reactionsCount: state.liked
        ? props.likeReactionID
          ? props.reactionsCount - 1
          : props.reactionsCount
        : props.likeReactionID
        ? props.reactionsCount
        : props.reactionsCount + 1,
    });

    // Clear previous search invocation if called prior to timeout
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    timeoutId.current = setTimeout(async () => {
      // return;
      if (state.liked) {
        // ccf8f48d-0841-4607-959e-ded1b227b231
        const res = await client.graphql({
          query: deleteReaction(
            newlikeReactionID.current || props.likeReactionID
          ),
        });
      } else {
        const res = await client.graphql({
          query: createReaction(
            `{type: HEART, category: POST, categoryID: "${postID}"}`
          ),
        });

        newlikeReactionID.current = res.data.createReaction.id;

        // console.group("reaction");
        // console.log("sender", props.currentUserObject);
        // console.log("recipient", props.userObject);
        // console.log("like added", res.data);
        // console.groupEnd();
      }
    }, [2000]);
  };

  return (
    <Box sx={{ opacity: state.postIsDeleted ? 0.5 : 1 }}>
      <ThemeProvider theme={theme}>
        <Card
          raised={false}
          variant="outlined"
          sx={{
            border: "none",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            background: "none",
            overflow: "visible",
            opacity: state.postIsDeleted ? 0.5 : 1,
            // borderRadius: 0
            // boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 2px 0px'
          }}
        >
          <Stack
            direction="row"
            alignItems={"center"}
            spacing={2}
            sx={{ mb: 2 }}
          >
            <Stack>
              <AvatarLink
                to={`/profile/${props.userId}`}
                sx={{ width: 42, height: 42, border: "solid 2px #faaf00" }}
                src={props.userAvatar}
                avatarText={props.userName}
              >
                {props.userName[0]}
              </AvatarLink>
            </Stack>

            <Stack sx={{ flexGrow: 1 }}></Stack>

            <Stack flexDirection={"column"} display="flex">
              <IconButton
                size="small"
                disabled={state.postIsDeleted}
                sx={{
                  position: "relative",
                  right: -8,
                  alignSelf: "end",
                  marginTop: "-10px",
                }}
                onClick={openOptionsModal}
              >
                <SvgIcon>
                  <EllipsisHorizontalIcon />
                </SvgIcon>
              </IconButton>
              <Typography variant="subtitle2">
                {timestampdifference(new Date(), props.createdAt)}
              </Typography>
            </Stack>
          </Stack>

          {/* Options Menu */}
          <Menu
            sx={{ width: 200, padding: 0 }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            disabled={state.postIsDeleted}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {props.userId !== globalState?.userData?.userId && (
              <MenuItem sx={{ width: 200 }} onClick={handleReportPost}>
                Report Post
              </MenuItem>
            )}
            {props.userId === globalState?.userData?.userId && (
              <MenuItem
                disabled={state.postIsDeleted}
                sx={{ width: 200 }}
                onClick={handleDeletePost}
              >
                Delete Post
              </MenuItem>
            )}
          </Menu>

          {/* Included persons */}
          <Stack
            sx={{}}
            mb={1}
            alignItems={"center"}
            direction={"row"}
            spacing={0.5}
          >
            <AvatarGroup
              sx={{ position: "relative", left: 0 }}
              total={props.postTagUsers?.items?.length}
            >
              {props.postTagUsers?.items?.map((attendee, i) => {
                return (
                  <Avatar
                    key={`avatar-${i}`}
                    sx={{ width: 20, height: 20 }}
                    alt={`${attendee?.user?.givenName} ${attendee?.user?.familyName}`}
                    src={attendee?.user?.images?.items?.[0]?.url}
                  />
                );
              })}
            </AvatarGroup>

            {props.postTagUsers?.items?.length > 0 && (
              <>
                <Typography variant="body1">With</Typography>

                {props.postTagUsers?.items?.map((attendee, i) => {
                  if (
                    i + 1 < props.postTagUsers?.items?.length ||
                    props.postTagUsers?.items?.length === 1
                  ) {
                    return (
                      <>
                        <Typography variant="body1" color="primary.dark">
                          <strong>{attendee?.user?.givenName}</strong>
                        </Typography>
                        <Typography sx={{ marginLeft: "0 !important" }}>
                          {i + 1 < props.postTagUsers?.items?.length - 1 && `,`}
                        </Typography>
                      </>
                    );
                  } else if (i + 1 === props.postTagUsers?.items?.length) {
                    return (
                      <>
                        <Typography sx={{ marginLeft: "0 !important" }}>
                          , and
                        </Typography>
                        <Typography variant="body1" color="primary.dark">
                          <strong>
                            {
                              props.postTagUsers?.items[
                                props.postTagUsers?.items?.length - 1
                              ].user?.givenName
                            }
                          </strong>
                        </Typography>
                      </>
                    );
                  }
                })}
              </>
            )}
          </Stack>

          <Box sx={{ flexGrow: 1 }} />

          <CardContent sx={{ p: 0 }}>
            {1>2 && props.text.length >= 80 && props.cropText === true ? (
              <>
                <Box mb={1} sx={{ maxHeight: 22, overflow: "hidden" }}>
                  <Typography variant="body1" color="text.primary">
                    {props.text.slice(0, 80)}...
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.primary">
                  <ReactLink to={`/posts/${props.id}`}>Read more</ReactLink>
                </Typography>
              </>
            ) : (
              <Box>
                <Typography
                  id={`text-${props.id}`}
                  variant="body1"
                  color="text.primary"
                  dangerouslySetInnerHTML={{
                    __html: props.text.replace(/\n/g, "<br />"),
                  }}
                />
              </Box>
            )}

            {props.imagesData.length > 0 && (
              <Box mt={1}>
                <ImageCarousel centered imagesData={props.imagesData} />
              </Box>
            )}

            {/* {props.image && (
            <Box
              sx={{
                height: 300,
                width: "100%",
                background: `url(${props.image})`,
                backgroundSize: "100%",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            ></Box>
          )} */}
            {props.shareType === "activity" && (
              <Box mt={1}>
                <ActivityCard {...props.activityObject} />
              </Box>
            )}
            {(props.shareType === "post" ||
              (props.shareType === "link" && validateURL(props.link.url))) && (
              <Box mt={1}>
                {" "}
                <EmbeddedContent
                  cropText={props.cropText}
                  {...props.postObject}
                  {...props.link}
                  type={props.shareType}
                  linkTo={
                    props.shareType === "link"
                      ? props.link.url
                      : `/posts/${props.postObject.id}`
                  }
                  target={props.shareType === "link" ? "_blank" : null}
                />
              </Box>
            )}
          </CardContent>

          <Box sx={{ flexGrow: 1 }} />

          {state.postIsDeleted ? (
            <Box sx={{ height: 48 }}></Box>
          ) : (
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              spacing={0}
              sx={{ pt: 0.5, pb: 1, px: 0 }}
            >
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <MuiButton
                  postID={props.id}
                  onClick={onLikeHandler}
                  sx={{
                    margin: 0,
                    minWidth: "48px",
                    textAlign: "right",
                    color: "#3b4f5c",
                  }}
                  variant="text"
                  startIcon={
                    state.liked ? (
                      <FavoriteIcon sx={{ color: "red" }} />
                    ) : (
                      <FavoriteBorderIcon />
                    )
                  }
                >
                  {state.reactionsCount}
                </MuiButton>
                <MuiButton
                  disabled={state.postIsDeleted}
                  sx={{
                    margin: 0,
                    minWidth: "48px",
                    textAlign: "right",
                    color: "#3b4f5c",
                  }}
                  component={ReactLink}
                  to={`/comments/${props.id}`}
                  variant="text"
                  startIcon={<ChatOutlinedIcon />}
                >
                  {state.commentsCount}
                </MuiButton>
                <MuiButton
                  disabled={state.postIsDeleted}
                  onClick={() =>
                    copyToClipboard(
                      `${process.env.REACT_APP_WEBSITE}/activity/${props.id}`,
                      () =>
                        setGlobalState({
                          ...globalState,
                          toaster: {
                            ...globalState?.toaster,
                            type: "info",
                            open: true,
                            bottom: 25,
                            message: `Share URL copied to clipboard: ${process.env.REACT_APP_WEBSITE}/activity/${props.id}`,
                          },
                        })
                    )
                  }
                  sx={{
                    margin: 0,
                    minWidth: "24px",
                    color: "#3b4f5c",
                    paddingLeft: 0.5,
                    paddingRight: 0.5,
                  }}
                  variant="text"
                >
                  <SendOutlinedIcon />
                </MuiButton>
              </Stack>

              {props.shareType === "activity" && (
                <Button
                  disableElevation
                  disabled={
                    new Date(props.activityObject.activityStart) < new Date() ||
                    state.postIsDeleted
                  }
                  component={ReactLink}
                  to={`/activity/${props.activityObject.id}`}
                  color="primary"
                  size="small"
                  sx={{ mt: 0.5, textTransform: "none" }}
                  variant="contained"
                >
                  {new Date(props.activityObject.activityStart) < new Date() ? (
                    <>Activity ended</>
                  ) : (
                    <> View </>
                  )}
                </Button>
              )}
            </Stack>
          )}
        </Card>
      </ThemeProvider>
    </Box>
  );
};

ActivityPostCard.propTypes = {
  text: PropTypes.string,
  commentsCount: PropTypes.number,
};

ActivityPostCard.defaultProps = {
  color: "primary",
  size: "large",
  onClick: undefined,
  shareType: "",
};
