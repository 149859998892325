/* src/AuthenticatedRoute.js */
import React, { useContext, useEffect, useRef } from "react";
import AppContext from "./AppContext";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

function AuthenticatedRoute(props) {

  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const loggedInStatus = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("loggedInStatus", loggedInStatus.current)
    // // If loggedin user logs out, redirect to sign in
    // if (loggedInStatus?.current === true && globalState.loggedIn === false) {
    //   localStorage.clear();
    //   loggedInStatus.current = false;
    //   navigate("/signin");
    // } else if (
    //   loggedInStatus.current === false &&
    //   globalState.loggedIn === true
    // ) {
    //   loggedInStatus.current = true;
    // }

    if(!globalState?.loggedIn && localStorage.length < 5) {
      navigate("/signin");
    }

  }, [globalState?.loggedIn]);

  if (localStorage.length > 0) {
    return <Outlet globalState={globalState} />;
  } else {
    return <Navigate to="/signin" />;
  }
}
export default AuthenticatedRoute;
