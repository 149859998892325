import React from 'react';
import { Chip as MuiChip } from '@mui/material';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';

const theme = createTheme({
    palette: {
    },
    components: {
        // MuiTextField: {
        //   styleOverrides: {
        //     root: {
        //     },
        //   },
        // },
    },
});

export const CategoryChip = styled(MuiChip)(({ theme }) =>`
  width: fit-content;
  height: 48px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 27px;
  padding: 8px 24px;
  border-radius: 16px;
  border: solid 2px #2d9cdb;
  background-color: #2d9cdb;

  &.MuiChip-outlined{
    background: white;
    border-color: #2d9cdb;
    & .MuiChip-label {
      color: #2d9cdb;
    }
  };
  & .MuiChip-label {
    color: white;
    font-size: 1rem;
  }
  `
);

CategoryChip.propTypes = {
  variant: PropTypes.oneOf(['contained', 'outlined'])
};

CategoryChip.defaultProps = {
  color: 'primary',
  variant: 'outlined'
};
