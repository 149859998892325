import React, { useContext, useEffect, useRef, useState } from "react";
import { Link as ReactLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Navigate } from "react-router-dom";
import AppContext from "../../AppContext";
import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  Skeleton,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import { SearchTextField } from "../basic/SearchTextField";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import { OnboardingScreenResponsiveLayout } from "../complex/OnboardingScreenResponsiveLayout";
import Search from "@mui/icons-material/Search";
import PropTypes from "prop-types";
import { Button } from "../basic/Button";
import { SubcategoryList } from "../complex/SubcategoryList";
import interests from "../../interests2.json";
import Fuse from "fuse.js";
import listInterests from "./queries/listInterests";
import { generateClient } from "aws-amplify/api";
import transitions from "./transitions.json";

const client = generateClient();

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

const makeGeneric = (path) => {
  var split = path.split("/");
  split.splice(3, 1);
  split.push(":subcategory");
  return split.join("/");
};

/**
 * Primary UI component for user interaction
 */
export const SubcategorySelectionScreen = ({ ...props }) => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const [state, setState] = useState({
    searchPattern: "",
    data: [],
    submission: p.submission
  });

  const fuse = useRef();

  useEffect(()=>{

    if(!p.activityMode && !p.journey) {
      // If no activityMode found, redirect to homepage
      navigate("/")
    }
    setState({
      ...state,
      submission: p.submission
    })
  }, [])
  useEffect(() => {
    if (state.interests && !fuse.search) {
      fuse.current = new Fuse(state.interests, {
        threshold: 0.2,
        keys: ["title"],
      });
    }
  }, [state.interests]);

  useEffect(() => {
    (async () => {
      setState({
        ...state,
        loading: true,
      });

      try {
        const res = await client.graphql({
          query: listInterests(
            `limit: 10000
              filter: { 
                parentID: {eq: "${p.submission?.currentCategoryID}"} 
              }`
          ),
        });
        setState({
          ...state,
          interests: res.data?.listInterests?.items,
          loading: false,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        setState({
          ...state,
          loading: false,
        });
      }

      // p.prevPage = p.currentPage || "subcategory-selection";
      // p.currentPage = "subcategory-selection";

    })();
  }, [state?.submission?.currentCategory]);

  const handleActivitySearch = (event) => {
    setState({
      ...state,
      searchPattern: event.currentTarget.value,
      data: event.currentTarget.value
        ? fuse?.current?.search(event.currentTarget.value)
        : [],
    });
  };

  const onSelectItem = (event) => {
    const val = event.currentTarget.getAttribute("value");
    const id = event.currentTarget.getAttribute("id");

    // Populate subcategories
    // with id
    const newSubcategoriesArr = p?.submission?.subcategories?.[
      p.submission?.currentCategoryID
    ]
      ? transitions[makeGeneric(location.pathname)].multipleSelections
        ? [
            ...p.submission?.subcategories?.[
              p.submission?.currentCategoryID
            ],
          ]
        : []
      : [];

    if (newSubcategoriesArr.includes(id)) {
      newSubcategoriesArr.splice(newSubcategoriesArr.indexOf(id), 1);
    } else {
      newSubcategoriesArr.push(id);
    }

    //with name
    const newSubcategoriesArrNamed = p?.subcategoriesNamed?.[
      p.submission?.currentCategory
    ]
      ? transitions[makeGeneric(location.pathname)].multipleSelections
        ? [
            ...p.submission?.subcategoriesNamed?.[
              p.submission?.currentCategory
            ],
          ]
        : []
      : [];

    if (newSubcategoriesArrNamed.includes(val)) {
      newSubcategoriesArrNamed.splice(newSubcategoriesArrNamed.indexOf(val), 1);
    } else {
      newSubcategoriesArrNamed.push(val);
    }

    // Populate categories
    const newCategories = p?.categories
      ? transitions[makeGeneric(location.pathname)].multipleSelections
        ? [...p.submission?.categories]
        : []
      : [];

    // Copy other categories (top-level elements) if multiple selectins
    var newSubcategories;
    var newSubcategoriesNamed;

    if (transitions[makeGeneric(location.pathname)].multipleSelections) {
      newSubcategories = {
        ...p.submission?.subcategories,
        [p.submission?.currentCategoryID]: newSubcategoriesArr,
      };

      newSubcategoriesNamed = {
        ...p.submission?.subcategoriesNamed,
        [p.submission?.currentCategory]: newSubcategoriesArrNamed,
      };
    } 
    // Otherwise, discard all the other elements
    else {
      newSubcategories = {
        [p.submission?.currentCategoryID]: newSubcategoriesArr,
      };

      newSubcategoriesNamed = {
        [p.submission?.currentCategory]: newSubcategoriesArrNamed,
      };
    }

    // If a category does not include subcategories, delete the
    // category from globalState altogher
    if (newSubcategoriesArr.length === 0) {
      newCategories.splice(
        newCategories.indexOf(p.submission?.currentCategoryID),
        1
      );

      // delete subcategories objects
      delete newSubcategories[p.submission?.currentCategoryID];
      delete newSubcategoriesNamed[p.submission?.currentCategory];

      // Otherwise, add the category to the globalState by name
    } else if (
      !newCategories.includes(p.submission?.currentCategory)
    ) {
      newCategories.push(p.submission?.currentCategory);
    }


    p.submission = {
      ...p.submission,
      subcategories: newSubcategories,
      subcategoriesNamed: newSubcategoriesNamed,
      categories: newCategories,
      interestID: Object.values(newSubcategories)[0],
    };

    p.submission.interestsFlat = (() => {
      var flatArr = [];
      Object.values(p.submission?.subcategories)?.forEach((arr) => flatArr.push(...arr));
      return flatArr;
    })();

    setState({
      ...state,
      submission: p.submission
    })

    if(p.journey === "edit-activity") {
      navigate(`/edit-activity/${p.editActivityID}`);
    } else if(p.journey === "create-activity") {
      navigate(`/create-activity`)
    }

  };

  return (
    <ThemeProvider theme={theme}>
      {state.submission?.currentCategoryID ? (
        <React.Fragment>
          <Container maxWidth={"sm"}>
            <Box mb={0}>
              <ScreenNavHeader
                title="Choose a Subcategory"
              >
                {p.journey === "onboarding" && <Button
                  component={ReactLink}
                  to={"/profile-information/select-interests"}
                >
                  Confirm
                </Button>}
              </ScreenNavHeader>
            </Box>
            <Box mb={2.5}>
              <SearchTextField
                fullWidth={true}
                size="medium"
                placeholder="Search"
                sx={{ borderRadius: "100%" }}
                onChange={handleActivitySearch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="Search" edge="end">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Container>

          <Box>
            {state.loading && (
              <Box mt={4}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((v, i) => (
                  <Skeleton
                    variant="rounded"
                    sx={{
                      height: 32,
                      width: "calc(100% - 48px)",
                      margin: "0 auto 16px auto",
                    }}
                  ></Skeleton>
                ))}
              </Box>
            )}
            {!state.loading && (
              <SubcategoryList
                currentCategory={state?.currentCategory}
                filtered={state.data?.length > 0}
                filteredData={state.data}
                interests={state.interests}
                subcategories={
                  state?.submission?.subcategories?.[
                    state.submission?.currentCategoryID
                  ]
                }
                onSelectItem={onSelectItem}
              />
            )}
          </Box>
        </React.Fragment>
      ) : (
        <Navigate to={transitions[makeGeneric(location.pathname)].return} />
      )}
    </ThemeProvider>
  );
};

export const SubcategorySelectionScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
      <SubcategorySelectionScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

export const SubcategorySelectionScreenOnboardingLayout = (props) => {
  return (
    <OnboardingScreenResponsiveLayout
      reponsivePositionBoxSx={{
        position: "static",
        top: "initial",
        left: "initial",
        transform: "translate(0,0) !important",
      }}
      hideLogo={true}
    >
      <SubcategorySelectionScreen {...props} />
    </OnboardingScreenResponsiveLayout>
  );
};

SubcategorySelectionScreen.propTypes = {};

SubcategorySelectionScreen.defaultProps = {
  color: "primary",
};
