function copyToClipboard(url, callback=null) {
  // Create a temporary input element
  var tempInput = document.createElement("input");

  // Set the input element's value to the current URL
  tempInput.value = url;

  // Append the input element to the document body
  document.body.appendChild(tempInput);

  // Select the input element's content
  tempInput.select();
  tempInput.setSelectionRange(0, 99999); // For mobile devices

  // Execute the copy command
  document.execCommand("copy");

  // Remove the temporary input element
  document.body.removeChild(tempInput);

  // Optionally, provide feedback to the user
  if(callback) {
    callback(url)
  }
}

export default copyToClipboard;