const onSendNotification = () => `subscription MySubscription {
  onSendNotification {
    status
    updatedAt
    type
    title
    body
    createdAt
    id
    identity
    identityReceiverIDPK
    meta
    receiverUserID
  }
}`;

export default onSendNotification;