import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../AppContext.js";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import {
  Box,
  Button as MuiButton,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/system";
import PropTypes from "prop-types";
import { Button } from "../basic/Button";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CircularProgress from "@mui/material/CircularProgress";
import { ProgressCircles } from "../basic/ProgressCircles";
import { OnboardingContainer } from "../complex/OnboardingContainer";
import { OnboardingScreenResponsiveLayout } from "../complex/OnboardingScreenResponsiveLayout";
import { ResponsiveToaster } from "../basic/ResponsiveToaster.jsx";
import { OtpInput as OtpInputContainer } from "reactjs-otp-input";
import { confirmSignIn, signIn } from "aws-amplify/auth";
import { generateClient, post } from "aws-amplify/api";
// import updateUserDevice from "./queries/updateUserDevice.js";
import "./OTPScreen.css";

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

const scalingFactor = 0.75;
const OtpInputContainer0 = styled(OtpInputContainer)(({ theme }) => ({
  "& input": {
    display: "flex",
    width: "56px",
    height: "88px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    // alignSelf: "stretch",
    borderRadius: "24px",
    border: "1px solid #75777F",
    background: "#FFF",
    color: "#011A43",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "44px",
    [theme.breakpoints.up("xs")]: {
      borderRadius: 24 * scalingFactor * 0.7,
      width: `${56 * scalingFactor * 0.7}px`,
      height: `${88 * scalingFactor * 0.7}px`,
      lineHeight: `${44 * scalingFactor * 0.7}px`,
    },
    [theme.breakpoints.up("sm")]: {
      borderRadius: 24 * scalingFactor,
      width: `${56 * scalingFactor}px`,
      height: `${88 * scalingFactor}px`,
      lineHeight: `${44 * scalingFactor}px`,
    },
  },
}));

const OtpInputElem = styled("input")(({ theme }) => ({
  "&:focus-visible": {
    outline: "#1976d2 solid 1px",
  },
}));

const FlexOTPBox = styled(Box)(({ theme }) => ({
  // [theme.breakpoints.up('md')]: {
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(8),
  flexGrow: 0.5,
  // },
  // [theme.breakpoints.down('md')]: {
  //   flexGrow: 0.5
  // }
}));

const FlexGrowBox = styled(Box)(({ theme }) => ({
  flexGrow: 0.5,
  [theme.breakpoints.down("md")]: {
    flexGrow: 0.5,
  },
}));

const SeperatorBox = styled(Box)(({ theme }) => ({
  minWidth: 8,
  [theme.breakpoints.down("sm")]: {
    minWidth: 4,
  },
}));

const client = generateClient();

/**
 * Primary UI component for user interaction
 */
export const OTPScreen = ({ ...props }) => {
  const navigate = useNavigate();
  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const [otp, setOtp] = useState("");
  const [toasterMessage, setToasterMessage] = useState();
  const [state, setState] = useState({
    loading: false,
    disabled: true,
    severity: "error",
    disableResend: false,
  });

  useEffect(() => {
    if (!p.authFlow?.fullPhoneNumber) {
      // navigate('/phone-number')
    }
  }, []);
  useEffect(() => {
    if (otp.length === 6) {
      handleSignUpConfirmation({
        username: p?.authFlow?.fullPhoneNumber,
        confirmationCode: otp,
      });
    }
  }, [otp]);

  useEffect(() => {
    if (p?.userData?.phone_number) {
      if (p.OTPStatus === "DONE") {
        if (p?.userData?.phone_number_verified) {
          navigate("/");
        } else {
          navigate("/profile-information");
        }
      }
    } else {
      return;
    }
  }, []);

  const handleSignUpConfirmation = async ({ username, confirmationCode }) => {
    setState({
      ...state,
      disabled: false,
      loading: true,
    });

    try {
      const res = await confirmSignIn({
        challengeResponse: confirmationCode,
      });

      // const { isSignUpComplete, nextStep } = res;

      // console.log("isSignUpComplete, nextStep", isSignUpComplete, nextStep);
      p.OTPStatus = "DONE";
      p.loggedIn = true;

      q.updateState(globalState);
      const newP = await q.getUserCognitoData(p);
      p.userData = newP.userData;

      if (p?.userData?.phone_number_verified) {
        const updatedP = await q.getUserData(p);
        p.userData = updatedP.userData;
        // setGlobalState({
        //   ...globalState,
        //   ...updatedP
        // })
        if(p.userData?.dynamoDB?.givenName && p.userData?.dynamoDB?.phoneNumber)  {
          setGlobalState({
            ...globalState,
            onboarded: true
          })
          navigate("/");
        } else {
          navigate("/profile-information");
        }
      } else {
        navigate("/profile-information");
      }

      // navigate("/");
    } catch (error) {
      console.log("error confirming sign up", error.message);
      setState({
        ...state,
        disabled: true,
        loading: false,
        severity: "error",
      });

      switch (error.message) {
        case "Invalid code or auth state for the user.":
          setToasterMessage("Invalid code.");
          break;
        case "Too many invalid credentials attempts. User temporarily locked. Please try again after few seconds.":
          setToasterMessage(
            "Too many invalid credentials attempts. User temporarily locked. Please try again after few seconds."
          );
        default:
          console.log(error.message);
          setToasterMessage("An error occured.");
          break;
      }
      return;
    }
  };

  const resendVerificationCode = async () => {
    setState({
      ...state,
      disableResend: true,
    });

    try {
      // console.log('authenticating', state.phoneNumber)
      const username = `${p?.authFlow?.fullPhoneNumber}`;
      const res = await signIn({
        username: username, //'+971502601697',
        password: `${username}!Sociogram`, //'+971502601697!Sociogram'
      });

      setState({
        ...state,
        severity: "success",
      });
      setToasterMessage("Verification code has been resent.");
    } catch (error) {
      setState({
        ...state,
        severity: "error",
        disableResend: false,
      });
      console.log(error.message);

      switch (error.message) {
        default:
          setToasterMessage("An error occured.");
          break;
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <OnboardingContainer
        step={1}
        title="Verify Phone Number"
        description={
          <>
            Verification code sent to: {p?.authFlow?.fullPhoneNumber}. <br />{" "}
            Please enter it to confirm your phone number.
          </>
        }
        btnLabel="Continue"
        btnPath="/"
      >
        <FlexOTPBox
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
        >
          <Box mb={2}>
            <MuiButton
              sx={{ textTransform: "none" }}
              disabled={state.disableResend}
              onClick={resendVerificationCode}
              variant="text"
            >
              {state.disableResend && (
                <CircularProgress
                  size={18}
                  sx={{
                    mr: 1,
                    ml: -0.5,
                    color: "rgba(0,0,0,0.25)",
                  }}
                />
              )}
              Resend Code
            </MuiButton>
          </Box>

          <OtpInputContainer
            containerStyle={"otp-container"}
            value={otp}
            onChange={setOtp}
            onPaste={(value) => (value.length <= 6 ? setOtp(value) : false)}
            numInputs={6}
            isInputNum={true}
            renderSeparator={<SeperatorBox />}
            renderInput={(props) => <input type="number" {...props} />}
          />
        </FlexOTPBox>

        <FlexGrowBox />

        {!state.loading ? (
          <Button
            disabled={state.disabled}
            component={ReactLink}
            startIcon={ChevronRightIcon}
            to={"/"}
          >
            {state.loading && (
              <CircularProgress
                size={18}
                sx={{
                  mr: 1,
                  ml: -0.5,
                  color: "rgba(0,0,0,0.25)",
                }}
              />
            )}
            Continue
          </Button>
        ) : (
          <Stack direction="column" alignItems={"center"} spacing={4}>
            <ProgressCircles />
            <Typography variant="h6">Checking the code...</Typography>
          </Stack>
        )}

        <ResponsiveToaster
          severity={state.severity}
          open={toasterMessage ? true : false}
          toasterMessage={toasterMessage}
          setToasterMessage={() => setToasterMessage("")}
        />
      </OnboardingContainer>
    </ThemeProvider>
  );
};

export const OTPScreenLayout = (props) => {
  return (
    <OnboardingScreenResponsiveLayout>
      <OTPScreen {...props} />
    </OnboardingScreenResponsiveLayout>
  );
};

OTPScreen.propTypes = {};

OTPScreen.defaultProps = {
  color: "primary",
};
