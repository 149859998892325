const searchActivitiesFuzzySorted = (criteria) => `query MyQuery {
    searchActivitiesFuzzySorted(${criteria}) {
      items {
        id
        name
        description
        currency
        price
        privacy
        address
        activityStart
        activityEnd
        conditions
        numberOfParticipants
        interest {
          id
          parentID
          title
          parentInterest {
            id
            title
          }
        }
        userObject {
          familyName
          givenName
          id
          images(limit: 1) {
            items {
              url
            }
          }
          interests {
            selectedInterests
          }
          numberOfVotes
          numberOfInvitesSent
          status
        }
        paymentMethod
        paymentLink
        longitude
        locationDescription
        link {
          title
          url
          info
          imageUrl
        }
        images {
          items {
            url
            index
            id
          }
        }
        city
        cancellationReason
        activityAttendance(filter: {status: {eq: CONFIRMED}}) {
          items {
            user {
              familyName
              givenName
              id
              images(limit: 1) {
                items {
                  url
                }
              }
            }
          }
        }
      }
      nextToken
    }
  }
  `;

export default searchActivitiesFuzzySorted;