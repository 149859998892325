import React from "react";

import { Toaster } from "../basic/Toaster.jsx";
import { styled } from "@mui/system";

export const ResponsiveToaster = styled(Toaster)(({ theme }) => ({
  width: 320,
  position: "static",
  left: "0 !important",
  right: "0 !important",
  transform: "translate(0,0) !important",
  margin: `${theme.spacing(2)} auto 0 auto`,
  // marginTop: theme.spacing(2),
  // [theme.breakpoints.up('md')]: {
  //   bottom: theme.spacing(4)
  // },
  // [theme.breakpoints.down('md')]: {
  //   position: 'absolute',
  //   bottom: theme.spacing(8)
  // },
  // [theme.breakpoints.down('sm')]: {
  //   position: 'absolute',
  //   left: '50%',
  //   transform: 'translateX(-50%)',
  //   bottom: theme.spacing(8)
  // }
}));

export default ResponsiveToaster;