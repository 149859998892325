const updateUserNotification  = (arr) => {
  const mutationArray = arr.map((obj,i) => {

return `
update${i}: updateUserNotification (input: {id: "${obj.id}", status: SEEN}) {
    id
    status
}
`;
  })
  // Line break after
  .join(`
`);

return `mutation MyMutation {
${mutationArray}
}`;
};

export default updateUserNotification ;
