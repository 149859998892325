import React, { useEffect, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';

const theme = createTheme({
    palette: {
    },
    components: {
        MuiSnackbar: {
          styleOverrides: {
            root: {
              '& .MuiPaper-root': {
                color: 'white',
                background: '#1D1D1D'
              }
            },
          },
        },
    },
});

/**
 * Primary UI component for user interaction
 */
export const Toaster = ({ ...props }) => {
  // const [open, setOpen] = useState(true);

  useEffect(
    ()=> {
      if(props.open) {
        setTimeout(() => props.setToasterMessage(), 5000);
      }
    }, [props.open]
  )

  return (
    <ThemeProvider theme={theme}>
      <Snackbar 
      {...props}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={props.open}>
        <Alert 
          onClose={props.setToasterMessage}  
          sx={{ width: '100%' }}
          severity={props.severity || "error"}
        >
          {props.toasterMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

Toaster.propTypes = {
};

Toaster.defaultProps = {
  color: 'primary',
};
