/*
 * id: String: ID of user
 * ine: String: Ineqeuality comparison for createdAt 
 * nextToken: String: Token for loading next documents
 */
const getUser = (id, nextToken=null, ine="ge") =>
 `
query MyQuery {
  getUser(id: "${id}") {
    id
    createdAt
    onboardingStatus
    address
    bio
    city
    email
    gender
    givenName
    phoneNumber
    relationshipStatus
    familyName
    birthday
    longitude
    latitude
    sumOfVotes
    numberOfVotes
    numberOfInvitesSent
    userContactIDs
    contacts {
      items {
        id
        contactID
        status
        sortedUserIDSPK
      }
    }
    languages {
      items {
        language {
          name
          id
        }
      }
    }
    images {
      items {
        index
        url
        id
      }
    }
    interests {
      categories {
        id
        interests {
          id
          title
          type
        }
        title
        type
      }
      selectedInterests
    }
    invitesSent {
      items {
        phoneNumber
        status
      }
    }
    activity(sortDirection: DESC) {
      items {
        id
        name
        address
        activityStart
        activityEnd
        numberOfParticipants
        locationDescription
        privacy
        createdByID
        interest {
          title
          parentInterest {
            id
            title
          }
        }
        activityFeedback {
          items {
              feedback
              createdAt
            }
          }
          userObject {
            id
            familyName
            givenName
            phoneNumber
            numberOfVotes
            sumOfVotes
            images {
              items {
                id
                index
                url
              }
            }
            interests {
              selectedInterests
              categories {
                id
                title
                type
              }
            }
            images {
              items {
                id
                index
                url
              }
            }
          }
          activityAttendance {
            items {
              activityID
              createdByID
              status
              user {
                id
                givenName
                familyName
                phoneNumber
                images {
                  items {
                    id
                    index
                    url
                  }
                }
              }
              id
              initStatus
            }
          }
        }
      nextToken
    }
  }
  listActivityAttendance(
    status: CONFIRMED, 
    activityStart: {${ine}: "${new Date().toISOString()}"}, 
    filter: {userID: {eq: "${id}"}}, 
    sortDirection: DESC,
    nextToken: ${nextToken}
  ) {
    nextToken
    items {
      activity {
        id
        name
        address
        activityStart
        activityEnd
        numberOfParticipants
        locationDescription
        locationType
        paymentMethod
        paymentLink
        link {
          url
        }
        privacy
        createdByID
        interest {
          title
          parentInterest {
            id
            title
          }
        }
        userObject {
          id
          familyName
          givenName
          phoneNumber
          numberOfVotes
          sumOfVotes
          images {
            items {
              id
              index
              url
            }
          }
          interests {
            selectedInterests
            categories {
              id
              title
              type
            }
          }
          images {
            items {
              id
              index
              url
            }
          }
        }
        activityAttendance {
          items {
            activityID
            createdByID
            status
            user {
              id
              givenName
              familyName
              phoneNumber
              images {
                items {
                  id
                  index
                  url
                }
              }
            }
            id
            initStatus
          }
        }
      }
    }
  }
}
`;

// contacts $filterInput{
//   nextToken
//   items {
//     contactID
//     requesterID
//     status
//     contact {
//       givenName
//       familyName
//       id
//       email
//       phoneNumber
//       status
//       numberOfVotes
//       sumOfVotes
//     }
//   }
// }
export default getUser;
