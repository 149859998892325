import React, { useEffect, useState } from "react";
import { Link as ReactLink } from "react-router-dom";
import {
  Box,
  Paper,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {formatLink, formatLinkText} from "../utils/formatLink";
import LinkIcon from "@mui/icons-material/Link";
import PropTypes from "prop-types";
import "./EmbeddedContent.css";

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

const StyledPaper = styled(Box)(
  ({ theme }) => `
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Surface-Surface, #F3F4F5);
  box-shadow: none;
  text-decoration: none;
`
);

const EmbeddedImage = styled(Box)(
  () => `
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
`
);

const capitalize = (string) => {
  return string[0].toUpperCase() + string.slice(1);
};

/**
 * Primary UI component for user interaction
 */
export const EmbeddedContent = ({ ...props }) => {
  const [state, setState] = useState({ loading: true });

  useEffect(() => {
    try {
      async function fetchData() {
        const url =
          "https://lagu5fzkccjt5hi7xzf4oi2ts40dxvsq.lambda-url.us-east-1.on.aws/";
        const data = {
          url: `${props.url}`,
        };

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          //throw new Error(`HTTP error! Status: ${response.status}`);
          console.log(response.status);
        }

        const jsonResponse = await response.json();
        setState({
          ...state,
          loading: false,
          title: jsonResponse.title,
          image: jsonResponse.image || null,
        });
      }

      // Call the async function to execute the fetch operation
      fetchData();
    } catch (error) {
      console.log("error", error);
    }

    return;
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <StyledPaper
        component={ReactLink}
        target={formatLink(props.linkTo || props.url)?.substring(0,1) === "/" ? "_self" : props.target}
        to={formatLink(props.linkTo || props.url)}
      >
        {props.imageSrc && props.imageSrc !== "No Image" ? (
          <EmbeddedImage
            component="img"
            sx={{ height: 46, width: 46 }}
            src={props.imageSrc}
          />
        ) : (
          <Box
            sx={{ height: 46, width: 46 }}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {state.loading && (
              <Skeleton
                animation={"wave"}
                variant="rounded"
                sx={{
                  width: 46,
                  height: 46,
                }}
              />
            )}

            {!state.loading && !state.image && (
              <SvgIcon>
                <LinkIcon />
              </SvgIcon>
            )}

            {!state.loading && state.image && (
              <EmbeddedImage
                component={Box}
                sx={{
                  height: 46,
                  width: 46,
                  backgroundImage: `url(${state.image})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              />
            )}
          </Box>
        )}

        <Stack flexGrow={1} direction="column" spacing={0.5}>
          <Typography
            sx={{ maxWidth: 500, fontFamily: "Lato", fontSize: "0.9rem" }}
          >
            {props.name && <strong>{`${props.name.substring(0, 20)} `}</strong>}

            {!state.loading ? (
              `${state.title || props.text || "No title found"}`?.substring(
                0,
                30
              )
            ) : (
              <Skeleton
                animation="wave"
                variant="rounded"
                sx={{ height: 9, width: 125 }}
              />
            )}
          </Typography>
          <Typography sx={{ fontFamily: "Lato", fontSize: "0.9rem" }}>
            {capitalize(props.type)} ·{" "}
            {props.type === "link"
              ? formatLinkText(props.url)
              : props.username}
          </Typography>
        </Stack>
      </StyledPaper>
    </ThemeProvider>
  );
};

EmbeddedContent.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  username: PropTypes.string,
  imageSrc: PropTypes.string,
};

EmbeddedContent.defaultProps = {
  color: "primary",
};
