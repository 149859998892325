const searchActivities = (criteria) => `
query MyQuery {
  search: searchActivities(${criteria}) {
    items {
      id
      name
      description
      currency
      price
      address
      activityStart
      activityEnd
      conditions
      numberOfParticipants
      interest {
        id
        parentID
        title
        parentInterest {
          id
          title
        }
      }
      userObject {
        familyName
        givenName
        id
        images(limit: 1) {
          items {
            url
          }
        }
        interests {
          selectedInterests
        }
        numberOfVotes
        numberOfInvitesSent
        status
      }
      paymentMethod
      paymentLink
      longitude
      locationDescription
      link {
        title
        url
        info
        imageUrl
      }
      images {
        items {
          url
          index
          id
        }
      }
      city
      cancellationReason
      activityAttendance {
        items {
          sumOfVotes
          user {
            familyName
            givenName
            id
            images(limit: 1) {
              items {
                url
              }
            }
          }
        }
      }
    }
    nextToken
  }
}
`;

export default searchActivities;