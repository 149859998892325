const createPresignedURL2 = (input) => `
mutation CreatePresignedURLMutation {
  createPresignedURL(${input}) {
    fileName
    presignedURL
    url
    image {
      id
    }
  }
}
`

export default createPresignedURL2;