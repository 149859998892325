import { Link as ReactLink, useNavigate } from "react-router-dom";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import GroupIcon from "@mui/icons-material/Group";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button as MuiButton,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  Typography,
  Rating,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AvatarLink } from "../basic/AvatarLink";
import "./ActivityCard.css";
import monthmap from "../utils/monthmap.js";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2D9CDB",
      dark: "#3b4f5c",
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#E0C2FF",
      light: "#F5EBFF",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#47008F",
    },
  },
  typography: {
    fontFamily: [
      '"Lato"',
      "Roboto",
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export const ActivityCard = (props) => {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <Card
        onClick={() => navigate(`/activity/${props.id}`)}
        raised={false}
        variant="outlined"
        sx={{
          border: "none",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box sx={{ flexGrow: 1 }} />

        <CardContent sx={{ p: 0 }}>
          <Paper
            sx={{
              border: "solid 1px rgba(0,0,0,0.15)",
              borderRadius: 1,
              p: 2,
            }}
            elevation={0}
          >
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems={"baseline"}
              mb={1}
              spacing={4}
            >
              <Stack>
                <Typography color="primary" variant="subtitle2">
                  {props.category} &middot; {props.subcategory}
                </Typography>
                <Typography variant="body1" color="primary.dark">
                  <strong>{props.title || props.name}</strong>
                </Typography>
              </Stack>

              {(props.privacy === "JOIN_BY_REQUEST" || props.privacy === "PRIVATE") && (
                <Stack>
                  <SvgIcon color="error" fontSize="medium">
                    <LockOutlinedIcon />
                  </SvgIcon>
                </Stack>
              )}
            </Stack>

            <AvatarLink
              to={`/profile/${props.userId}`}
              sx={{ width: 32, height: 32, border: "solid 2px #faaf00" }}
              src={props.userAvatar}
              avatarText={props.userName}
            >
              H
            </AvatarLink>

            <Stack mb={1} alignItems={"center"} direction={"row"} spacing={0.5}>
              <AvatarGroup
                sx={{ position: "relative", left: -2 }}
                total={
                  props.attendees?.length >= 3 ? 3 : props.attendees?.length
                }
              >
                {props.attendees?.map((attendee, i) => {
                  return (
                    <Box
                      ml={i > 0 ? -1 : 0}
                      sx={{ zIndex: i * 10, position: "relative" }}
                    >
                      <AvatarLink
                        to={`/profile/${attendee?.user?.id}`}
                        key={`avatar-${i}`}
                        sx={{ width: 20, height: 20 }}
                        alt={attendee?.user?.givenName}
                        src={
                          attendee?.user?.images?.items?.[0] &&
                          attendee?.user?.images?.items?.[0]?.url
                        }
                      />
                    </Box>
                  );
                })}
              </AvatarGroup>

              {props.attendees?.length === 1 && (
                <Typography variant="body1" color="primary.dark">
                  <strong>{props?.attendees?.[0].user?.givenName}</strong> is
                  going
                </Typography>
              )}
              {props.attendees?.length > 1 && (
                <Typography variant="body1" color="primary.dark">
                  <strong>{props?.attendees?.[0].user?.givenName}</strong>
                </Typography>
              )}
              {props.attendees?.length >= 2 && <Typography>and</Typography>}

              {props.attendees?.length === 2 && (
                <Typography variant="body1" color="primary.dark">
                  <strong>{props?.attendees?.[1].user?.givenName}</strong> are
                  going
                </Typography>
              )}
              {props.attendees?.length > 2 && (
                <>
                  <Typography variant="body1" color="primary.dark">
                    <strong>
                      {props.attendees?.length - 1}{" "}
                      {props.attendees?.length - 1 < 2 ? `other` : `others`}
                    </strong>
                  </Typography>
                  <Typography>are going</Typography>
                </>
              )}
            </Stack>

            <Stack
              mb={2}
              direction="row"
              spacing={1}
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                sx={{
                  width: 32 * 3,
                  border: "solid 2px #2D9CDB",
                  borderRadius: 2,
                  p: 1,
                }}
              >
                <Stack
                  sx={{ margin: "0 auto" }}
                  justifyContent="center"
                  spacing={1}
                  direction="row"
                >
                  <Typography>
                    <strong>{props.day}</strong>
                  </Typography>
                  <Typography sx={{ alignSelf: "end" }} variant="caption">
                    {monthmap[props.month]}
                  </Typography>
                </Stack>
              </Box>

              <Stack
                direction="row"
                alignItems="end"
                spacing={1}
                sx={{
                  width: "100%",
                  border: "solid 2px #dff0fa",
                  background: "#dff0fa",
                  borderRadius: 2,
                  p: 1,
                }}
              >
                <Typography>
                  <strong>
                    {props.startTime} - {props.endTime}
                  </strong>
                </Typography>
                <Typography variant="caption">{props.remainingDays}</Typography>
              </Stack>
            </Stack>

            <Stack direction="row" alignItems="baseline">
              <Stack
                direction="row"
                pt={2}
                pr={2}
                spacing={1}
                sx={{
                  flexBasis: "50%",
                  minHeight: 60,
                  borderRight: "solid 1px rgba(0,0,0,0.15)",
                }}
              >
                <SvgIcon color="primary">
                  <FmdGoodIcon />
                </SvgIcon>
                <Typography variant="subtitle2">
                  {props.locationType === "ONLINE" ? (
                    <>
                      Online <br />{" "}
                      <ReactLink to={props.link?.url} target="_blank">
                        {props.link?.url}
                      </ReactLink>{" "}
                    </>
                  ) : (
                    props.address
                  )}
                </Typography>
              </Stack>
              <Stack sx={{ flexBasis: "50%" }} pl={2} direction="column">
                <Stack mb={1} spacing={1} direction={"row"}>
                  <SvgIcon color="primary">
                    <GroupIcon />
                  </SvgIcon>
                  <Stack direction={"row"} alignItems={"center"} spacing={1}>
                    <Typography>
                      {props.rsvped}/{props.participants}
                    </Typography>

                    {props.rsvped === props.participants && (
                      <Chip size="small" color="error" label="Activity is full" />
                    )}
                  </Stack>
                </Stack>

                <Stack mb={1} spacing={1} direction={"row"}>
                  <SvgIcon color="success">
                    <AttachMoneyIcon />
                  </SvgIcon>
                  <Typography>
                    {props.currency} {props.price}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Paper>
        </CardContent>

        <Box sx={{ flexGrow: 1 }} />
      </Card>
    </ThemeProvider>
  );
};

ActivityCard.propTypes = {};

ActivityCard.defaultProps = {
  color: "primary",
  size: "large",
  onClick: undefined,
};
