import React, { useContext, useEffect, useRef, useState } from "react";
import { Link as ReactLink, useNavigate, useParams } from "react-router-dom";
import AppContext from "../../AppContext";
import {
  Avatar,
  Box,
  Button as MuiButton,
  CircularProgress,
  Container,
  IconButton,
  Rating,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ActivityPostCardContainer from "../complex/ActivityPostCardContainer";
import ActivityCardContainer from "../complex/ActivityCardContainer";
import { Switch } from "../basic/Switch";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import { Button } from "../basic/Button";
import { Tabs } from "../complex/Tabs";
import ImageCarousel from "../complex/ImageCarousel";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import getUser_paired from "./queries/getUser-paired.js";
import { generateClient } from "aws-amplify/api";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AddHomeIcon from "@mui/icons-material/AddHome";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import copyToClipboard from "../utils/copyToClipboard.js";
import { signOut } from "aws-amplify/auth";
const client = generateClient();

const capitalize = (string) => {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
};

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

function intersectArrays(array1, array2) {
  return array1.filter((element) => array2.includes(element));
}

export const MetaInfo = styled(Box)(
  ({ theme }) => `
    width: 165px !important;
    height: 56px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border-left: solid 2px #2d9cdb;`
);
/**
 * Primary UI component for user interaction
 */
export const ProfilePageScreen = ({ ...props }) => {
  const params = useParams();
  const navigate = useNavigate();

  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const [state, setState] = useState({
    loading: true,
    loadingConnection: true,
    connectLoading: false,
    connectDisabled: false,
    ine: "le",
    loadingActivities: false,
    defaultTab: p.configs?.["/profile"]?.defaultTab,
  });
  const commentObj = {};

  // 9d1d5590-7b65-4e09-8bce-64035ecfbed5
  useEffect(() => {
    p.prevPage = p.currentPage || "view-profile";
    p.currentPage = "view-profile";

    if (localP.current?.init) {
      getNewUserData(true, false);
    } else {
      localP.current.init = true;
      getNewUserData(true, true);
    }
  }, [state.inequality]);

  const localP = useRef({ inequality: "ge" });
  const nextTokenAttendances = useRef(null);

  const getNewUserData = async (resetArray = false, useToken = false) => {
    setState({ ...state, loadingCalendar: true });
    const res = await client.graphql({
      query: getUser_paired(
        p.userData?.userId,
        params.id,
        nextTokenAttendances.current && !resetArray && useToken
          ? `"${nextTokenAttendances.current}"`
          : null,
        localP.current?.inequality
      ),
    });

    p.userData = {
      ...p.userData,
      ...res.data.user2,
    };

    const selfContactObj = res.data.user1?.contacts?.items.filter(
      (obj) => obj.contactID === res.data.user2.id
    )?.[0];

    const otherContactObj = res.data.user2?.contacts?.items.filter(
      (obj) => obj.contactID === res.data.user1.id
    )?.[0];

    var listActivityAttendance = res.data.listActivityAttendance?.items
      ?.filter((activityObj) => {
        if (activityObj?.activity) {
          return activityObj;
        }
      })
      ?.map((activityObj) => ({ ...activityObj.activity }));

    listActivityAttendance =
      localP.current?.inequality === "ge"
        ? listActivityAttendance.reverse()
        : listActivityAttendance;

    console.log(
      "listActivityAttendance",
      listActivityAttendance,
      listActivityAttendance?.[0]?.activityAttendance?.items?.filter(
        (obj) => obj?.user?.id === "7f58d4ce-ba42-49f1-a595-02d252908b42"
      ),
      otherContactObj?.id
    );
    setState({
      ...state,
      loading: false,
      loadingConnection: false,
      loadingCalendar: false,
      ...res.data.user2,
      selfContactID: selfContactObj?.id,
      otherContactID: otherContactObj?.id,
      sortedUserIDSPK: otherContactObj?.sortedUserIDSPK,
      connected: selfContactObj?.id && otherContactObj?.id,
      contactStatus: selfContactObj?.status,
      selfIsContactInitiater:
        p.userData.dynamoDB?.id === otherContactObj?.requesterID,
      loadingActivities: false,
      nextTokenAttendances:
        nextTokenAttendances.current ===
        res.data?.listActivityAttendance.nextToken
          ? null
          : res.data?.listActivityAttendance.nextToken,

      rsvpTotal: res.data?.rsvpTotal?.items?.length,
      attendances: {
        items:
          state?.attendances?.items && !resetArray
            ? [...state?.attendances?.items, ...listActivityAttendance]
            : listActivityAttendance,
      },
      posts: res.data?.posts1?.items,
    });

    nextTokenAttendances.current = res.data?.listActivityAttendance.nextToken;
  };

  const onSwitch = (switchIndex) => {
    // Set to gte if switch is upcoming and to lte if recent
    setState({
      ...state,
      loadingActivities: true,
      inequality: switchIndex === 0 ? "ge" : "le",
      runLoadActivitiesBySwitch: true,
    });

    localP.current = {
      inequality: switchIndex === 0 ? "ge" : "le",
    };
  };

  const onCreateContactHandler = async () => {
    setState({
      ...state,
      loadingConnection: true,
    });
    q.updateState(globalState);
    const res = await q.doQuery(
      "createConnection",
      "Invitation has been sent",
      "An error occured",
      {},
      p,
      state.phoneNumber
    );

    // if (res.data?.createContact) {
    //   getNewUserData();
    // }

    getNewUserData();
  };

  const onDeleteContactHandler = async () => {
    setState({
      ...state,
      loadingConnection: true,
    });

    q.updateState(globalState);
    const res = await q.doQuery(
      "deleteConnection",
      "Invitation has been withdrawn",
      "An error occured",
      {},
      p,
      state.selfContactID,
      state.otherContactID
    );

    // If both contact entries were deleted in db
    if (res.length === 2) {
      setState({
        ...state,
        connectDisabled: false,
        invitation: {},
        selfContactID: null,
        otherContactID: null,
        connected: false,
        contactStatus: null,
      });
    }

    getNewUserData();
  };

  const onUpdateConnectionStatus = async (sortedUserIDSPK, status) => {
    if (!sortedUserIDSPK) return;

    setState({
      ...state,
      loadingConnection: true,
    });

    q.updateState(globalState);
    const res = await q.doQuery(
      "updateConnectionStatus",
      status === "ACCEPTED"
        ? "Connection request accepted"
        : "Connection request declined",
      "An error occured",
      {},
      p,
      sortedUserIDSPK,
      status
    );
    getNewUserData();
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"sm"}>
        <Box>
          <ScreenNavHeader title="Profile" />
        </Box>

        {state.loading && (
          <Stack my={4} alignItems={"center"}>
            <CircularProgress sx={{ color: "#2D9CDB" }} />
          </Stack>
        )}

        {!state.loading && (
          <>
            <Box mb={2}>
              <Stack display={"flex"} direction="column" alignItems={"center"}>
                <Stack
                  direction={"column"}
                  spacing={2}
                  justifyContent={"center"}
                  mb={2}
                >
                  <Avatar
                    sx={{ width: 128, height: 128, border: "solid 2px rgba(0,0,0,0.25)" }}
                    src={state?.images?.items?.[0]?.url}
                  />
                  <Stack
                    direction={"column"}
                    justifyContent={"center"}
                    sx={{ flexGrow: 1 }}
                  >
                    <Typography variant="body1" textAlign={"center"}>
                      {state?.givenName} {state?.familyName}
                    </Typography>
                    <Typography variant="body2">
                      {commentObj?.comment}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack
                  spacing={1}
                  display={"flex"}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                ></Stack>
              </Stack>
            </Box>

            <Stack
              mb={2}
              direction={"row"}
              spacing={2}
              justifyContent={"center"}
            >
              <Typography fontWeight={700}>
                {state.userContactIDs?.length || 0} Contacts
              </Typography>

              {globalState?.userData?.dynamoDB?.id !== params.id && (
                <Typography>
                  {intersectArrays(
                    state.userContactIDs || [],
                    globalState?.userData?.dynamoDB?.contacts?.items || []
                  ).length || 0}{" "}
                  Mutual
                </Typography>
              )}
            </Stack>

            <Box mb={2}>
              <Stack
                sx={{ maxWidth: 350, margin: "0 auto" }}
                direction={"row"}
                justifyContent={"center"}
                spacing={2}
              >
                <MetaInfo>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={1}
                    mb={0}
                  >
                    <SvgIcon>
                      <AddHomeIcon />
                    </SvgIcon>
                    <Typography>{state.rsvpTotal}</Typography>
                  </Stack>
                  <Typography>RSVPs</Typography>
                </MetaInfo>
                <MetaInfo>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={1}
                    mb={0}
                  >
                    <SvgIcon>
                      <CalendarMonthIcon />
                    </SvgIcon>
                    <Typography>
                      {new Date(state.createdAt).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                      })}
                    </Typography>
                  </Stack>
                  <Typography>Join Date</Typography>
                </MetaInfo>
                {/* <MetaInfo>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={1}
                    mb={0}
                  >
                    <SvgIcon>
                      <AddHomeIcon />
                    </SvgIcon>
                    <Typography>{state.activity?.items?.length}</Typography>
                  </Stack>
                  <Typography>RSVPs</Typography>
                </MetaInfo> */}
              </Stack>
            </Box>

            <Box mb={4}>
              <Stack direction={"row"} spacing={1} justifyContent={"center"}>
                {globalState?.userData?.userId === state.id ? (
                  <Stack spacing={1} direction={"row"}>
                    <Button component={ReactLink} to="/edit-profile">
                      Edit Your Profile
                    </Button>
                    <Button
                      onClick={async () => {
                        p.loggedIn = false;
                        p.loggingIn = false;
                        p.OTPStatus = null;
                        p.initialized = false;
                        p.checkingUserData = false;
                        p.authenticatedChecked = false;

                        delete p.prevPage;
                        delete p.currentPage;
                        delete p.journey;
                        delete p.submission;
                        delete p.userData;
                        delete p.notificationsCount;
                        delete p.notificationsInitialized;
                        delete p.nextTokenFeed;
                        delete p.homeScreenEventBinded;
                        delete p.routerChecking;

                        setGlobalState({
                          ...globalState,
                          loggedIn: false,
                          userData: {},
                          logoutUser: true,
                        });

                        localStorage.clear();
                        await signOut();
                      }}
                      color="error"
                    >
                      Logout
                    </Button>
                  </Stack>
                ) : state.loadingConnection ? (
                  <>
                    <CircularProgress sx={{ color: "#2D9CDB" }} size={42} />
                  </>
                ) : (
                  <>
                    {(() => {
                      if (
                        state.contactStatus &&
                        state.contactStatus !== "REQUESTED"
                      ) {
                        return (
                          <Button
                            color={"error"}
                            size="small"
                            onClick={onDeleteContactHandler}
                          >
                            Remove Contact
                          </Button>
                        );
                      } else if (state.contactStatus || state.connectDisabled) {
                        if (state.selfIsContactInitiater) {
                          return (
                            <Button
                              size="small"
                              disabled={state.loading}
                              color="error"
                              onClick={onDeleteContactHandler}
                            >
                              Withdraw Request
                            </Button>
                          );
                        } else {
                          return (
                            <Stack direction={"row"} spacing={0.5}>
                              <Button
                                size="small"
                                color="secondary"
                                disabled={state.loading}
                                onClick={() =>
                                  onUpdateConnectionStatus(
                                    state.sortedUserIDSPK,
                                    "ACCEPTED"
                                  )
                                }
                                sx={{ bgcolor: "secondary.dark" }}
                              >
                                Accept Request
                              </Button>
                              <Button
                                size="small"
                                color="secondary"
                                disabled={state.loading}
                                onClick={() =>
                                  onUpdateConnectionStatus(
                                    state.sortedUserIDSPK,
                                    "DECLINED"
                                  )
                                }
                                sx={{ bgcolor: "secondary.dark" }}
                              >
                                Decline Request
                              </Button>
                            </Stack>
                          );
                        }
                      } else {
                        return (
                          <Button
                            disabled={state.loading}
                            onClick={onCreateContactHandler}
                          >
                            {state.loading && (
                              <CircularProgress
                                size={18}
                                sx={{
                                  mr: 1,
                                  ml: -0.5,
                                  color: "rgba(0,0,0,0.25)",
                                }}
                              />
                            )}
                            Connect
                          </Button>
                        );
                      }
                    })()}
                  </>
                )}

                {globalState?.userData?.userId !== state.id && (
                  <IconButton
                    component={ReactLink}
                    to={`/chat/${state.id},${globalState?.userData?.userId}`}
                    sx={{ color: "#000" }}
                  >
                    <ChatBubbleIcon />
                  </IconButton>
                )}
                <IconButton
                  onClick={() =>
                    copyToClipboard(
                      `${process.env.REACT_APP_WEBSITE}/profile/${params.id}`,
                      () =>
                        setGlobalState({
                          ...globalState,
                          toaster: {
                            ...globalState?.toaster,
                            type: "info",
                            open: true,
                            bottom: 25,
                            message: `Share URL copied to clipboard: ${process.env.REACT_APP_WEBSITE}/profile/${params.id}`,
                          },
                        })
                    )
                  }
                  sx={{ color: "#000" }}
                >
                  <SendOutlinedIcon />
                </IconButton>
              </Stack>
            </Box>

            <Box>
              <Tabs
                defaultTab={state.defaultTab}
                callback={(newIndex) =>
                  (p.configs["/profile"] = {
                    ...p.configs["/profile"],
                    defaultTab: newIndex,
                  })
                }
                tabs={[
                  {
                    label: "About",
                    content: (
                      <Box>
                        <Box mb={2}>
                          <Typography variant="body1">
                            {state.bio || "This user does not have a bio"}
                          </Typography>
                        </Box>
                        {state.images?.items?.length > 0 && (
                          <Box mt={1}>
                            <ImageCarousel
                              centered
                              imagesData={state.images?.items}
                            />
                          </Box>
                        )}
                      </Box>
                    ),
                  },
                  {
                    label: "Calendar",
                    content: (
                      <>
                        <Stack
                          py={2}
                          mb={2}
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Switch
                            defaultIs0={true}
                            disabled={state.switchDisabled || state.loading}
                            width={200}
                            size="small"
                            optionA={"Upcoming"}
                            optionB={"Recent"}
                            callback={onSwitch}
                          ></Switch>
                        </Stack>

                        {
                          // state?.attendances?.items?.length < 1 &&
                          !state.loading &&
                          !state?.attendances?.items?.filter((activityObj) => {
                            if (activityObj.privacy === "PRIVATE") {
                              if (
                                // If user is the creator of the activity
                                activityObj.createdByID ===
                                  globalState?.userData?.userId ||
                                // Or if user is in the attendance list
                                activityObj?.activityAttendance?.items?.filter(
                                  (obj) => obj?.user?.id === p.userData?.userId
                                ).length > 0
                              ) {
                                return true;
                              } else {
                                return false;
                              }
                            } else {
                              return true;
                            }
                          }) ? (
                            <Stack direction="column" alignItems={"center"}>
                              <SvgIcon sx={{ mb: 3 }}>
                                <CalendarMonthIcon />
                              </SvgIcon>
                              <Typography
                                variant="h5"
                                fontWeight={700}
                                sx={{ mb: 2 }}
                              >
                                Activity Calendar
                              </Typography>
                              <Typography variant="h6">
                                No activity calendar available for this user.
                              </Typography>
                            </Stack>
                          ) : (
                            <>
                              {state.loadingActivities ? (
                                <Stack alignItems={"center"}>
                                  <CircularProgress />
                                </Stack>
                              ) : (
                                <Box mb={6}>
                                  <ActivityCardContainer
                                    loading={state.loading}
                                    data={state?.attendances?.items?.filter(
                                      (activityObj) => {
                                        if (activityObj.privacy === "PRIVATE") {
                                          if (
                                            // If user is the creator of the activity
                                            activityObj.createdByID ===
                                              globalState?.userData?.userId ||
                                            // Or if user is in the attendance list
                                            activityObj?.activityAttendance?.items?.filter(
                                              (obj) =>
                                                obj?.user?.id ===
                                                p.userData?.userId
                                            ).length > 0
                                          ) {
                                            return true;
                                          } else {
                                            return false;
                                          }
                                        } else {
                                          return true;
                                        }
                                      }
                                    )}
                                  />
                                  <Stack
                                    direction={"row"}
                                    justifyContent={"center"}
                                  >
                                    {state.loadingCalendar ? (
                                      <CircularProgress
                                        sx={{ color: "#2D9CDB" }}
                                      />
                                    ) : state.nextTokenAttendances ? (
                                      <Button
                                        onClick={() =>
                                          getNewUserData(false, true)
                                        }
                                      >
                                        Load More
                                      </Button>
                                    ) : (
                                      <></>
                                    )}
                                  </Stack>
                                </Box>
                              )}
                            </>
                          )
                        }
                      </>
                    ),
                  },
                  {
                    label: "Posts",
                    content: (
                      <>
                        {state.posts?.length > 0 ? (
                          <ActivityPostCardContainer
                            loading={globalState?.searchLoading}
                            data={state.posts}
                          />
                        ) : (
                          <Stack direction="column" alignItems={"center"}>
                            <SvgIcon sx={{ mb: 3 }}>
                              <AddHomeIcon />
                            </SvgIcon>
                            <Typography
                              variant="h5"
                              fontWeight={700}
                              sx={{ mb: 2 }}
                            >
                              User Posts
                            </Typography>
                            <Typography variant="h6">
                              No posts available from this user.
                            </Typography>
                          </Stack>
                        )}
                      </>
                    ),
                  },
                  // {
                  //   label: "Feedback",
                  //   content: (
                  //     <>
                  //       {state.feedback?.items?.length === 0 && (
                  //         <Stack direction="column" alignItems={"center"}>
                  //           <SvgIcon sx={{ mb: 3 }}>
                  //             <SpeakerNotesOffOutlinedIcon />
                  //           </SvgIcon>
                  //           <Typography
                  //             variant="h5"
                  //             fontWeight={700}
                  //             sx={{ mb: 2 }}
                  //           >
                  //             Feedback Wall
                  //           </Typography>
                  //           <Typography variant="h6">
                  //             No activity feedback available.
                  //           </Typography>
                  //         </Stack>
                  //       )}
                  //       {state?.feedback?.items?.map((feedbackObj, i) => {
                  //         return (
                  //           <>
                  //             <Stack
                  //               key={`comment-${i}`}
                  //               direction={"row"}
                  //               spacing={2}
                  //               alignItems={"flex-start"}
                  //               mb={4}
                  //             >
                  //               <AvatarLink
                  //                 to={`/profile/${feedbackObj?.createdBy?.id}`}
                  //                 sx={{ width: 48, height: 48 }}
                  //                 src={
                  //                   feedbackObj?.createdBy?.images?.items?.[0]
                  //                     ?.url
                  //                 }
                  //               />
                  //               <Stack
                  //                 direction={"column"}
                  //                 sx={{ flexGrow: 1 }}
                  //               >
                  //                 <Box mb={2}>
                  //                   <Stack
                  //                     direction={"row"}
                  //                     justifyContent={"space-between"}
                  //                     spacing={2}
                  //                   >
                  //                     <Typography variant="body2">
                  //                       {feedbackObj?.createdBy?.givenName}{" "}
                  //                       {feedbackObj?.createdBy?.familyName}
                  //                     </Typography>
                  //                     <Typography variant="caption">
                  //                       {timestampdifference(
                  //                         nowDateTime,
                  //                         feedbackObj.createdAt
                  //                       )}
                  //                     </Typography>
                  //                   </Stack>
                  //                   <Stack direction={"row"}>
                  //                     {/* <Rating
                  //                       disabled
                  //                       size="small"
                  //                       value={feedbackObj.rate}
                  //                     /> */}
                  //                   </Stack>
                  //                   <Typography variant="body1">
                  //                     {feedbackObj?.feedback && (
                  //                       <>
                  //                         &#x301D; {feedbackObj?.feedback}
                  //                         &#x301E;
                  //                       </>
                  //                     )}
                  //                   </Typography>
                  //                 </Box>
                  //                 <Typography>
                  //                   <ReactLink
                  //                     to={`/activity/${feedbackObj?.activity?.id}`}
                  //                   >
                  //                     {feedbackObj?.activity?.name} (view
                  //                     activity)
                  //                   </ReactLink>
                  //                 </Typography>
                  //               </Stack>
                  //             </Stack>
                  //           </>
                  //         );
                  //       })}{" "}
                  //     </>
                  //   ),
                  // },
                ]}
              />
            </Box>
          </>
        )}
      </Container>
    </ThemeProvider>
  );
};

export const ProfilePageScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar pb={0} waitForUserData={props.waitForUserData}>
      <ProfilePageScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

ProfilePageScreen.propTypes = {};

ProfilePageScreen.defaultProps = {
  color: "primary",
};
