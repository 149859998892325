import React, { useContext, useEffect, useRef, useState } from "react";
import { Link as ReactLink } from "react-router-dom";
import {
  Avatar,
  Box,
  Button as MuiButton,
  Container,
  List,
  ListItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AppContext from "../../AppContext";
import EventIcon from "@mui/icons-material/Event";
import { AvatarLink } from "../basic/AvatarLink";
import { Button } from "../basic/Button";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import updateUserNotification from "./queries/updateUserNotification.js";
import getUserNotificationsByReceiverUser from "./queries/getUserNotificationsByReceiverUser.js";
import updateConnectionStatus from "./queries/updateConnectionStatus.js";
import timestampdifference from "../utils/timestampdifference";
import { generateClient, post } from "aws-amplify/api";
const client = generateClient();

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

// Function to compare two items by date
function compareByDate(item1, item2) {
  const date1 = new Date(item1.createdAt);
  const date2 = new Date(item2.createdAt);

  // Compare the dates
  if (date1 > date2) {
    return -1;
  }
  if (date1 < date2) {
    return 1;
  }
  return 0;
}

const pathMap = {
  POST_TAG: "posts",
  POST_LIKE: "posts",
  POST_COMMENT: "comments",
  ACTIVITY_START: "activity",
  CHAT_MESSAGE: "chat",
};

// Make structure of PushNotification item similar
// to UserNotification so that items from both tables
// cab be used.
const flattenPushNotification = (notificationObject) => {
  // console.log("notificationObject.userObject", notificationObject.userObject)
  notificationObject = {
    ...notificationObject,
    ...notificationObject.data,
    userObject: { ...notificationObject.userObject },
    sortedUserIDSPK:
      notificationObject.userObject?.contacts?.items?.[0]?.sortedUserIDSPK,
    // activityObject: notificationObject.activityObject,
    // postObject: notificationObject.postObject
  };
  notificationObject.userObject = { ...notificationObject.info.sender };
  return notificationObject;
};

const createNotificationText = (notificationObject) => {
  const { activityObject, data, userObject } = notificationObject;
  const { identity } = data;

  switch (identity) {
    case "POST_LIKE":
      return `${userObject?.givenName} ${userObject?.familyName} has liked your post`;
    case "POST_TAG":
      return `${userObject?.givenName} ${userObject?.familyName} has tagged you in a post`;
    case "POST_COMMENT":
      return `${userObject?.givenName} ${userObject?.familyName} has commented on your post`;
    case "ACTIVITY_START":
      return `${activityObject?.name} is starting`;
    case "ACTIVITY_FINISHED":
      return `${activityObject?.name} has ended`;
    case "ACTIVITY_REQUEST":
      return `${userObject?.givenName} ${userObject?.familyName} has requested to join your activity`;
    case "ACTIVITY_INVITE":
      return `${userObject?.givenName} ${userObject?.familyName} has invited you to an activity`;
    case "ACTIVITY_JOIN":
      return `${userObject?.givenName} ${userObject?.familyName} has joined your activity`;
    case "ACTIVITY_APPROVED":
      return `${userObject?.givenName} ${userObject?.familyName} has accepted your activity invite`;
    case "CLAN_INVITE":
      return `${userObject?.givenName} ${userObject?.familyName} has invited you to clan`;
    case "CONTACT_REQUEST":
      return `${userObject?.givenName} ${userObject?.familyName} has invited you to connect`;
    case "CHAT_MESSAGE":
      return `${userObject?.givenName} ${userObject?.familyName} has sent you a message`;
    default:
      return `A user has interacted with you. ${identity}`;
  }
};

const onAcceptInvite = async (notificationObject /*event*/) => {
  const metaParsed = JSON.parse(notificationObject.meta).replace(
    /\\{1,}/g,
    "\\"
  );

  // Parse the outer string as JSON
  var jsonObject = JSON.parse(metaParsed);

  // Parse the `info` property's value as JSON
  jsonObject.info = JSON.parse(jsonObject.info);

  const senderID = jsonObject.info.sender.id;
  const receiverID = jsonObject.info.receiverUserID;
  const sortedUserIDSPK = `${senderID}#${receiverID}`;

  try {
    const res = await client.graphql({
      query: updateConnectionStatus(sortedUserIDSPK),
    });
  } catch (error) {
    console.log("error", error);
  }
};

const ChipCount = function (props) {
  return (
    <Box
      sx={{
        background: "white",
        borderRadius: "100%",
        display: "flex",
        width: 18,
        height: 18,
        ml: 1,
      }}
      justifyContent={"center"}
      alignContent={"center"}
      alignItems={"center"}
    >
      <Typography variant="caption">{props.count}</Typography>
    </Box>
  );
};

/**
 * Primary UI component for user interaction
 */
export const ChatNotificationsScreen = ({ ...props }) => {
  const [
    globalState,
    setGlobalState,
    q,
    p,
    connectionStatus,
    isBackgroundLoaded,
    notificationsGlobalState,
    setNotificationsGlobalState,
  ] = useContext(AppContext);
  const [state, setState] = useState({
    data: [],
    nextToken: null,
    loading: true,
    filter: "Messages",
  });

  const nowDateTime = new Date();
  const notificationItems = useRef();

  const fetchData = async () => {
    var apiKey = "da2-22ztnhuamje4jptwonwzfafdi4"; // Replace 'xxx' with your actual API key

    await fetch(
      "https://3arkzxpkarhoflbprgz4tpruai.appsync-api.us-east-1.amazonaws.com/graphql",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: JSON.stringify({
          query: getUserNotificationsByReceiverUser(p?.userData?.userId),
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        // For activity invitations, contact invitiations, etc.
        const typeCount = {};
        
        res.data.getUserNotificationsByReceiverUser.items.forEach(
          (objString, i) => {
            var obj = objString;
            obj.data.info = JSON.parse(obj.data.info);

            typeCount[obj.data.identity] = typeCount[obj.data.identity]
              ? // Increment if element counted before
                typeCount[obj.data.identity] + 1
              : // Or add 1 for first occurance
                1;

            if (
              typeof res.data.getUserNotificationsByReceiverUser.items[i].data
                .info === "string"
            ) {
              res.data.getUserNotificationsByReceiverUser.items[i].data.info =
                JSON.parse(obj.data.info);
            }
          }
        );

        notificationItems.current =
          res.data.getUserNotificationsByReceiverUser.items;

        setState({
          ...state,
          data: [...res.data.getUserNotificationsByReceiverUser.items],
          typeCount,
          loading: false,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const updateToSeen = async () => {
    if (!notificationItems.current) return;
    var apiKey = "da2-22ztnhuamje4jptwonwzfafdi4"; // Replace 'xxx' with your actual API key

    // Localy update state of messages
    setNotificationsGlobalState({
      ...notificationsGlobalState,
      newMessages: [
        ...notificationItems.current.map((obj) => ({ ...obj, status: "SEEN" })),
      ],
    });

    await fetch(
      "https://3arkzxpkarhoflbprgz4tpruai.appsync-api.us-east-1.amazonaws.com/graphql",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: JSON.stringify({
          query: updateUserNotification(notificationItems.current),
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        // setNotificationsGlobalState({
        //   ...notificationsGlobalState,
        //   newMessages: [...notificationItems.current.map(obj=>({...obj, status: "SEEN"}))],
        // })
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const onFilterHandler = (event) => {
    const filterName = event.currentTarget.getAttribute("label");
    setState({
      ...state,
      filter: filterName,
    });
  };

  useEffect(() => {
    if (!globalState?.userData?.userId) {
      return;
    }
    (async () => {
      await fetchData();
      updateToSeen();
    })();
  }, [
    globalState?.userData,
    // notificationsGlobalState?.newMessages
  ]);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"sm"}>
        <Box>
          <Box mb={0}>
            <ScreenNavHeader title="Message Notifications" />
          </Box>

          <Box mb={4} sx={{ overflowX: "scroll" }}>
            <Stack
              pb={3}
              sx={{ width: "fit-content" }}
              direction={"row"}
              spacing={1}
            >
              {[["Messages", state?.typeCount?.["CHAT_MESSAGE"] || 0]].map(
                (filterArr) => (
                  <Button
                    size="small"
                    fullWidth
                    color={"secondary"}
                    label={filterArr[0]}
                    onClick={onFilterHandler}
                    startIcon={filterArr?.[2] && EventIcon}
                    sx={{
                      background:
                        state.filter === filterArr[0] ? "#8EC8E9" : "#f3f4f5",
                      minWidth: "fit-content",
                      "&:hover": {
                        background:
                          state.filter === filterArr[0] ? "#8EC8E9" : "#f3f4f5",
                      },
                    }}
                  >
                    {filterArr[0]}
                    <ChipCount count={filterArr[1]} />
                  </Button>
                )
              )}
            </Stack>
          </Box>

          <Box>
            <List sx={{ m: 0, p: 0, listStyle: "none" }}>
              {state.loading &&
                // state?.data?.length === 0 &&
                [1, 2, 3, 4].map((v, i) => (
                  <Stack
                    key={`skeleton-activity-${i}`}
                    direction={"column"}
                    spacing={1}
                    mb={4}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      spacing={2}
                      sx={{ width: "100%" }}
                    >
                      <Skeleton
                        animation={"wave"}
                        variant="circular"
                        width={48}
                        height={48}
                      />
                      <Stack
                        direction={"row"}
                        spacing={1}
                        flexGrow={1}
                        justifyContent={"space-between"}
                      >
                        <Stack direction={"column"} spacing={1}>
                          <Skeleton variant="rounded" width={160} height={10} />
                          <Skeleton
                            variant="rounded"
                            width={100}
                            height={10}
                            sx={{ flexGrow: 1 }}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                ))}

              {!state.loading &&
                state.data
                  .filter((notificationObject) => {
                    notificationObject =
                      flattenPushNotification(notificationObject);
                    if (state.filter === "All") {
                      return true;
                    } else if (state.filter === "Updates") {
                      if (
                        notificationObject.data?.identity ===
                          "ACTIVITY_START" ||
                        notificationObject.data?.identity === "ACTIVITY_FINISH"
                      ) {
                        return true;
                      }
                    } else if (
                      state.filter === "Contact requests" &&
                      notificationObject.data?.identity === "CONTACT_REQUEST"
                    ) {
                      return true;
                    } else if (
                      state.filter === "Activity joining requests" &&
                      notificationObject.data?.identity === "ACTIVITY_REQUEST"
                    ) {
                      return true;
                    } else if (
                      state.filter === "Activity invitations" &&
                      notificationObject.data?.identity === "ACTIVITY_INVITE"
                    ) {
                      return true;
                    } else if (
                      state.filter === "Reactions" &&
                      (notificationObject.data?.identity === "POST_LIKE" ||
                        notificationObject.data?.identity === "POST_TAG")
                    ) {
                      return true;
                    } else if (
                      state.filter === "Comments" &&
                      notificationObject.data?.identity === "POST_COMMENT"
                    ) {
                      return true;
                    } else if (
                      state.filter === "Messages" &&
                      notificationObject.data?.identity === "CHAT_MESSAGE"
                    ) {
                      return true;
                    } else {
                      return notificationObject.data?.identity === state.filter;
                    }
                  })
                  .sort(compareByDate)
                  .map((notificationObject, i) => {
                    return (
                      <ListItem disablePadding>
                        <Stack
                          key={`notification-${i}`}
                          direction={"row"}
                          spacing={2}
                          alignItems={"flex-start"}
                          mb={4}
                          sx={{ width: "100%" }}
                        >
                          <AvatarLink
                            to={`/profile/${notificationObject?.userObject?.id}`}
                            sx={{ width: 48, height: 48 }}
                            src={
                              notificationObject?.data?.identity?.substring(
                                0,
                                4
                              ) === "POST"
                                ? notificationObject?.userObject?.image?.url ||
                                  notificationObject?.userObject?.images
                                    ?.items?.[0]?.url
                                : notificationObject?.userObject?.image?.url ||
                                  notificationObject?.userObject?.images
                                    ?.items?.[0]?.url ||
                                  `${process.env.REACT_APP_WEBSITE}/logo-color.png`
                            }
                          />

                          <Stack direction={"column"} sx={{ flexGrow: 1 }}>
                            <Typography variant="body1">
                              {notificationObject?.pushNotificationBody}
                            </Typography>

                            <Stack
                              direction="row"
                              justifyContent={"space-between"}
                              sx={{ width: "100%" }}
                            >
                              <Typography variant="body1">
                                {createNotificationText(notificationObject)}
                              </Typography>
                              <Typography variant="caption" textAlign={"right"}>
                                {timestampdifference(
                                  nowDateTime,
                                  notificationObject.createdAt
                                )}
                              </Typography>
                            </Stack>

                            <ReactLink
                              to={`/${
                                pathMap[notificationObject?.data?.identity]
                              }/${notificationObject?.postObject?.id}`}
                            >
                              <Typography variant="body1">
                                {notificationObject?.postObject?.text}
                              </Typography>
                            </ReactLink>

                            <ReactLink
                              style={{ textDecoration: "none" }}
                              to={`/${
                                pathMap[notificationObject?.data?.identity]
                              }/${notificationObject?.userObject?.contacts?.items?.[0]?.sortedUserIDSPK?.replace(
                                "#",
                                ","
                              )}`}
                            >
                              <Typography sx={{ ml: -0.5 }} variant="body2">
                                {notificationObject?.chatObject?.content
                                  ?.text && (
                                  <>
                                    &#x301D;{" "}
                                    {
                                      notificationObject?.chatObject?.content
                                        ?.text
                                    }{" "}
                                    &#x301E;
                                  </>
                                )}
                              </Typography>
                            </ReactLink>
                            <ReactLink
                              to={`/${
                                pathMap[notificationObject?.data?.identity]
                              }/${notificationObject?.activityObject?.id}`}
                            >
                              <Typography variant="body1" fontWeight={700}>
                                {notificationObject?.activityObject?.name}
                              </Typography>
                            </ReactLink>
                            {notificationObject.data?.identity ===
                              "CONTACT_REQUEST" && (
                              <Stack mt={1} direction={"row"} spacing={0.5}>
                                <Button
                                  notificationObject={notificationObject}
                                  onClick={() =>
                                    onAcceptInvite(notificationObject)
                                  }
                                  color="secondary"
                                  sx={{ bgcolor: "secondary.dark" }}
                                  size="small"
                                >
                                  Accept
                                </Button>
                                <Button
                                  color="secondary"
                                  sx={{ bgcolor: "secondary.dark" }}
                                  size="small"
                                >
                                  Decline
                                </Button>
                              </Stack>
                            )}
                          </Stack>
                        </Stack>
                      </ListItem>
                    );
                  })}
            </List>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export const ChatNotificationsScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
      <ChatNotificationsScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

ChatNotificationsScreen.propTypes = {};

ChatNotificationsScreen.defaultProps = {
  color: "primary",
};
