/*
 * The prefix will be used when GetChatChannelBySortedP2PUserIDs is called
 * for multiple chat channels simultaneously. See MessagesScreen.jsx for an example.
 */
const getChatChannelBySortedP2PUserIDS = (
  receiverID,
  senderID,
  userID = null,
  input = null
) => `
query GetChatChannelBySortedP2PUserIDs {
  q1: getChatChannelBySortedP2PUserIDS(sortedP2PUserIDSPK: "${receiverID}#${senderID}") {
    createdAt
    updatedAt
    name
    id
    createdByID
    latestMessageAt
    chatChannelMessages(limit: 600) {
      nextToken
      items {
        content {
          data
          text
        }
        id
        type
        updatedAt
        createdAt
        createdByID
        channelID
        messageToken
        createdBy {
          email
          familyName
          givenName
          id
          phoneNumber
          images {
            items {
              id
              index
              url
            }
          }
        }
      }
    }
    chatChannelMembers {
      items {
        user {
          email
          id
          familyName
          givenName
          phoneNumber
          numberOfVotes
          sumOfVotes
          images {
            items {
              id
              url
              index
            }
          }
        }
        id
        userID
        channelID
        createdAt
        updatedAt
        status
        role
      }
    }
    latestMessage {
      content {
        data
        text
      }
      createdAt
      updatedAt
      type
      id
      createdByID
      channelID
      messageToken
      createdBy {
        email
        familyName
        givenName
        id
        images {
          items {
            id
            url
            index
          }
        }
      }
    }
  }
  q2: getChatChannelBySortedP2PUserIDS(sortedP2PUserIDSPK: "${senderID}#${receiverID}") {
    createdAt
    updatedAt
    name
    id
    createdByID
    latestMessageAt
    chatChannelMessages(limit: 600) {
      nextToken
      items {
        content {
          data
          text
        }
        id
        type
        updatedAt
        createdAt
        createdByID
        channelID
        messageToken
        createdBy {
          email
          familyName
          givenName
          id
          phoneNumber
          images {
            items {
              id
              index
              url
            }
          }
        }
      }
    }
    chatChannelMembers {
      items {
        user {
          email
          id
          familyName
          givenName
          phoneNumber
          numberOfVotes
          sumOfVotes
          images {
            items {
              id
              url
              index
            }
          }
        }
        id
        userID
        channelID
        createdAt
        updatedAt
        status
        role
      }
    }
    latestMessage {
      content {
        data
        text
      }
      createdAt
      updatedAt
      type
      id
      createdByID
      channelID
      messageToken
      createdBy {
        email
        familyName
        givenName
        id
        images {
          items {
            id
            url
            index
          }
        }
      }
    }
  }
}`;

export const getChatChannelBySortedP2PUserIDS_array = (
  receiverID,
  contactIDs,
  userID = null,
  input = null
) => {
  return `query GetChatChannelBySortedP2PUserIDs {
    ${contactIDs
      .map(
        (sender, i) => `
      q${i}1: getChatChannelBySortedP2PUserIDS(sortedP2PUserIDSPK: "${receiverID}#${sender?.contactID}") {
        createdAt
        updatedAt
        name
        id
        createdByID
        latestMessageAt
        chatChannelMessages(limit: 1) {
          items {
            content {
              data
              text
            }
            id
            type
            updatedAt
            createdAt
            createdByID
            channelID
            messageToken
            createdBy {
              email
              familyName
              givenName
              id
              phoneNumber
              images {
                items {
                  id
                  index
                  url
                }
              }
            }
          }
        }
        chatChannelMembers {
          items {
            user {
              email
              id
              familyName
              givenName
              phoneNumber
              numberOfVotes
              sumOfVotes
              images {
                items {
                  id
                  url
                  index
                }
              }
            }
            id
            userID
            channelID
            createdAt
            updatedAt
            status
            role
          }
        }
        latestMessage {
          content {
            data
            text
          }
          createdAt
          updatedAt
          type
          id
          createdByID
          channelID
          messageToken
          createdBy {
            email
            familyName
            givenName
            id
            images {
              items {
                id
                url
                index
              }
            }
          }
        }
      }
      q${i}2: getChatChannelBySortedP2PUserIDS(sortedP2PUserIDSPK: "${sender?.contactID}#${receiverID}") {
        createdAt
        updatedAt
        name
        id
        createdByID
        latestMessageAt
        chatChannelMessages(limit: 1) {
          items {
            content {
              data
              text
            }
            id
            type
            updatedAt
            createdAt
            createdByID
            channelID
            messageToken
            createdBy {
              email
              familyName
              givenName
              id
              phoneNumber
              images {
                items {
                  id
                  index
                  url
                }
              }
            }
          }
        }
        chatChannelMembers {
          items {
            user {
              email
              id
              familyName
              givenName
              phoneNumber
              numberOfVotes
              sumOfVotes
              images {
                items {
                  id
                  url
                  index
                }
              }
            }
            id
            userID
            channelID
            createdAt
            updatedAt
            status
            role
          }
        }
        latestMessage {
          content {
            data
            text
          }
          createdAt
          updatedAt
          type
          id
          createdByID
          channelID
          messageToken
          createdBy {
            email
            familyName
            givenName
            id
            images {
              items {
                id
                url
                index
              }
            }
          }
        }
      }
    `
      )
      .join("")}
  }`;
};

export const getContactObjects = (contactIDs) => `
  query GetChatChannelBySortedP2PUserIDs {
   ${contactIDs.map(
     (contact, i) =>
       `c${i}: getContact(id: "${contact?.id}") {
          id
          requesterID
          userID
          contactID
          twinID
          contactPhoneNumber
          sortedUserIDSPK
          userIDcontactIDPK
          status
          __typename
      }`
   )}
}`;

export default getChatChannelBySortedP2PUserIDS;
