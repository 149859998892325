import React, { useContext, useState } from "react";
import AppContext from "../../AppContext";
import { Link as ReactLink, useParams } from "react-router-dom";
import {
  Box,
  Button as MUIButton,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button } from "../basic/Button";
import PropTypes from "prop-types";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import { styled } from "@mui/system";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import IosShareIcon from "@mui/icons-material/IosShare";
import copyToClipboard from "../utils/copyToClipboard.js";

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

const StyledBox = styled(Box)(
  ({ theme }) => `
  &.MuiBox-root {
    max-width: 320px;
    border-radius: 16px;
    border: solid 2px #B9DEF3;
    padding: ${theme.spacing(4)};
    text-transform: none;
    text-align: left;
  }
  &.selected {
    opacity: 1;
    background: rgba(25, 118, 210, 0.04)
  }
  &.deselected {
    opacity: 0.5;
  }
`
);

/**
 * Primary UI component for user interaction
 */
export const ActivityCreatedScreen = ({ ...props }) => {

  const params = useParams();

  const [state, setState] = useState({
    toasterMessage: ""
  })
  const [globalState, setGlobalState, q, p] = useContext(AppContext);


  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"xs"}>
        <Stack spacing={8}>
          <Stack alignItems={"center"}>
            <Box
              mb={2}
              sx={{ width: 128 }}
              component={"img"}
              src={`${process.env.REACT_APP_WEBSITE}/green-check.png`}
            />
            <Typography variant="h5">Activity Created</Typography>
            <Typography variant="body1">
              Your activity has been successfully created.
            </Typography>
          </Stack>

          <StyledBox>
            <Typography variant="h5" fontWeight={700}>
              Invite people
            </Typography>
            <Typography>Invite people to your Activity</Typography>
            <Stack mt={2} spacing={1}>
              <Button 
              component={ReactLink}
              to="/invite"
              startIcon={PersonAddIcon} fullWidth size={"large"}>
                Invite People
              </Button>
              <Button
                onClick={() =>
                  copyToClipboard(
                    `${process.env.REACT_APP_WEBSITE}/activity/${params.id}`,
                    ()=>setGlobalState({
                      ...globalState,
                      toaster: {
                        ...globalState?.toaster,
                        type: "info",
                        open: true,
                        bottom: 25,
                        message: `Share URL copied to clipboard: ${process.env.REACT_APP_WEBSITE}/activity/${params.id}`
                      }
                    })
                  )
                }
                startIcon={IosShareIcon}
                fullWidth
                size={"large"}
                color="secondary"
              >
                Share Activity
              </Button>
            </Stack>
          </StyledBox>
          <Button
            component={ReactLink}
            to={`/activity/${params.id}`}
            startIcon={ChevronRightIcon}
            fullWidth
          >
            Go to Activity
          </Button>
        </Stack>
      </Container>
    </ThemeProvider>
  );
};

export const ActivityCreatedScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
      <ActivityCreatedScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

ActivityCreatedScreen.propTypes = {};

ActivityCreatedScreen.defaultProps = {
  color: "primary",
};
