export const formatLinkText = (link) => {
    var newLink = link;
  
    if (true) {
      if (link.length >= 40) {
        newLink = link.slice(0, 40) + "...";
      } else {
        newLink = link;
      }
    }
  
    return newLink
      ?.replaceAll(/(http\:\/\/)+/gi, "http://")
      ?.replaceAll(/(https\:\/\/)+/gi, "https://");
  };
  
  export const formatLink = (link) => {

    var newLink = link
      ?.replaceAll(/(http\:\/\/)+/gi, "http://")
      ?.replaceAll(/(https\:\/\/)+/gi, "https://");
  
    if (
      newLink?.substring(0, 24) === process.env.REACT_APP_WEBSITE.substring(0, 24)
    ) {
      newLink = newLink?.replaceAll(process.env.REACT_APP_WEBSITE, "")?.replaceAll("http://www.sociogram.io", "");
    }
  
    return newLink;
  };
  