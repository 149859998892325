import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Button } from "../basic/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import DatePicker from "./DatePicker";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

export const AppDialog = (props) => {
  const { onClose, selectedValue, open } = props;
  const [state, setState] = useState(open ? open : false);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  useEffect(() => {
    setState(
      // ...state,
      props.open
    );
  }, [props.open]);

  return (
    <Dialog onClose={handleClose} open={state}>
      <Box sx={{ position: "absolute", top: 6, right: 6 }}>
        <IconButton onClick={handleClose}>
          <SvgIcon>
            <CloseIcon />
          </SvgIcon>
        </IconButton>
      </Box>
      <DialogTitle
        textAlign={"center"}
        gutterBottom
        variant="h5"
        sx={{ mt: 3, mb: 0 }}
      >
        {props.title}
      </DialogTitle>
      <Box px={0} pb={3}>
        {props.children}
        <Box sx={{ maxWidth: props.maxButtonWidth || 250 }} mx={"auto"} mt={4}>
          <Stack direction="column" spacing={1}>
            <Button 
            disabled={props.buttonDisabled}
            onClick={props.onConfirm} startIcon={CheckIcon} fullWidth>
              {props.confirmText || "Done"}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Dialog>
  );
};

AppDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
