import React from "react";
import { Link as ReactLink } from "react-router-dom";
import { Box, Container, Stack, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button } from "../basic/Button";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { StepperProgress } from "../basic/StepperProgress";
import { styled } from '@mui/system';
import PropTypes from "prop-types";

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

const ResponsiveStack = styled(Stack)(
  ({ theme }) => ({
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    }
  })
)

/**
 * Primary UI component for user interaction
 */
export const OnboardingContainer = ({ ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        px={{xs: 2, sm: 4}}
        pt={0}
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: 360,
          height: "100%",
        }}
      >
        <ResponsiveStack direction={"row"} justifyContent="center">
          <StepperProgress steps={[1,2]} color="blue" step={props.step} />
        </ResponsiveStack>

        <Box mb={0}>
          <Typography gutterBottom variant="h4" sx={{ fontWeight: 700 }}>
            {props.title}
          </Typography>
          <Typography gutterBottom variant="body1">
            {props.description}
          </Typography>
        </Box>


        {props.children}

        {/* {
          !props.customHandler &&
          <Button
            component={ReactLink}
            startIcon={ChevronRightIcon}
            to={props.btnPath}
          >
            {props.btnLabel}
          </Button>
        }
        {
          props.customHandler
        } */}
      </Box>
    </ThemeProvider>
  );
};

OnboardingContainer.propTypes = {};

OnboardingContainer.defaultProps = {
  color: "primary",
};
