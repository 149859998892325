import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../AppContext";
import { Link as ReactLink, useParams } from "react-router-dom";
import {
  Box,
  Button as MUIButton,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button } from "../basic/Button";
import PropTypes from "prop-types";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import { ResponsiveToaster } from "../basic/ResponsiveToaster.jsx";
import { styled } from "@mui/system";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import IosShareIcon from "@mui/icons-material/IosShare";
import copyToClipboard from "../utils/copyToClipboard.js";

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

const StyledBox = styled(Box)(
  ({ theme }) => `
  &.MuiBox-root {
    max-width: 320px;
    border-radius: 16px;
    border: solid 2px #B9DEF3;
    padding: ${theme.spacing(4)};
    text-transform: none;
    text-align: left;
  }
  &.selected {
    opacity: 1;
    background: rgba(25, 118, 210, 0.04)
  }
  &.deselected {
    opacity: 0.5;
  }
`
);

/**
 * Primary UI component for user interaction
 */
export const PostCreatedScreen = ({ ...props }) => {
  const params = useParams();
  const [state, setState] = useState({
    toasterMessage: "",
  });
  const [globalState, setGlobalState, q, p] = useContext(AppContext);

  useEffect(() => {
    p.disableBlock = true;
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"xs"}>
        <Stack spacing={8}>
          <Stack alignItems={"center"}>
            <Box
              mb={2}
              sx={{ width: 128 }}
              component={"img"}
              src={`${process.env.REACT_APP_WEBSITE}/green-check.png`}
            />
            <Typography variant="h5">Post Created</Typography>
            <Typography variant="body1">
              Your post has been successfully created.
            </Typography>
          </Stack>

          <StyledBox>
            <Typography variant="h5" fontWeight={700}>
              Share Your Post
            </Typography>
            <Typography>Share your post for better engagement.</Typography>
            <Stack mt={2} spacing={1}>
              <Button
                onClick={() =>
                  copyToClipboard(
                    `${process.env.REACT_APP_WEBSITE}/posts/${
                      globalState?.submission?.id || params.post
                    }`,
                    () =>
                      copyToClipboard(
                        `${process.env.REACT_APP_WEBSITE}/posts/${params.post}`,
                        () =>
                          setGlobalState({
                            ...globalState,
                            toaster: {
                              ...globalState?.toaster,
                              type: "info",
                              open: true,
                              bottom: 25,
                              message: `Share URL copied to clipboard: ${
                                process.env.REACT_APP_WEBSITE
                              }/posts/${
                                globalState?.submission?.id || params.post
                              }`,
                            },
                          })
                      )
                  )
                }
                startIcon={IosShareIcon}
                fullWidth
                size={"large"}
              >
                Share Post
              </Button>
            </Stack>
          </StyledBox>
          <Button
            component={ReactLink}
            to={`${process.env.REACT_APP_WEBSITE}/posts/${
              globalState?.submission?.id || params.post
            }`}
            startIcon={ChevronRightIcon}
            fullWidth
          >
            Go to Post
          </Button>
        </Stack>
      </Container>
    </ThemeProvider>
  );
};

export const PostCreatedScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
      <PostCreatedScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

PostCreatedScreen.propTypes = {};

PostCreatedScreen.defaultProps = {
  color: "primary",
};
