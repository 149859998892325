const createActivityRequest = (activityID) => `mutation MyMutation {
  createActivityRequest(input: 
    {
        activityID: "${activityID}"
    }) {
    id
    status
    statusActivityIDPK
  }
}`;

export default createActivityRequest;
// 2b5561be-7bfa-41c0-b3ba-1907856e3bce