import React, { useState } from "react";

const Dial = () => {
  const [rotation, setRotation] = useState(0);

  const handleMouseMove = (event) => {
    // return
    const { clientY } = event;
    const componentHeight = window.innerHeight;
    const rotationPercentage = (clientY / componentHeight) * 180;
    setRotation(rotationPercentage - 90);
  };

  return (
    <div
      style={{ height: "100vh", width: "100vw" }}
      onMouseMove={handleMouseMove}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "100px",
          height: "100px",
          borderRadius: "50%",
          borderColor: "currentcolor",
          borderStyle: "solid",
          borderWidth: "25px",
          transform: `translate(-50%, -50%) rotate(${rotation}deg)`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column"
        }}
      >
        <div
          style={{
            width: "48px",
            height: "48px",
            background: "blue",
            fontSize: "20px",
            position: "relative",
            top: "-35px",
            borderRadius: "100%",
            textAlign: "center",
          }}
        >
          1
        </div>

        <div
          style={{
            width: "48px",
            height: "48px",
            background: "blue",
            fontSize: "20px",
            position: "relative",
            right: "-70px",
            borderRadius: "100%",
            textAlign: "center",
          }}
        >2
        </div>

        <div
          style={{
            width: "48px",
            height: "48px",
            background: "blue",
            fontSize: "20px",
            position: "relative",
            bottom: "-35px",
            borderRadius: "100%",
            textAlign: "center",
          }}
        >3
        </div>
      </div>
    </div>
  );
};

export default Dial;
