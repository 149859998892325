import React from "react";
import { Link as ReactLink, useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Button as MuiButton,
  CircularProgress,
  Container,
  IconButton,
  Rating,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { Button } from "../basic/Button";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";

const theme = createTheme({
    palette: {},
    components: {
      // MuiTextField: {
      //   styleOverrides: {
      //     root: {
      //     },
      //   },
      // },
    },
  });

  
/**
 * Primary UI component for user interaction
 */
export const Page404Screen = ({ ...props }) => {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"sm"}>
        <Box>
          <ScreenNavHeader title="Page Not Found" />
        </Box>

        <Stack mt={10} spacing={2} justifyContent={"center"}>
            <Typography variant="h2" textAlign={"center"}>404</Typography>
            <Typography textAlign={"center"}>Oops! This page does not exist.</Typography>

            <Button component={ReactLink} to="/">Go Home</Button>
        </Stack>
      </Container>
    </ThemeProvider>
  );
};

export const Page404ScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar pb={0} waitForUserData={props.waitForUserData}>
      <Page404Screen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

Page404Screen.propTypes = {};

Page404Screen.defaultProps = {
  color: "primary",
};
