/** @jsxImportSource @emotion/react */
import {
  createContext,
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Stack, SvgIcon } from "@mui/material";

import { css, jsx, SerializedStyles } from "@emotion/react";
import invariant from "tiny-invariant";

import { easeInOut } from "@atlaskit/motion/curves";
import { smallDurationMs } from "@atlaskit/motion/durations";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
  monitorForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { token } from "@atlaskit/tokens";

// import battery from '@atlaskit/pragmatic-drag-and-drop/docs/examples/icons/battery.png';
// import drill from '@atlaskit/pragmatic-drag-and-drop/docs/examples/icons/drill.png';
// import koala from '@atlaskit/pragmatic-drag-and-drop/docs/examples/icons/koala.png';
// import ui from '@atlaskit/pragmatic-drag-and-drop/docs/examples/icons/ui.png';
// import wallet from '@atlaskit/pragmatic-drag-and-drop/docs/examples/icons/wallet.png';
// import yeti from '@atlaskit/pragmatic-drag-and-drop/docs/examples/icons/yeti.png';
// import { GlobalStyles } from '@atlaskit/pragmatic-drag-and-drop/docs/examples/util/global-styles';

function getInstanceId() {
  return Symbol("instance-id");
}

const InstanceIdContext = createContext(null);

const imgSize = 150;

const itemStyles = css({
  display: "block",
  // objectFit: "cover",
  width: imgSize * 1.0,
  height: imgSize * 1.0,
  boxSizing: "border-box",
  background: token("elevation.surface.raised", "#FFF"),
  padding: token("space.050", "0px"),
  borderRadius: token("border.radius.100", "4px"),
  boxShadow: token("elevation.shadow.raised", "none"),
  transition: `all ${smallDurationMs}ms ${easeInOut}`,
  "-webkit-touch-callout": "none", // needed to avoid a "save image" popup on iOS
  "@media (min-width: 600px)": {
    width: imgSize,
    height: imgSize,
  },
});

const itemStateStyles = {
  idle: css({
    ":hover": {
      background: token("elevation.surface.overlay", "#FFF"),
      boxShadow: token("elevation.shadow.overlay", "none"),
    },
  }),
  dragging: css({
    filter: "grayscale(0.8)",
  }),
  over: css({
    transform: "scale(1.1) rotate(8deg)",
    filter: "brightness(1.15)",
    boxShadow: token("elevation.shadow.overlay", "none"),
  }),
};

const Item = memo(function Item({ src, children }) {
  const ref = useRef(null);
  const [state, setState] = useState("idle");

  const instanceId = useContext(InstanceIdContext);

  useEffect(() => {
    const el = ref.current;
    invariant(el);

    return combine(
      draggable({
        element: el,
        getInitialData: () => ({ type: "grid-item", src: src, instanceId }),
        onDragStart: () => setState("dragging"),
        onDrop: () => setState("idle"),
      }),
      dropTargetForElements({
        element: el,
        getData: () => ({ src: src }),
        getIsSticky: () => true,
        canDrop: ({ source }) =>
          source.data.instanceId === instanceId &&
          source.data.type === "grid-item" &&
          source.data.src !== src,
        onDragEnter: () => setState("over"),
        onDragLeave: () => setState("idle"),
        onDrop: () => setState("idle"),
      })
    );
  }, [instanceId, src]);

  return (
    <div css={[itemStyles, itemStateStyles[state]]} ref={ref} src={src}>
      {children}
    </div>
  );
});

const gridStyles = css({
  display: "grid",
  gridTemplateColumns: `repeat(2, ${150 * 1.0}px)`,
  gap: "30px",
  // justifyContent: "space-between",
  "@media (min-width: 600px)": {
    gap: "20px",
    gridTemplateColumns: "repeat(3, 150px)",
  },
});

export default function PhotoGrid(props) {
  const [instanceId] = useState(getInstanceId);
  const idMap = useRef({});

  // useEffect

  useEffect(() => {
    return monitorForElements({
      canMonitor({ source }) {
        return source.data.instanceId === instanceId;
      },
      onDrop({ source, location }) {
        const destination = location.current.dropTargets[0];
        if (!destination) {
          return;
        }
        const destinationSrc = destination.data.src;
        const startSrc = source.data.src;

        if (typeof destinationSrc !== "string") {
          return;
        }

        if (typeof startSrc !== "string") {
          return;
        }

        // Swapping item positions

        // Reorder index of imagesData items
        const flatArray = props.imagesData?.map((obj) => obj.url);
        const updated = [...flatArray];
        updated[flatArray.indexOf(startSrc)] = destinationSrc;
        updated[flatArray.indexOf(destinationSrc)] = startSrc;

        // Reorder index of file objects
        const flatArrayFiles = props.files;
        const updatedFiles = flatArrayFiles ? [...flatArrayFiles] : [];
        updatedFiles[flatArrayFiles?.indexOf(startSrc)] = destinationSrc;
        updatedFiles[flatArrayFiles?.indexOf(destinationSrc)] = startSrc;

        props.callback(
          updated?.map((url, index) => ({
            url,
            id: idMap.current[url], // maps to id
            index,
          })),
          updatedFiles
        );
      },
    });
  }, [instanceId, props.imagesData]);

  return (
    <InstanceIdContext.Provider value={instanceId}>
      <div css={gridStyles}>
        {props.imagesData?.length > 0 &&
          props.imagesData?.map((obj, index) => {
            const { url, id } = obj;
            idMap.current[url] = id;

            return (
              <Item src={url} key={url} id={id}>
                <Stack
                  key={`stack-${index}`}
                  sx={{
                    position: "relative",
                    background: "rgba(0,0,0,0.9)",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                    borderRadius: 2,
                    height: { xs: imgSize * 1.0, sm: imgSize },
                    width: { xs: imgSize * 1.0, sm: imgSize },
                    marginBottom: 10,
                  }}
                >
                  <Box
                    key={`image-${index}`}
                    sx={{
                      display: "block",
                      overflow: "hidden",
                      height: { xs: imgSize * 1.0, sm: imgSize },
                      width: { xs: imgSize * 1.0, sm: imgSize },
                      background: `url(${url}) center`,
                      backgroundSize: "cover",
                    }}
                    src={url}
                  />
                  <IconButton
                    onClick={props.onRemoveImage}
                    index={index}
                    className={"cancelSelectorName"}
                    variant="contained"
                    size="small"
                    sx={{
                      background: "rgba(238, 63, 63, 0.5)",
                      "&:hover": {
                        background: "rgba(238, 63, 63, 1)",
                      },
                      color: "white",
                      position: "absolute",
                      top: 4,
                      right: 4,
                      zIndex: 10,
                    }}
                  >
                    <SvgIcon>
                      <CloseIcon />
                    </SvgIcon>
                  </IconButton>
                </Stack>
              </Item>
            );
          })}
        {props.children}
      </div>
    </InstanceIdContext.Provider>
  );
}
