import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "../basic/Button";

export const JoinButton = (props) => {
  const { state } = props;
  // console.log("state", state);

  if (state.privacy === "PUBLIC" || state.privacy === "PRIVATE") {
    return (
      <Button
        {...props}
        disabled={
          state.activityAttendance?.items?.length -
            state.numberOfParticipants ===
            0 || props.disabled
        }
      >
        {props.children || (
          props.fullWidth ? <>
            Join now -{" "}
            {Math.abs(
              state.activityAttendance?.items?.length -
                state.numberOfParticipants
            )}{" "}
            spots left
          </> : 
          <>Join now</>
        )}
      </Button>
    );
  } else if (state.privacy === "JOIN_BY_REQUEST") {
    const buttonText =
      state.attendanceObj?.status === "INIT" ? (
        "Request sent"
      ) : props.fullWidth ? (
        <>
          Request to join -{" "} 
          {Math.abs(
            state.activityAttendance?.items?.length - state.numberOfParticipants
          )}{" "}
          spots left
        </>
      ) : (
        <>Request to join</>
      );

    return (
      <Button
        {...props}
        disabled={
          state.activityAttendance?.items?.length -
            state.numberOfParticipants ===
            0 ||
          state.attendanceObj?.status === "INIT" ||
          props.disabled
        }
      >
        {props.children || <>{buttonText}</>}
      </Button>
    );
  }
};

export default JoinButton;