import * as React from "react";
import { styled, useTheme } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import MuiSelect from "@mui/material/Select";

const baseTheme = createTheme();

const theme = createTheme({
  palette: {
    primary: {
      main: "#2D9CDB",
      light: "#B9DEF3",
    },
    // secondary: {
    //     main: '#0C2F43'
    // }
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {},
      },
    },
  },
});

const StyledMuiSelect = styled(MuiSelect)(
  ({ theme }) => `
  &.MuiInputBase-root {
      border-radius: 16px;

      &:hover,
      &.Mui-focused {
          border: 0;
      }

      &.Mui-focused .MuiOutlinedInput-notchedOutline {
          border: solid 2px #B9DEF3;
      }  

      &.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline {
          border-color: ${baseTheme.palette.error.main}
      }

      &.Mui-error {
          & .MuiOutlinedInput-notchedOutline,
          &:hover .MuiOutlinedInput-notchedOutline {
              border-color: ${baseTheme.palette.error.main}
          }
      }

      & .MuiOutlinedInput-notchedOutline{
          border: solid 2px #B9DEF3;
      }
  
      &:hover .MuiOutlinedInput-notchedOutline{
          border: solid 2px #2d9cdb;
      }

  };      
`
);

export const Select = (props) => {
  const [state, setState] = React.useState(props.value);

  const handleChange = (event) => {
    setState(event.target.value);
    props.callback && props.callback(event.target.value);
  };

  return (
    <ThemeProvider theme={theme}>
      <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small-label">{props.label}</InputLabel>
        <StyledMuiSelect
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={state}
          label={props.label}
          onChange={handleChange}
        >
          {props.options.map(
            (v,i)=><MenuItem value={v}>
              {v}
            </MenuItem>
          )}
        </StyledMuiSelect>
      </FormControl>
    </ThemeProvider>
  );
};

Select.propTypes = {};

Select.defaultProps = {
  color: "primary",
  options: []
};

export default Select;
