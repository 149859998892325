import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MuiListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CheckIcon from "@mui/icons-material/Check";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

export const ListItemButton = styled(MuiListItemButton)(
  ({ theme }) => `
  &.Mui-selected,
  &.Mui-selected:hover{
    background: #d6eefb
  }
  &:hover {
    background: transparent;
  }
`
);

const ListItemButtonStateful = (props) => {

  return (
    <ListItemButton
      onClick={props.onClick}
      selected={props.selected}
      value={props.text}
      id={props.id}
    >
      {props.selected && (
        <ListItemIcon>
          <CheckIcon />
        </ListItemIcon>
      )}
      <ListItemText primary={props.text} sx={{paddingLeft: 1}}/>
    </ListItemButton>
  );
};

/**
 * Primary UI component for user interaction
 */
export const SubcategoryList = ({ ...props }) => {

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: "100%",
          minWidth: 400,
          bgcolor: "background.paper",
        }}
      >
        <nav aria-label="subcategories">
          <List sx={{paddingTop: 0}}>
            {
              !props.filtered &&
              props.interests?.map((obj, i) => (
                <ListItem disablePadding>
                  <ListItemButtonStateful
                    selected={props?.subcategories?.includes(obj.id)}
                    onClick={props.onSelectItem}
                    text={obj.title}
                    id={obj.id}
                  />
                </ListItem>
              ))}
              {
              props.filtered &&
              props?.filteredData.map((v, i) => (
                <ListItem disablePadding>
                  <ListItemButtonStateful
                    selected={props?.subcategories?.includes(v.item.id)}
                    onClick={props.onSelectItem}
                    text={v.item.title}
                    id={v.item.id}
                  />
                </ListItem>
              ))}
          </List>
        </nav>
      </Box>
    </ThemeProvider>
  );
};

SubcategoryList.propTypes = {};

SubcategoryList.defaultProps = {
  color: "primary",
};
