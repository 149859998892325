import React, { useContext, useEffect, useRef, useState } from "react";
import { Link as ReactLink, useBlocker, useNavigate } from "react-router-dom";
import {
  Box,
  Button as MUIButton,
  CircularProgress,
  Container,
  LinearProgress,
  Stack,
  Switch as MUISwitch,
  Typography,
} from "@mui/material";
import AppContext from "../../AppContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Button } from "../basic/Button";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import LinearProgressWithLabel from "../complex/LinearProgressWithLabel";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import CheckIcon from "@mui/icons-material/Check";
import { styled } from "@mui/system";
import uploadImages from "./sharedFunctions/uploadImages";
import { generateClient } from "aws-amplify/api";
import createActivity from "./queries/createActivity.js";
import activityQueryString from "./activityQueryString.js";
const client = generateClient();

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

const StyledBox = styled(Box)(
  ({ theme }) => `
  &.MuiBox-root {
    border-radius: 16px;
    border: solid 2px #B9DEF3;
    padding: ${theme.spacing(4)};
    text-transform: none;
    text-align: left;
  }
  &.selected {
    opacity: 1;
    background: rgba(25, 118, 210, 0.04)
  }
  &.deselected {
    opacity: 0.5;
  }
`
);

const SettingBox = (props) => {
  const onClick = (event) => {
    // event.stopPropagation();
    // event.preventDefault();
    // setSelected(true);
    props.callback(event.currentTarget.getAttribute("id"));
  };

  return (
    <StyledBox
      disabled={props.disabled}
      disableRipple
      id={props.id?.toLowerCase()}
      onClick={onClick}
      className={
        ((props.selected?.toLowerCase() === props.id?.toLowerCase() ||
          props.selected?.toLowerCase() === props.id2?.toLowerCase()) &&
          "selected") ||
        "deselected"
      }
      display={"flex"}
      flexDirection={"column"}
      alignItems={"start"}
      component={MUIButton}
    >
      <Typography
        textAlign={"left"}
        fontWeight={700}
        variant="body1"
        color={"text.primary"}
      >
        {props.title}
      </Typography>
      <Typography variant="body2" color={"text.primary"}>
        {props.body}
      </Typography>
      {(props.selected?.toLowerCase() === props.id?.toLowerCase() ||
        props.selected?.toLowerCase() === props.id2?.toLowerCase()) &&
        props.children}
    </StyledBox>
  );
};

/**
 * Primary UI component for user interaction
 */
export const ActivityPrivacy = ({ ...props }) => {
  const navigate = useNavigate();
  const [globalState, setGlobalState, q, p] = useContext(AppContext);

  const [state, setState] = useState({
    blockState: true,
    privacy: p?.submission?.privacy?.toLowerCase() || "public",
    loading: false,
  });

  const [uploadProgress, setUploadProgress] = useState(null);

  const localP = useRef({
    selected: p?.submission?.privacy?.toLowerCase() || "public",
  });

  useBlocker(() => {
    if (p.disableBlock === true) {
      return false;
    } else {
      const confirmResponse = window.confirm(
        "Activity creation is not complete. Changes might not be saved!"
      );

      if (confirmResponse) {
        p.submission = {};
      }

      return !confirmResponse;
    }
  }, state.blockState);

  useEffect(() => {
    if (!p.journey) navigate("/");
    p.prevPage = p.currentPage || "activity-privacy";
    p.currentPage = "activity-privacy";
    console.log(p?.submission);

    if (p.journey === "edit-activity") {
      p.disableBlock = true;
    } else {
      p.disableBlock = false;
    }
  }, []);

  useEffect(() => {
    if (state.blockState === false && p.createdActivityID) {
      navigate(`/create-activity/share/${p.createdActivityID}`);
      // delete p.createdActivityID;
      // delete p.journey;
    }
  }, [state.blockState]);

  const onCreateNewActivity = async () => {
    p.submission = {
      ...p.submission,
      privacy: localP.current.selected.toUpperCase(),
    };

    if (p.journey === "edit-activity") {
      navigate(-1);
    } else if (p.journey === "create-activity") {
      // 1. Create the activity
      const queryStringComplete = activityQueryString(p, "create");
      // console.log(queryStringComplete);

      setState({
        ...state,
        loading: true,
      });
      q.updateState(globalState);
      const res = await q.doQuery(
        "createNewActivity",
        "Submission completed.",
        "An error occured",
        {
          submission: p.submission, // Cannot restart here because images need to be uploaded next
        },
        p,
        queryStringComplete
      );

      p.formErrors = null;

      // 2. Get the activity ID
      const activityID = res?.data?.createActivity?.id;
      p.createdActivityID = activityID;

      // 3. Upload attached images (if any)
      if (p.submission?.files) {
        await uploadImages(
          "ACTIVITY",
          p.submission?.files,
          activityID,
          "activity",
          //Callback for when images are done uploading
          () => {
            p.disableBlock = true;
            p.submission = {};
            setState({ ...state, blockState: false });
          },
          (uploadProgress) => {
            // callback 2
            setUploadProgress(uploadProgress);
          }
        );
      } else {
        p.disableBlock = true;
        p.submission = {};
        setState({ ...state, blockState: false });
      }
    }
  };

  const clickHandler = (id) => {
    localP.current.selected = id;
    setState({
      ...state,
      privacy: localP.current.selected,
    });

    if (p.journey === "create-activity") {
      p.submission = {
        ...p.submission,
        privacy: localP.current.selected.toUpperCase(),
      };
    }
  };

  const toggleJoinByRequest = (event) => {
    event.stopPropagation();

    if (localP.current.selected?.toLowerCase() === "public") {
      localP.current.selected = "join_by_request";
    } else {
      localP.current.selected = "public";
    }

    setState({
      ...state,
      privacy: localP.current.selected,
    });

    if (p.activityMode === "create-activity") {
      p.submission = {
        ...p.submission,
        privacy: localP.current.selected.toUpperCase(),
      };
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"sm"}>
        <Box mb={0}>
          <ScreenNavHeader title="Privacy" />
        </Box>

        <Container maxWidth={"xs"}>
          <Stack direction={"column"} spacing={6}>
            <Box
              my={3}
              display="flex"
              flexDirection={"column"}
              alignItems="center"
            >
              <Typography variant="h5" gutterBottom>
                We care about privacy.
              </Typography>
              <Typography variant="body2">
                Please select one of the options below.
              </Typography>
            </Box>

            <Stack alignItems={"center"} spacing={2}>
              <SettingBox
                disabled={state.loading}
                callback={clickHandler}
                selected={state.privacy?.toUpperCase()}
                id="public"
                id2="join_by_request"
                title="Public activity"
                body="Post the Activity publicly so anyone on Sociogram can find it."
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <Stack p={2} direction={"column"}>
                    <Typography
                      variant="body1"
                      fontWeight={700}
                      color={"text.primary"}
                    >
                      Join by request
                    </Typography>
                    <Typography variant="body2" color={"text.primary"}>
                      People who want to join must be approved by you first.
                    </Typography>
                  </Stack>
                  <MUISwitch
                    checked={
                      state?.privacy?.toLowerCase() === "join_by_request"
                    }
                    onClick={toggleJoinByRequest}
                  />
                </Stack>
              </SettingBox>

              <SettingBox
                disabled={state.loading}
                callback={clickHandler}
                selected={state.privacy?.toUpperCase()}
                id="private"
                title="Private activity"
                body="Invite people from your contacts list or clans, this Activity will
            not show up in search results."
              />
            </Stack>

            <Box mb={4}>
              <Button
                onClick={onCreateNewActivity}
                disabled={!state?.privacy?.toUpperCase() || state.loading}
                fullWidth
                startIcon={CheckIcon}
              >
                {state.loading && (
                  <CircularProgress
                    size={18}
                    sx={{
                      mr: 1,
                      ml: -0.5,
                      color: "rgba(0,0,0,0.25)",
                    }}
                  />
                )}
                Confirm
              </Button>
            </Box>

            {uploadProgress && (
              <Stack>
                <LinearProgressWithLabel value={uploadProgress} />
              </Stack>
            )}
          </Stack>
        </Container>
      </Container>
    </ThemeProvider>
  );
};

export const ActivityPrivacyLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
      <ActivityPrivacy {...props} />
    </ResponsiveLayoutSidebar>
  );
};

ActivityPrivacy.propTypes = {};

ActivityPrivacy.defaultProps = {
  color: "primary",
};
