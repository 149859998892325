const updateActivityAttendanceStatus = (id, status) => `mutation MyMutation {
  updateActivityAttendanceStatus(input: {id: "${id}", status: ${status}}) {
    id
    status
    user {
      id
      givenName
      familyName
      phoneNumber
    }
  }
}`;

export default updateActivityAttendanceStatus;