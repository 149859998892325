const createConnection = (phoneNumber) => `mutation createConnection {
  createContact(phoneNumber: "${phoneNumber}") {
    
    contactID
    createdAt
    requesterID
    id
    sortedUserIDSPK
    status
    twinID
    updatedAt
    userIDcontactIDPK
    userID
    contact {
      email
      familyName
      id
      givenName
      phoneNumber
    }
  }
}`;

export default createConnection;