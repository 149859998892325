const listLanguages = () => `query MyQuery {
  listLanguages(limit: 150, sort: {direction: desc, field: name}) {
    items {
      id
      name
      nativeName
    }
    nextToken
  }
}`;

export default listLanguages;