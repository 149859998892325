import React, { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../AppContext.js";
import Badge from "@mui/material/Badge";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";

import { generateClient, post } from "aws-amplify/api";

function NotificationsIconSubbed(props) {
  const [
    globalState,
    setGlobalState,
    q,
    p,
    connectionStatus,
    isBackgroundLoaded,
    notificationsGlobalState,
  ] = useContext(AppContext);

  return (
    <Badge
      badgeContent={
        notificationsGlobalState?.newNotifications?.length < 0
          ? 0
          : notificationsGlobalState?.newNotifications?.filter(
              (obj) => obj?.status !== "SEEN"
            )?.length
      }
      color="error"
    >
      <NotificationsOutlinedIcon />
    </Badge>
  );
}

export default NotificationsIconSubbed;
