const contactsByUserID = (userID, other="") => `query MyQuery {
  contactsByUserID(userID: "${userID}") {
    items {
      id
      status
      sortedUserIDSPK
      contact {
        familyName
        givenName
        id
        phoneNumber
        images(limit: 1) {
          items {
            id
            index
            url
          }
        }
        ${other}
      } 
    }
  }
}`;

export default contactsByUserID;