/* src/Router.js */
import React, { useState, useEffect, useRef } from "react";
import { RouterProvider } from "react-router-dom";
import AppContext from "./AppContext.js";
import ImageContext from "./ImageContext.js";
import Query from "./stories/screens/createFunctions.js";
import { generateClient } from "aws-amplify/api";
import dataRouter from "./dataRouter.js";
import onSendNotification from "./stories/screens/queries/onSendNotification.js";
import onCreatePost from "./stories/screens/queries/onCreatePost.js";
import getUserNotificationsByReceiverUser from "./stories/screens/queries/getUserNotificationsByReceiverUser.js";
import { ThemeProvider } from "./ThemeContext";

import MQTTContext from "./MQTTContext.js";
const client = generateClient();

// Clean and parse the 'meta' field
function cleanAndParseMeta(metaString) {
  if (!metaString) return;
  if (typeof metaString === "object") return metaString;
  // Remove the outermost quotes and unescape the content
  const cleanedMetaString = metaString
    .replace(/^"|"$/g, "") // Remove the leading and trailing double quotes
    .replace(/\\{2}/g, "\\") // Convert double backslashes to single backslashes
    .replace(/\\"/g, '"'); // Replace escaped double quotes with normal quotes

  // Parse the cleaned meta string
  return JSON.parse(cleanedMetaString);
}

const Router = () => {
  const [connectionStatus, setConnectionStatus] = useState(-1);
  const [globalState, setGlobalState] = useState();
  const [notificationsGlobalState, setNotificationsGlobalState] = useState({
    newNotifications: [],
    newMessages: [],
  });
  const [previewImage, setPreviewImage] = useState();
  const [isBackgroundLoaded, setBackgroundLoaded] = useState(false);
  const mqttManager = useRef(null);

  // For createFunctions.js (GraphQL class)
  const q = useRef();

  // For tracking previous routes in components
  var p = useRef({
    prevPage: "home",
    currentPage: "home",
    lockHomeFeedScrollOffset: false,
  });

  const fetchNotifications = async () => {
    var apiKey = "da2-22ztnhuamje4jptwonwzfafdi4"; // Replace 'xxx' with your actual API key

    await fetch(
      "https://3arkzxpkarhoflbprgz4tpruai.appsync-api.us-east-1.amazonaws.com/graphql",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: JSON.stringify({
          query: getUserNotificationsByReceiverUser(
            p?.current?.userData?.userId
          ),
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        // For activity invitations, contact invitiations, etc.
        const typeCount = {};
        res.data.getUserNotificationsByReceiverUser.items.forEach(
          (objString, i) => {
            var obj = objString;
            obj.data.info = JSON.parse(obj.data.info);
            typeCount[obj.data.identity] = typeCount[obj.data.identity]
              ? // Increment if element counted before
                typeCount[obj.data.identity] + 1
              : // Or add 1 for first occurance
                0;

            res.data.getUserNotificationsByReceiverUser.items[i].data.info =
              typeof obj.data.info === "string"
                ? JSON.parse(obj.data.info)
                : obj.data.info;
          }
        );

        const notifications = res.data.getUserNotificationsByReceiverUser.items;

        // console.log('getUserNotificationsByReceiverUser', res.data.getUserNotificationsByReceiverUser.items)
        setNotificationsGlobalState({
          newNotifications: [...notifications],
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    p.fetchingFlagNotifications = 0;
  };

  useEffect(() => {
    if (!p.current?.userData?.userId) return;

    // Subscribe to new notifications
    client.graphql({ query: onSendNotification() }).subscribe({
      next: (payload) => {
        // Parse the outer JSON string
        const parsedObject = payload?.data?.onSendNotification;

        // Parse the 'meta' and 'info' properties
        parsedObject.meta = cleanAndParseMeta(parsedObject?.meta);
        parsedObject.meta.info = cleanAndParseMeta(parsedObject.meta?.info);

        const newParsedObject = {
          ...parsedObject,
          ...parsedObject?.meta?.info,
        };

        if (newParsedObject?.receiverUserID !== globalState?.userData?.userId)
          return;

        setNotificationsGlobalState((prevState) => {
          return {
            ...prevState,
            newNotifications: [
              ...(prevState.newNotifications || []), // Ensure array exists
              newParsedObject,
            ],
          };
        });
      },
      error: (error) => console.warn(error),
    });

    // Subscribe to new posts
    client.graphql({ query: onCreatePost() }).subscribe({
      next: (payload) => {
        setTimeout(() => {
          console.log("New post incoming...");
          setGlobalState(
            { ...globalState, showNewPostsButton: true },
            "graphql"
          );
        }, [10000]);
      },
      error: (error) => console.warn(error),
    });
  }, [globalState?.userData]);

  // Set initial state
  useEffect(() => {
    (async () => {
      p.current.routerChecking = true;
      // Set initial tab configurations
      p.current.configs = {
        "/profile": {
          defaultTab: 0,
        },
        "/search": {
          defaultTab: 0,
        },
        interests: {
          physicalInterests: [],
          onlineInterests: [],
        },
        categories: {
          physicalInterests: [],
          onlineInterests: [],
        },
        subcategories: {
          physicalSubcategories: [],
          onlineSubcategories: [],
        },
      };

      if (!q.current) {
        q.current = new Query();
        q.current.init(globalState, setGlobalState, p.current);
      }

      if (!p.current?.userData?.userId) {
        try {
          const newCognitoP = await q.current.getUserCognitoData(p.current);
          p.current.userData = newCognitoP.userData;
        } catch (error) {
          console.log("error", error);
        }
      }
      if (!p?.current?.initialized || globalState.onboarded === true) {
        // Set initial authflow
        p.current.authFlow = {
          countryObj: {
            name: "United Arab Emirates",
            code: "ae",
            dial_code: "+971",
          },
        };

        try {
          const newP = await q.current?.getUser(p.current);

          // Set initial user data
          p.current.userData = newP.userData;
          p.current.initialized = true;

          // For Authenticate, the appropriate key value should be set at this point
          if (p.current.userData?.dynamoDB?.givenName) {
            p.current = newP;
          }
          p.current.routerChecking = false;
        } catch (error) {
          console.log(error);
        }
        setGlobalState(
          {
            ...globalState,
            ...p.current,
            logoutUser: false,
          },
          "router"
        );

        try {
          //Load first notifications
          fetchNotifications();
        } catch (error) {
          console.log("error", error);
        }

        let count = 0;
        // Simulate loading the background image (replace with your actual logic)
        const loadImage = (path) => {
          const image = new Image();
          image.src = path;
          image.onload = () => {
            count++;
            if (count == 1) {
              setTimeout(() => setBackgroundLoaded(true), 500);
            }
          };
        };

        loadImage(
          `${process.env.REACT_APP_WEBSITE}/pexels_videos_2022396 (1080p).gif`
        );
      }

      p.current.routerChecking = false;
    })();
  }, [globalState?.onboarded]);

  // Instantiate Query class
  useEffect(() => {
    if (!q.current) {
      q.current = new Query();
      q.current.init(globalState, setGlobalState, p.current);
    }
    // q.current.doQuery(console.log, "DONE", "ERROR", globalState)
  }, [globalState]);

  /**
   * This fix is for Firefox (and potentially other browsers).
   * When the browser encounters "NetworkError" when communicating
   * with backend, refresh the browser.
   */
  useEffect(() => {
    window.addEventListener("online", () => {
      setConnectionStatus(1);
      // console.log("You're back online");
      setTimeout(() => {
        setConnectionStatus(-1);
      }, 3000);
    });
    window.addEventListener("offline", () => {
      // console.log("You're offline");
      setConnectionStatus(0);
    });
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <AppContext.Provider
      value={[
        globalState,
        (args, component) => {
          setGlobalState(args, component);
        },
        q.current,
        p.current,
        connectionStatus,
        isBackgroundLoaded,
        notificationsGlobalState,
        setNotificationsGlobalState,
      ]}
    >
      <ImageContext.Provider value={[previewImage, setPreviewImage]}>
        <MQTTContext.Provider value={mqttManager.current}>
          <ThemeProvider>
            <RouterProvider
              connectionStatus={connectionStatus}
              router={dataRouter}
            />
          </ThemeProvider>
        </MQTTContext.Provider>
      </ImageContext.Provider>
    </AppContext.Provider>
  );
};
export default Router;
