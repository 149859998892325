function compareTimestamps(T1, T2) {
  const date1 = new Date(T1);
  const date2 = new Date(T2);
  
  if(date1 >= date2) {
        var timeDifference = date1.getTime() - date2.getTime();
        var secondsDifference = timeDifference / 1000;
        var minutesDifference = secondsDifference / 60;
        var hoursDifference = minutesDifference / 60;
        var daysDifference = hoursDifference / 24;


        if (timeDifference === 0) {
            return "Now";
        }
        else if (daysDifference >= 1) {
            return Math.floor(daysDifference) === 1
            ? Math.floor(daysDifference) + " day ago"
            : Math.floor(daysDifference) + " days ago";
        } 
        
        else if (hoursDifference >= 1) {
            return Math.floor(hoursDifference)=== 1
            ? Math.floor(hoursDifference) + " hour ago"
            : Math.floor(hoursDifference) + " hours ago";
        } 
        else if (minutesDifference >= 1) {
            return Math.floor(minutesDifference) === 1
            ? Math.floor(minutesDifference) + " minute ago"
            : Math.floor(minutesDifference) + " minutes ago";
        } 
        else {
            return Math.floor(secondsDifference) === 1
            ? Math.floor(secondsDifference) + " second ago"
            : Math.floor(secondsDifference) + " seconds ago";
        }
    } else {

        var timeDifference = date2.getTime() - date1.getTime();
        var secondsDifference = timeDifference / 1000;
        var minutesDifference = secondsDifference / 60;
        var hoursDifference = minutesDifference / 60;
        var daysDifference = hoursDifference / 24;

        if (daysDifference >= 1) {
            return daysDifference === 1
            ? "in " + Math.floor(daysDifference) + " day"
            : "in " + Math.floor(daysDifference) + " days";
        } 
        
        else if (hoursDifference >= 1) {
            return hoursDifference === 1
            ? "in " + Math.floor(hoursDifference) + " hour"
            : "in " + Math.floor(hoursDifference) + " hours";
        } 
        else if (minutesDifference >= 1) {
            return minutesDifference === 1
            ? "in " + Math.floor(minutesDifference) + " minute"
            : "in " + Math.floor(minutesDifference) + " minutes";
        } 
        else {
            return secondsDifference === 1
            ? "in " + Math.floor(secondsDifference) + " second"
            : "in " + Math.floor(secondsDifference) + " seconds";
        }
    }
}
  
export default compareTimestamps;

// Example usage:
// const T1 = new Date("2024-02-01T12:30:00").getTime(); // Replace with your timestamp
// const T2 = new Date("2024-02-01T11:00:00").getTime(); // Replace with your timestamp

// console.log(compareTimestamps(T1, T2));
