const updateUserDevice = (
    userID,
    appLocalizationIdentifier="en_US", 
    appVersion="1.x.x",
    deviceID="web",
    os="Web",
    osLocalizationIdentifier="en_US",
    osVersion="NA",
    fcmToken="NA",
    type="WEB"
) => `mutation updateUserDevice {
  updateUserDevice(input: {
    appLocalizationIdentifier: "${appLocalizationIdentifier}", 
    appVersion: "${appVersion}", 
    deviceID: "${userID}-${deviceID}", 
    os: "${os}", 
    osLocalizationIdentifier: "${osLocalizationIdentifier}", 
    osVersion: "${osVersion}", 
    token: "${fcmToken}", 
    type: ${type}
  }) {
    id
    userID
    deviceID
    token
    latestActivationAt
    status
    type
    os
    osVersion
    appVersion
    updatedAt
    createdAt
  }
}`;


export default updateUserDevice;