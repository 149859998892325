import createPresignedURL2 from "../../screens/queries/createPresignedURL2";
import { uploadData } from "aws-amplify/storage";
import { generateClient } from "aws-amplify/api";
const client = generateClient();

const uploadImages = async (category, files, postID, path, callback, callback2) => {
  try {
    const expectedSum = Array.from(files)?.length;
    const progressMap = {};

    Array.from(files).forEach(async (file, i) => {
      if (!file.type) return;

      const slashIndex = file.type.lastIndexOf("/");
      const fileExtension = file.type.substring(slashIndex + 1).toUpperCase();

      progressMap[i] = 0;

      // 1. Create presignedURL
      const presignedData = await client.graphql({
        query: createPresignedURL2(
          `input: {
              category: ${category.toUpperCase()},
              type: ${fileExtension},
              imageInput: {
                subjectID: "${postID}",
                index: ${i}
              }
            }`
        ),
      });
      console.log("1. Create presignedURL", presignedData);

      // 2. Upload presigned image
      const uploadDataResult = await uploadData({
        key: `/features/${path.toLowerCase()}/photos/${
          presignedData.data.createPresignedURL.fileName
        }.${fileExtension.toLowerCase()}`,
        data: file,
        options: {
          isPublic: true,
          // accessLevel: 'guest',
          onProgress: ({ transferredBytes, totalBytes }) => {
            if (totalBytes) {
              // console.log(
              //   `Upload progress #${i} ${
              //     Math.round((transferredBytes / totalBytes)*10) * 10
              //   } %`
              // );

              // console.group(`Progress ${i}`)
              // console.log('Percent', Math.round((transferredBytes/totalBytes)*10)*10)
              progressMap[i] =
                Math.round((transferredBytes / totalBytes) * 10) * 10;
              // console.groupEnd();

              // Add up the percentages of every image upload progress
              var progressMapTotal = 0;
              Object.values(progressMap).forEach(
                (x) => (progressMapTotal += x)
              );

              console.log(progressMapTotal, expectedSum * 100);
              callback2((progressMapTotal/(expectedSum * 100))*100)

              if (progressMapTotal === expectedSum * 100) {
                // Acknowledge file(s) uploaded
                console.log("Uploading done. Callback...");
                callback();
              }
            }
          },
        },
      });
      console.log("2. Upload presigned URL", uploadDataResult);

      // 3. Update database
      // const updateDatabaseResult = await client.graphql({
      //   query: createImage(
      //     `"${presignedData.data.createPresignedURL.url}"`,
      //     `POST`,
      //     `"PUBLISHED"`,
      //     null,
      //     null,
      //     `"${postID}"`,
      //     null,
      //     i
      //   ),
      // });
      // console.log("3. Update database", updateDatabaseResult);

      // Callback for parent function
    });
  } catch (error) {
    console.error("Error uploading data:", error);
  }
};

export default uploadImages;
