import React from "react";
import { Link as ReactLink } from "react-router-dom";
import { Avatar, Typography, Stack } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

/**
 * Primary UI component for user interaction
 */
export const AvatarLink = ({ ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      {props.avatarText ? (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          mb={props.mb || 0}
        >
          <ReactLink to={props.to}>
            <Avatar {...props} src={props.src && props.src?.replace("//cdn.qa.sociogram.com/", "//d2mgxt9bsinkzr.cloudfront.net/filters:quality(25)/fit-in/128x128/")}/>
          </ReactLink>
          <ReactLink
          style={{textDecoration: "none"}}
          to={props.to}>
            <Typography
              sx={{textDecoration: "none"}}
              color="text.primary" 
              variant="body1" 
              fontWeight={500}
              textDecoration="none"
            >
            {props.avatarText}
            </Typography>
          </ReactLink>
        </Stack>
      ) : (
        <ReactLink to={props.to}>
          <Avatar {...props} src={props.src && props.src?.replace("//cdn.qa.sociogram.com/", "//d2mgxt9bsinkzr.cloudfront.net/filters:quality(25)/fit-in/128x128/")}/>
        </ReactLink>
      )}
    </ThemeProvider>
  );
};

AvatarLink.propTypes = {};

AvatarLink.defaultProps = {
  color: "primary",
};
