import React, { Component } from "react";
import {
  IconButton as MuiIconButton,
  Box,
  Button as MuiButton,
  Container,
  Divider,
  InputBase,
  Stack,
  Switch as MuiSwitch,
  SvgIcon,
  Typography,
  IconButton,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReorderIcon from "@mui/icons-material/Reorder";
import CancelIcon from "@mui/icons-material/Cancel";
// fake data generator
const getItems = (count) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k}`,
    content: `item ${k}`,
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgrey" : "transparent", // 'transparent',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: "transparent", //isDraggingOver ? "lightblue" : "lightgrey",
  // padding: grid,
  // width: 250
});

const ValidatedInputBase = (props) => {
  const [state, setState] = React.useState(null);

  const onValidateField = (evt) => {
    const value = evt.target.value;
  };

  return <InputBase onChange={this.onValidateField} {...props} />;
};

export class ConditionsList extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   items: props.items, //|| getItems(3)
    // };
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onValidateField = this.onValidateField.bind(this);
    this.timeoutId = null;
  }


  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      this.props.items,
      result.source.index,
      result.destination.index
    );

    this.props.setConditions(newItems);
  }

  onValidateField(event) {
    const index = event.currentTarget.getAttribute("id");
    const newItems = [...this.props?.items];

    newItems[index].content = event.currentTarget.value;
    this.props.setConditions(newItems)
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    return (
      <Box position="relative">
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {this.props?.items?.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <Stack
                        spacing={1}
                        alignItems={"center"}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        direction={"row"}
                        p={2}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <SvgIcon>
                          <ReorderIcon />
                        </SvgIcon>

                        {/* <Typography>{item.content}</Typography> */}

                        <InputBase
                          id={index}
                          sx={{ flexGrow: 1 }}
                          placeholder="Enter a condition..."
                          defaultValue={item.content}
                          onChange={this.onValidateField}
                          inputProps={{
                            maxLength: 200,
                          }}
                        />

                        <IconButton
                          index={index}
                          onClick={this.props.removeHandler}
                        >
                          <CancelIcon />
                        </IconButton>
                      </Stack>
                      // </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    );
  }
}

export default ConditionsList;
