import React from 'react';
import {TextField as MUITextField} from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import './textfield.css';


const baseTheme = createTheme();

const theme = createTheme({
    palette: {
        primary: {
            main: '#2D9CDB',
            light: '#B9DEF3'
        },
        // secondary: {
        //     main: '#0C2F43'
        // }
    },
    components: {
        MuiTextField: {
          styleOverrides: {
            root: {
            },
          },
        },
    },
});

const StyledMUITextField = styled(MUITextField)(
    ({ theme }) => `
    & .MuiInputBase-root {
        border-radius: 16px;

        &:hover,
        &.Mui-focused {
            border: 0;
        }

        &.Mui-focused .MuiOutlinedInput-notchedOutline {
            border: solid 2px #B9DEF3;
        }  

        &.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline {
            border-color: ${baseTheme.palette.error.main}
        }

        &.Mui-error {
            & .MuiOutlinedInput-notchedOutline,
            &:hover .MuiOutlinedInput-notchedOutline {
                border-color: ${baseTheme.palette.error.main}
            }
        }

        & .MuiOutlinedInput-notchedOutline{
            border: solid 2px #B9DEF3;
        }
    
        &:hover .MuiOutlinedInput-notchedOutline{
            border: solid 2px #2d9cdb;
        }

    };      
`);


/**
 * Primary UI component for user interaction
 */
export const TextField = ({ ...props }) => {


  //const mode = primary ? 'storybook-button--primary' : 'storybook-button--secondary';
  return (
    <ThemeProvider theme={theme}>
        <StyledMUITextField
            {...props}
            fullWidth={props.fullWidth}
            size={props.size}
            label={props.label}
            inputProps={{ maxLength: props.inputProps?.maxLength || 900 }}
        >
        </StyledMUITextField>
    </ThemeProvider>
  );
};

TextField.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
//   color: PropTypes.oneOf(['primary', 'secondary']),
  /**
   * What background color to use
   */
//   backgroundColor: PropTypes.string,
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['small', 'medium']),
  /**
   * Button contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onChange: PropTypes.func,
  /**
   * Start icon
   */
  startIcon: PropTypes.elementType,

  /**
   * End icon
   */
  endAdornment: PropTypes.elementType,

  /*
   * Error
   */
  error: PropTypes.bool
};

TextField.defaultProps = {
  color: 'primary',
  size: 'medium'
};
