import React, { useContext, useEffect, useState } from "react";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import { Box, Container, ButtonGroup, Typography } from "@mui/material";
import AppContext from "../../AppContext.js";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import { OnboardingContainer } from "../complex/OnboardingContainer";
import { OnboardingScreenResponsiveLayout } from "../complex/OnboardingScreenResponsiveLayout";
import { Button } from "../basic/Button";
import { ResponsiveToaster } from "../basic/ResponsiveToaster.jsx";
import { TextField } from "../basic/TextField";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { signUp, confirmSignIn, signIn } from "aws-amplify/auth";
import { generateClient, post } from "aws-amplify/api";
import getInviteStatus from "./queries/getInviteStatus.js";
import updateUserDevice from "./queries/updateUserDevice.js";
import CircularProgress from "@mui/material/CircularProgress";
import "/node_modules/flag-icons/css/flag-icons.min.css";

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

const ButtonGroupStyled = styled(ButtonGroup)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  boxShadow: "none",
  display: "flex",
  width: "100%",
  [theme.breakpoints.up("md")]: {},
  [theme.breakpoints.down("md")]: {
    // marginTop: '30.5%',
    alignItems: "center",
    flexGrow: 0, //0.5
  },
}));

const FlexGrowBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    flexGrow: 0, //0.5
  },
}));

const GroupButton = styled(Button)(
  ({ theme }) => `
  height: 56px;
`
);

const GroupTextField = styled(TextField)(
  ({ theme }) => `
    &:hover > div.MuiOutlinedInput-root > fieldset.MuiOutlinedInput-notchedOutline,
    & .Mui-focused > fieldset.MuiOutlinedInput-notchedOutline,
    & > div.MuiOutlinedInput-root > fieldset.MuiOutlinedInput-notchedOutline {
      top: 0px;
      height: 52px;
      border-left: none !important;
    }
    & fieldset > legend {
      display: none;
    }
    & > .MuiInputBase-root > fieldset.MuiOutlinedInput-notchedOutline {
      border-color:  #1B1B1E;
    }
    &:hover > .MuiInputBase-root > fieldset.MuiOutlinedInput-notchedOutline {
      border-color:  #1B1B1E;
    }
    & .Mui-focused > fieldset.MuiOutlinedInput-notchedOutline {
      border-color: #1B1B1E !important;
    }
    & > div {
      border-left: none;
      border-radius: 0px 45px 45px 0px !important; 
    }
`
);

export default function BasicButtonGroup(props) {
  return (
    <ButtonGroupStyled
      {...props.sx}
      {...props.flexGrow}
      variant="contained"
      aria-label="Basic button group"
    >
      <GroupButton
        color="secondary"
        component={ReactLink}
        to="/country-selection"
        sx={{
          border: "solid 2px #1B1B1E !important",
          paddingTop: 1,
          paddingBottom: 1,
          paddingLeft: 2,
          paddingRight: 1,
          width: 140,
        }}
      >
        <span
          style={{ marginRight: 8, fontSize: 20 }}
          className={`fi fi-${
            props?.authFlow?.countryObj?.code.toLowerCase() || "ae"
          }`}
        ></span>
        <span style={{ width: 90 }}>
          {props?.authFlow?.countryObj?.dial_code}
        </span>
      </GroupButton>

      <GroupTextField
        label=""
        type={useMediaQuery(theme.breakpoints.up("sm")) ? "text" : "number"}
        onChange={props.onChange}
        sx={{ width: "100%", height: 56 }}
      />
    </ButtonGroupStyled>
  );
}

// const client = generateClient();
/**
 * Primary UI component for user interaction
 */
export const PhoneNumberScreen = ({ ...props }) => {
  var [globalState, setGlobalState, q, p] = useContext(AppContext);
  const [state, setState] = useState({
    phoneNumber: "",
    loading: false,
    disabled: true,
    authFlow: p.authFlow
  });
  const [toasterMessage, setToasterMessage] = useState();
  const navigate = useNavigate();

  const onChange = (event) => {
    setState({
      ...state,
      disabled: !/^\d{6,}$/.test(event.currentTarget.value),
      phoneNumber: event.currentTarget.value,
      authFlow: p.authFlow
    });
  };

  useEffect(()=>{

    if(!p.authFlow?.countryObj) navigate("/signin")
    setState({
      ...state,
      authFlow: p.authFlow
    });
  }, [])

  const onClick = async () => {
    setState({ ...state, loading: true });
    try {
      // console.log('authenticating', state.phoneNumber)
      const username = `${p?.authFlow?.countryObj?.dial_code}${state.phoneNumber}`;
      const res = await signIn({
        username: username, //'+971502601697',
        password: `${username}!Sociogram`, //'+971502601697!Sociogram'
      });

      p.authFlow = {
        ...p.authFlow,
        phoneNumber: state.phoneNumber,
        fullPhoneNumber: username,
      };
      p.loggingIn = true;

      // q.updateState(globalState);
      // const newP = await q.getUserCognitoData(p);
      // p = {
      //   ...p,
      //   ...newP
      // }
      // setGlobalState({
      //   ...globalState,
      //   ...newP
      // });

      // console.log('res', res)
      navigate("/otp");
    } catch (error) {
      console.log(error.message);

      switch (error.message) {
        case "User does not exist.":
          setToasterMessage("No user with this phone number");
          break;
        case "username is required to signIn":
          setToasterMessage("Please enter a valid phone number");
          break;
        default:
          console.log("error", error.message);
          setToasterMessage("An error occured");
          break;
      }
    }
    setState({ ...state, loading: false });
  };

  return (
    <ThemeProvider theme={theme}>
      <OnboardingContainer
        step={0}
        title="Phone Number"
        description="Enter your phone number so we can verify your identity."
      >
        <BasicButtonGroup
          authFlow={state?.authFlow}
          onChange={onChange}
        />

        <FlexGrowBox />

        <Button
          disabled={state.loading || state.disabled || toasterMessage}
          onClick={onClick}
          startIcon={!state.loading && ChevronRightIcon}
        >
          {state.loading && (
            <CircularProgress
              size={18}
              sx={{
                mr: 1,
                ml: -0.5,
                color: "rgba(0,0,0,0.25)",
              }}
            />
          )}
          Continue
        </Button>

        <ResponsiveToaster
          severity={state.severity}
          open={toasterMessage ? true : false}
          toasterMessage={toasterMessage}
          setToasterMessage={() => setToasterMessage("")}
        />
      </OnboardingContainer>
    </ThemeProvider>
  );
};

export const PhoneNumberScreenLayout = (props) => {
  return (
    <OnboardingScreenResponsiveLayout>
      <PhoneNumberScreen {...props} />
    </OnboardingScreenResponsiveLayout>
  );
};

PhoneNumberScreen.propTypes = {};

PhoneNumberScreen.defaultProps = {
  color: "primary",
};
