import React, { forwardRef, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { Box, Stack, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimeField as MUITimeField } from "@mui/x-date-pickers/TimeField";
import { DatePicker as MUIDatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "../basic/TextField";
import { styled } from "@mui/system";
import moment from "moment";

const TimeField = styled(MUITimeField)(({ theme }) => ({
  padding: 0,
  "& div": {
    borderRadius: "16px",
  },
  "& .MuiInputBase-root": {
    paddingRight: 8,
  },
  "& > div > input": {
    padding: "12px 0px 12px 12px",
  },
  "& button": {
    padding: 4, // "8px 8px 8px 0px"
    marginRight: 0,
  },
}));

const StyledDatePicker = styled(MUIDatePicker)(({ theme }) => ({
  padding: 0,
  "& div": {
    borderRadius: "16px",
  },
  "& .MuiInputBase-root": {
    paddingRight: 8,
  },
  "& > div > input": {
    padding: "12px 0px 12px 12px",
  },
  "& button": {
    padding: 4, // "8px 8px 8px 0px"
    marginRight: 0,
  },
}));

function isAGreaterThanBBy10Minutes(A, B) {
  return moment(A).diff(moment(B), "minutes") >= 10;
}

export default forwardRef(function DatePicker(props, ref) {
  const [state, setState] = useState({
    startDate: props.startDate || moment(Date.now()).toISOString(true), //.split("T")[0],
    startTime: props.startDate || moment(Date.now()).toISOString(true), //.split("T")[1],
    endDate:
      props.endDate || moment(Date.now()).add(0.16, "hours").toISOString(true), //.split("T")[0],
    endTime:
      props.endDate || moment(Date.now()).add(0.16, "hours").toISOString(true), //.split("T")[1]
  });

  const date1 = useRef(null);
  const date2 = useRef(null);
  const time1 = useRef(null);
  const time2 = useRef(null);

  const validateDateTimes2 = (
    startDateArg = null,
    startTimeArg = null,
    endDateArg = null,
    endTimeArg = null
  ) => {
    try {
      const startDate = (
        moment(startDateArg)?.toISOString(true) || state.startDate
      ).split("T")[0];
      const startTime = (
        moment(startTimeArg)?.toISOString(true) || state.startTime
      ).split("T")[1];

      const endDate = (
        moment(endDateArg)?.toISOString(true) || state.endDate
      ).split("T")[0];
      const endTime = (
        moment(endTimeArg)?.toISOString(true) || state.endTime
      ).split("T")[1];

      const date1 = `${startDate}T${startTime}`;
      const date2 = `${endDate}T${endTime}`;

      var returnBoolean;
      //if (isISO8601(date1) && isISO8601(date2)) {
      if (isAGreaterThanBBy10Minutes(date2, date1)) {
        returnBoolean = true;
      } else {
        returnBoolean = false;
      }
      // } else {
      //   returnBoolean = false;
      // }

      props.onChangeCallback(returnBoolean);

      return returnBoolean;
    } catch (error) {
      return false;
    }
  };

  const setStartDate = (e) => {
    setState({
      ...state,
      startDate: moment(e?.$d)?.toISOString(true), //.split("T")[0],
      valid: validateDateTimes2(e?.$d, null, null, null),
    });
  };

  const setStartTime = (e) => {
    setState({
      ...state,
      startTime: moment(e?.$d)?.toISOString(true), //.split("T")[1],
      valid: validateDateTimes2(null, e?.$d, null, null),
    });
  };

  const setEndDate = (e) => {
    setState({
      ...state,
      endDate: moment(e?.$d)?.toISOString(true), //.split("T")[0],
      valid: validateDateTimes2(null, null, e?.$d, null),
    });
  };

  const setEndTime = (e) => {
    setState({
      ...state,
      endTime: moment(e?.$d)?.toISOString(true), //.split("T")[1],
      valid: validateDateTimes2(null, null, null, e?.$d),
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ maxWidth: 305, margin: "0 auto" }} px={3}>
        <Typography gutterBottom variant="body2">
          Start
        </Typography>
        <Stack direction={"row"} spacing={1} mb={2}>
          <StyledDatePicker
            inputRef={(thisElem) => (date1.current = thisElem)}
            onChange={setStartDate}
            onAccept={setStartDate}
            onError={setStartDate}
            minDate={dayjs(state.startDate)}
            defaultValue={dayjs(state.startDate)}
          />

          <TimeField
            inputRef={(thisElem) => (time1.current = thisElem)}
            onChange={setStartTime}
            onAccept={setStartTime}
            onError={setStartTime}
            minDate={dayjs(state.startDate)}
            defaultValue={dayjs(state.startDate)}
            ampmInClock={false}
          />
        </Stack>

        <Typography gutterBottom variant="body2">
          End
        </Typography>
        <Stack direction={"row"} spacing={1}>
          <StyledDatePicker
            id="endDate"
            inputRef={(thisElem) => (date2.current = thisElem)}
            onChange={setEndDate}
            onAccept={setEndDate}
            onError={setEndDate}
            minDate={dayjs(state.endDate)}
            defaultValue={dayjs(state.endDate)}
          />
          <TimeField
            id="endTime"
            inputRef={(thisElem) => (time2.current = thisElem)}
            onChange={setEndTime}
            onAccept={setEndTime}
            onError={setEndTime}
            minTime={dayjs(state.endTime)}
            defaultValue={dayjs(state.endTime)}
            ampmInClock={false}
          />
        </Stack>
        <input
          type="hidden"
          value={[
            `${state.startDate?.split("T")?.[0]}T${
              state.startTime?.split("T")?.[1]
            }`,
            `${state.endDate?.split("T")?.[0]}T${
              state.endTime?.split("T")?.[1]
            }`,
            state.valid,
          ]}
          ref={ref}
        />
      </Box>
    </LocalizationProvider>
  );
});
