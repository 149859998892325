export const escapeSpecialChars = (str) => {
  return str.replace(/"/g, '\\"');
}

export const keywordQueryUsers = (keyword) => {
  var kibanaAr = [];

  keyword.split(" ").forEach((kw) => {
    kibanaAr.push({ "fuzzy": { "givenName": { "value": `${kw}`, "fuzziness": 10 } } })
    kibanaAr.push({ "fuzzy": { "familyName": { "value": `${kw}`, "fuzziness": 10 } } })
    kibanaAr.push({ "fuzzy": { "address": { "value": `${kw}`, "fuzziness": 10 } } })
    kibanaAr.push({ "fuzzy": { "phoneNumber": { "value": `${kw}`, "fuzziness": 10 } } })
  });

  var stingified = JSON.stringify(kibanaAr)
  return escapeSpecialChars(stingified)
};

export default keywordQueryUsers;