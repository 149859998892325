import React from 'react';
import { 
  TextField as MuiTextField,
} from '@mui/material';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';


const theme = createTheme({});

export const SearchTextField = styled(MuiTextField)(({ theme }) =>`
  .MuiInputBase-root {
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 12px 16px 12px 8px;
    border-radius: 24px;
    border: solid 0px #B9DEF3;
    background-color: #fff;

  }
  
  & .MuiOutlinedInput-notchedOutline,
  &:focus-within .MuiOutlinedInput-notchedOutline {
    border: solid 1px #B9DEF3;
    border-width: 1px;
  }

  &:hover .MuiOutlinedInput-notchedOutline{
    border-color: #2d9cdb;
    border-width: 1px;
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: solid 1px #2d9cdb;
  }

`);