import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import timestampdifference from "../utils/timestampdifference";
import { ActivityPostCard } from "../complex/ActivityPostCard";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2D9CDB",
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#E0C2FF",
      light: "#F5EBFF",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#47008F",
    },
  },
  typography: {
    fontFamily: [
      '"Lato"',
      "Roboto",
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

const ResponsiveBoxContainer = styled(Box)(({ theme }) => ({
  background: "solid 1px rgba(0,0,0,0.15)",
  margin: "0 auto",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    background: "#f6f7f9",
  },
}));

const ResponsiveStack = styled(Stack)(({ theme }) => ({
  //maxWidth: 530,
  // marginLeft: 'auto',
  // marginRight: 'auto',
  // borderBottom: 'solid 1px rgba(0,0,0,0.15)',
  background: "white",
  // marginBottom: 0,
  [theme.breakpoints.down("md")]: {
    paddingLeft: 16,
    paddingRight: 16,
    borderBottom: 0,
    // marginBottom: 0,
  },
  "&:hover": {
    cursor: "pointer",
    // background: 'rgba(0,0,0,0.025)'
  },
}));

const ActivityPostCardContainer = (props) => {
  // const [state, setState] = useState({loading: true});

  // useEffect(
  //   () => {

  //     setState({...state, loading: false})

  //   }, [props.data]
  // )

  return (
    <ResponsiveBoxContainer>
      <div>
        {(props.loading) &&
          [1].map((v, i) => (
            <ResponsiveStack
              sx={{ "&:hover": { background: "none" } }}
              key={`skeleton-activity-${i}`}
              direction={"column"}
              spacing={1}
              mb={0}
              py={2}
              px={4}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={1}
                sx={{ width: "100%" }}
              >
                <Skeleton
                  animation={"wave"}
                  variant="circular"
                  width={48}
                  height={48}
                />
                <Stack
                  direction={"row"}
                  spacing={1}
                  flexGrow={1}
                  justifyContent={"space-between"}
                >
                  <Stack direction={"column"} spacing={1}>
                    <Skeleton variant="rounded" width={160} height={10} />
                    <Skeleton
                      variant="rounded"
                      width={100}
                      height={10}
                      sx={{ flexGrow: 1 }}
                    />
                  </Stack>
                  {/* <Skeleton variant="rounded" width={20} height={6} /> */}
                </Stack>
              </Stack>
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={"100%"}
                height={170}
              />
            </ResponsiveStack>
          ))}


        {(!props.loading && props?.data?.length === 0) && (
          <Typography>No results found in Activities.</Typography>
        )}

        {!props.loading &&
          props?.data &&
          props?.data?.map((itemObj, i) => {
            const {
              id,
              createdByID,
              status,
              text,
              createdAt,
              interest,
              images,
              link,
              userObject,
              activityObject,
              postObject,
              commentsCount,
              reactions,
              reactionsCount,
              numberOfVotes,
              sumOfVotes,
              privacy,
              postTagUsers,
            } = itemObj;

            const startTimestamp = activityObject
              ? new Date(activityObject.activityStart)
              : null;
            const endTimestamp = activityObject
              ? new Date(activityObject.activityEnd)
              : null;
            var start = { sDay: 0, sMonth: 0, sHour: 0, sMinute: 0 };
            var end = { eDay: 0, eMonth: 0, eHour: 0, eMinute: 0 };

            if (startTimestamp) {
              start = {
                sDay: startTimestamp.getDay(),
                sMonth: startTimestamp.getMonth(),
                sHour: String(startTimestamp.getHours()).padStart(2, "0"),
                sMinute: String(startTimestamp.getMinutes()).padStart(2, "0"),
              };
            }
            if (endTimestamp) {
              end = {
                eDay: endTimestamp.getDay(),
                eMonth: endTimestamp.getMonth(),
                eHour: String(endTimestamp.getHours()).padStart(2, "0"),
                eMinute: String(endTimestamp.getMinutes()).padStart(2, "0"),
              };
            }

            const { sDay, sMonth, sHour, sMinute } = start;
            const { eDay, eMonth, eHour, eMinute } = end;

            // if (postObject) console.log("postObject", postObject);

            return (
              <>
                <ResponsiveStack key={`feedcard-${i}`} pt={2} px={4} sx={{mb: 1, border: "solid 1px rgba(0,0,0,0.15)", borderRadius: 1}}>
                  <ActivityPostCard
                    id={id}
                    userId={userObject?.id}
                    userAvatar={
                      userObject.images.items.length > 0 &&
                      userObject.images.items[0]?.url
                    }
                    userName={`${userObject.givenName} ${userObject.familyName}`}
                    image={images?.items?.[0]?.url}
                    imagesData={images?.items}
                    text={text}
                    commentsCount={commentsCount}
                    reactionsCount={reactionsCount}
                    likeReactionID={reactions?.items?.[0]?.id}
                    liked={
                      reactions?.items?.[0]?.type === "HEART" ? true : false
                    }
                    shareType={
                      (activityObject && "activity") ||
                      (postObject && "post") ||
                      (link && "link") ||
                      null
                    }
                    createdAt={createdAt}
                    postTagUsers={postTagUsers}
                    rating={
                      Math.round(
                        (userObject.sumOfVotes / userObject.numberOfVotes) * 100
                      ) / 100
                    }
                    activityObject={
                      activityObject && {
                        category: activityObject.interest.parentInterest.title,
                        userName: `${activityObject.userObject.givenName} ${activityObject.userObject.familyName}`,
                        userId: activityObject?.userObject?.id,
                        text: activityObject.description,
                        currency: activityObject.currency || "",
                        price: activityObject.price
                          ? parseFloat(activityObject.price).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "FREE",
                        day: sDay,
                        month: sMonth,
                        privacy: activityObject.privacy,
                        startTime: `${sHour}:${sMinute}`,
                        endTime: `${eHour}:${eMinute}`,
                        participants: activityObject.numberOfParticipants,
                        rsvped: activityObject.activityAttendance.items.length,
                        address: activityObject.address,
                        userAvatar:
                          activityObject?.userObject?.images?.items?.[0]?.url,
                        attendees:
                          activityObject.activityAttendance.items.slice(0, 5),
                        remainingDays: timestampdifference(
                          new Date(),
                          endTimestamp
                        ),
                      }
                    }
                    postObject={
                      postObject && {
                        imageSrc:
                          postObject.images?.items?.[0]?.url ||
                          postObject?.userObject?.images?.items?.[0]?.url,
                        username: `${postObject?.userObject?.givenName} ${postObject?.userObject?.familyName}`,
                        text:
                          `"${postObject.text}"` ||
                          postObject?.activityObject?.description,
                        type: postObject.activityObject && "Activity",
                        name: postObject?.activityObject?.name,
                      }
                    }
                    link={
                      link && {
                        imageSrc: link?.imageUrl,
                        name: link?.title,
                        text: " ",
                        type: "link",
                        url: link.url,
                      }
                    }
                  />
                </ResponsiveStack>
              </>
            );
          })}
      </div>
    </ResponsiveBoxContainer>
  );
};

export default ActivityPostCardContainer;
