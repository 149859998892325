import React, { useEffect, useState, useContext } from "react";
import AppContext from "../../AppContext";
import { Link as ReactLink, useParams, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Container,
  Divider,
  Stack,
  InputBase,
  Menu,
  MenuItem,
  Skeleton,
  SvgIcon,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { AvatarLink } from "../basic/AvatarLink";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import { ActivityPostCard } from "../complex/ActivityPostCard";
import { generateClient, post } from "aws-amplify/api";
import EllipsisHorizontalIcon from "@heroicons/react/24/outline/EllipsisHorizontalIcon";
import getCommentsByPost from "./queries/getCommentsByPost.js";
import createComment from "./queries/createComment.js";
import SpeakerNotesOffOutlinedIcon from "@mui/icons-material/SpeakerNotesOffOutlined";
import timestampdifference from "../utils/timestampdifference";
import { cleanText } from "../../ui-components/utils.js";
import SendIcon from "@mui/icons-material/Send";

const client = generateClient();

const theme = createTheme({
  palette: {},
  components: {
    // MuiInputBase multiline: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

const ResponsiveBoxContainer = styled(Box)(({ theme }) => ({
  background: "solid 1px rgba(0,0,0,0.15)",
  margin: "0 auto",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    background: "#f6f7f9",
  },
}));

const ResponsiveStack = styled(Stack)(({ theme }) => ({
  //maxWidth: 530,
  // marginLeft: 'auto',
  // marginRight: 'auto',
  // borderBottom: 'solid 1px rgba(0,0,0,0.15)',
  background: "white",
  [theme.breakpoints.down("md")]: {
    paddingLeft: 16,
    paddingRight: 16,
    borderBottom: 0,
    marginBottom: 8,
  },
  "&:hover": {
    cursor: "pointer",
    // background: 'rgba(0,0,0,0.025)'
  },
}));

/**
 * Primary UI component for user interaction
 */
export const CommentsScreen = ({ ...props }) => {
  const [state, setState] = useState({ data: [], nextToken: null });
  const [isLoading, setIsLoading] = useState(true);
  const [commentText, setCommentText] = useState("");
  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const params = useParams();
  const navigate = useNavigate();

  const openOptionsModal = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const res = await client.graphql({
        query: getCommentsByPost(
          state.nextToken ? `"${state.nextToken}"` : null,
          params.id,
          p.userData?.userId
        ),
      });

      console.log("res.data.getCommentsByPost.", res.data.comments.items);

      const thePostObject = res.data.thePost;

      setState({
        ...state,
        data: [...res.data.comments?.items],
        postData: [thePostObject],
        nextToken: res.data.nextToken,
        sendLoading: false,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const submitComment = async (postID, comment) => {
    const res = await client.graphql({
      query: createComment(
        `{categoryID: "${postID}", category: POST, comment: "${cleanText(
          comment
        )}"}`
      ),
    });
    // console.log("res", res);
  };

  const handleCommentSubmission = async (event) => {
    if (
      event.type === "click" ||
      (event.type === "keyup" && event.key === "Enter")
    ) {
      event.preventDefault();
      if (commentText.length >= 300 || commentText.trim() === "") return;
      setCommentText("");
      setState({
        ...state,
        sendLoading: true,
      });
      await submitComment(params.id, commentText);
      fetchData();
    } else {
      setCommentText(event.target.value);
    }
  };

  const handleDeleteComment = async (commentID) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this comment?"
    );
    if (confirmDelete) {
      const oldComments = state.data;
      const newComments = oldComments.filter((item) => item.id !== commentID);
      setState({
        ...state,
        data: newComments,
      });

      try {
        q.updateState(globalState);
        const res = await q.doQuery(
          "deleteComment",
          "Comment deleted",
          "An error occurred",
          {},
          p,
          commentID
        );
      } catch (error) {
        console.error(error);
      }
    }
    handleCloseMenu();
  };

  useEffect(() => {
    (async () => {
      fetchData();
    })();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"sm"}>
        <Box>
          <ScreenNavHeader title="Comments" />

          <ResponsiveBoxContainer
            pt={0}
            pb={2}
            sx={{
              padding: 0,
            }}
          >
            <div>
              {!state.postData &&
                [1].map((v, i) => (
                  <ResponsiveStack
                    sx={{
                      "&:hover": {
                        background: "none",
                      },
                    }}
                    key={`skeleton-activity-${i}`}
                    direction={"column"}
                    spacing={1}
                    mb={0}
                    py={2}
                    px={1}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      spacing={1}
                      sx={{ width: "100%" }}
                    >
                      <Skeleton
                        animation={"wave"}
                        variant="circular"
                        width={48}
                        height={48}
                      />
                      <Stack
                        direction={"row"}
                        spacing={1}
                        flexGrow={1}
                        justifyContent={"space-between"}
                      >
                        <Stack direction={"column"} spacing={1}>
                          <Skeleton variant="rounded" width={160} height={10} />
                          <Skeleton
                            variant="rounded"
                            width={100}
                            height={10}
                            sx={{ flexGrow: 1 }}
                          />
                        </Stack>
                        {/* <Skeleton variant="rounded" width={20} height={6} /> */}
                      </Stack>
                    </Stack>
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      width={"100%"}
                      height={170}
                    />
                  </ResponsiveStack>
                ))}
              {state.postData &&
                state.postData.map((itemObj, i) => {
                  if (!itemObj) return null;
                  const {
                    id,
                    activityID,
                    createdByID,
                    status,
                    text,
                    createdAt,
                    interest,
                    images,
                    link,
                    userObject,
                    activityObject,
                    postObject,
                    commentsCount,
                    reactions,
                    reactionsCount,
                    numberOfVotes,
                    sumOfVotes,
                    privacy,
                    postTagUsers,
                  } = itemObj;

                  const startTimestamp = activityObject
                    ? new Date(activityObject.activityStart)
                    : null;
                  const endTimestamp = activityObject
                    ? new Date(activityObject.activityEnd)
                    : null;
                  var start = { sDay: 0, sMonth: 0, sHour: 0, sMinute: 0 };
                  var end = { eDay: 0, eMonth: 0, eHour: 0, eMinute: 0 };

                  if (startTimestamp) {
                    start = {
                      sDay: startTimestamp.getDate(),
                      sMonth: startTimestamp.getMonth(),
                      sHour: String(startTimestamp.getHours()).padStart(2, "0"),
                      sMinute: String(startTimestamp.getMinutes()).padStart(
                        2,
                        "0"
                      ),
                    };
                  }
                  if (endTimestamp) {
                    end = {
                      eDay: endTimestamp.getDate(),
                      eMonth: endTimestamp.getMonth(),
                      eHour: String(endTimestamp.getHours()).padStart(2, "0"),
                      eMinute: String(endTimestamp.getMinutes()).padStart(
                        2,
                        "0"
                      ),
                    };
                  }

                  const { sDay, sMonth, sHour, sMinute } = start;
                  const { eDay, eMonth, eHour, eMinute } = end;

                  if (postObject) console.log("postObject", postObject);

                  return (
                    <>
                      <ResponsiveStack key={`feedcard-${i}`} pt={2} px={1}>
                        <ActivityPostCard
                          id={id}
                          deleteCallback={() => navigate("/")}
                          activityID={activityID}
                          userObject={userObject}
                          userAvatar={
                            userObject.images.items.length > 0 &&
                            userObject.images.items[0]?.url
                          }
                          userName={`${userObject.givenName} ${userObject.familyName}`}
                          userId={userObject?.id}
                          image={images?.items?.[0]?.url}
                          imagesData={images?.items}
                          text={text}
                          commentsCount={commentsCount}
                          reactionsCount={reactionsCount}
                          likeReactionID={reactions?.items?.[0]?.id}
                          liked={
                            reactions?.items?.[0]?.type === "HEART"
                              ? true
                              : false
                          }
                          shareType={
                            (activityObject && "activity") ||
                            (postObject && "post") ||
                            (link && "link") ||
                            null
                          }
                          createdAt={createdAt}
                          postTagUsers={postTagUsers}
                          rating={
                            Math.round(
                              (userObject.sumOfVotes /
                                userObject.numberOfVotes) *
                                100
                            ) / 100
                          }
                          activityObject={
                            activityObject && {
                              category:
                                activityObject.interest.parentInterest.title,
                              userName: `${activityObject.userObject.givenName} ${activityObject.userObject.familyName}`,
                              text: activityObject.description,
                              currency: activityObject.currency || "",
                              price: activityObject.price
                                ? parseFloat(
                                    activityObject.price
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : "FREE",
                              day: sDay,
                              month: sMonth,
                              privacy: activityObject.privacy,
                              startTime: `${sHour}:${sMinute}`,
                              endTime: `${eHour}:${eMinute}`,
                              participants: activityObject.numberOfParticipants,
                              rsvped:
                                activityObject.activityAttendance.items.length,
                              address: activityObject.address,
                              userAvatar:
                                activityObject?.userObject?.images?.items?.[0]
                                  ?.url,
                              attendees:
                                activityObject.activityAttendance.items.slice(
                                  0,
                                  5
                                ),
                              remainingDays: timestampdifference(
                                new Date(),
                                endTimestamp
                              ),
                            }
                          }
                          postObject={
                            postObject && {
                              imageSrc:
                                postObject.images?.items?.[0]?.url ||
                                postObject?.userObject?.images?.items?.[0]?.url,
                              username: `${postObject?.userObject?.givenName} ${postObject?.userObject?.familyName}`,
                              text:
                                `"${postObject.text}"` ||
                                postObject?.activityObject?.description,
                              type: postObject.activityObject && "Activity",
                              name: postObject?.activityObject?.name,
                            }
                          }
                          link={
                            link && {
                              imageSrc: link?.imageUrl,
                              name: link?.title,
                              text: " ",
                              type: "link",
                              url: link.url,
                            }
                          }
                        />
                      </ResponsiveStack>
                    </>
                  );
                })}
            </div>
          </ResponsiveBoxContainer>

          <Divider sx={{ mx: -3 }} />

          <Box>
            <Stack py={ !isLoading && !state.sendLoading ? 4 : 2 } direction={"row"} spacing={2}>
              {!isLoading && !state.sendLoading && (
                <>
                  <AvatarLink
                    to={`/profile/${globalState?.userData?.dynamoDB?.id}`}
                    src={globalState?.userData?.dynamoDB?.images?.items[0]?.url}
                    sx={{ width: 48, height: 48 }}
                  />
                  <Stack direction={"column"} sx={{ flexGrow: 1 }}>
                    <Stack direction="row" spacing={1} alignItems="flex-end">
                      <InputBase
                        onKeyUp={handleCommentSubmission}
                        onChange={(e) => setCommentText(e.target.value)}
                        multiline={true}
                        placeholder="Write a comment..."
                        value={commentText}
                        sx={{ flexGrow: 1 }}
                      />
                      <IconButton
                        sx={{
                          padding: 1,
                        }}
                        onClick={handleCommentSubmission}
                        disabled={
                          commentText.trim() === "" || commentText.length >= 300
                        }
                        size="small"
                      >
                        <SvgIcon>
                          <SendIcon />
                        </SvgIcon>
                      </IconButton>
                    </Stack>
                    {commentText?.length >= 250 && (
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{
                          color: commentText?.length >= 250 ? "red" : "inherit",
                        }}
                      >
                        Character count: {commentText?.length}/300
                      </Typography>
                    )}
                  </Stack>
                </>
              )}
            </Stack>

            {!isLoading && !state.sendLoading && state.data.length === 0 && (
              <>
                <Stack direction="column" alignItems={"center"}>
                  <SvgIcon sx={{ mb: 3 }}>
                    <SpeakerNotesOffOutlinedIcon />
                  </SvgIcon>
                  <Typography variant="h5" fontWeight={700} sx={{ mb: 2 }}>
                    No Comments
                  </Typography>
                  <Typography variant="h6">
                    Be the first to leave a comment.
                  </Typography>
                </Stack>
              </>
            )}

            {isLoading &&
              !state.sendLoading &&
              state.data.length === 0 &&
              [1].map((v, i) => (
                <Stack
                  key={`skeleton-activity-${i}`}
                  direction={"column"}
                  spacing={1}
                  mb={4}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={2}
                    sx={{ width: "100%" }}
                  >
                    <Skeleton
                      animation={"wave"}
                      variant="circular"
                      width={48}
                      height={48}
                    />
                    <Stack
                      direction={"row"}
                      spacing={1}
                      flexGrow={1}
                      justifyContent={"space-between"}
                    >
                      <Stack direction={"column"} spacing={1}>
                        <Skeleton variant="rounded" width={160} height={10} />
                        <Skeleton
                          variant="rounded"
                          width={100}
                          height={10}
                          sx={{ flexGrow: 1 }}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              ))}

            {state.sendLoading && (
              <Stack mt={0} mb={4} direction="row" spacing={2}>
                <Skeleton
                  variant="circular"
                  sx={{ width: 48, height: 48 }}
                ></Skeleton>
                <Stack direction={"column"} spacing={1}>
                  <Skeleton
                    animation={"wave"}
                    variant="rounded"
                    width={150}
                    height={12}
                  />
                  <Skeleton
                    animation={"wave"}
                    variant="rounded"
                    width={100}
                    height={10}
                  />
                </Stack>
              </Stack>
            )}

            {state.data.map((commentObj, i) => (
              <Stack
                key={`comment-${i}`}
                direction={"row"}
                spacing={2}
                alignItems={"flex-start"}
                mt={0}
                mb={4}
              >
                <AvatarLink
                  to={`/profile/${commentObj?.userObject?.id}`}
                  sx={{ width: 48, height: 48 }}
                  src={commentObj?.userObject?.images?.items?.[0]?.url}
                />
                <Stack direction={"column"} sx={{ flexGrow: 1 }}>
                  <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                    <ReactLink to={`/profile/${commentObj?.userObject?.id}`}>
                      <Typography variant="body1">
                        {commentObj?.userObject?.givenName}{" "}
                        {commentObj?.userObject?.familyName}
                      </Typography>
                    </ReactLink>
                    <Typography color="textSecondary" variant="caption">
                      · {timestampdifference(new Date(), commentObj?.createdAt)}
                    </Typography>
                  </Stack>
                  <Typography variant="body2">{commentObj?.comment}</Typography>
                </Stack>
                <IconButton onClick={openOptionsModal}>
                  <SvgIcon>
                    <EllipsisHorizontalIcon />
                  </SvgIcon>
                </IconButton>
                {/* Options Menu */}
                <Menu
                  sx={{ width: 200, padding: 0 }}
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  {commentObj?.userObject?.id !==
                    globalState?.userData?.userId && (
                    <MenuItem sx={{ width: 200 }} onClick={() => {}}>
                      Report Post
                    </MenuItem>
                  )}
                  {commentObj?.userObject?.id ===
                    globalState?.userData?.userId && (
                    <MenuItem
                      disabled={state.postIsDeleted}
                      sx={{ width: 200 }}
                      onClick={() => handleDeleteComment(commentObj.id)}
                    >
                      Delete Comment
                    </MenuItem>
                  )}
                </Menu>
              </Stack>
            ))}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export const CommentsScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
      <CommentsScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

CommentsScreen.propTypes = {};

CommentsScreen.defaultProps = {
  color: "primary",
};
