/* src/Protected.js */
import React, { useEffect } from 'react';
import { signIn, getCurrentUser } from 'aws-amplify/auth';
import { useNavigate } from "react-router-dom";

import Container from './Container'
function Protected(props) {

    const navigate = useNavigate();
    useEffect(() => {
        getCurrentUser()
        .catch(() => {
            navigate('/profile')
        });
    }, [])
    return (

        <Container>
            <h1>Protected route</h1>
        </Container>
    );
}
export default Protected