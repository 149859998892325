import React, { useEffect } from 'react';
import { useTheme, createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { StepperProgress } from '../basic/StepperProgress';
import PropTypes from 'prop-types';
import logoShape from '../assets/logo-shape.svg'

const theme = createTheme({
    palette: {
    },
    components: {
        // MuiTextField: {
        //   styleOverrides: {
        //     root: {
        //     },
        //   },
        // },
    },
});


const LogoBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: 72
  },
  [theme.breakpoints.down('sm')]: {
    width: 72
  }
}));

const ResponsiveSignUpBox = styled(Box)(({ theme }) => ({
  margin: '0 auto',
  [theme.breakpoints.down('lg')]: {
    width: 400
  },
  [theme.breakpoints.down('md')]: {
    width: 300
  }
}));


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const data = [
  {
    label: <><strong>Chat</strong> with <br/> people you like</>,
  },
  {
    label: <><strong>Create</strong> groups <br/> around your passions</>,
  },
  {
    label: <><strong>Discover</strong> more <br/> to do together</>,
  }
];

/**
 * Primary UI component for user interaction
 */

export const OnboardingCarousel = ({ ...props }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = data.length;

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  useEffect(()=>{ props.callback && props.callback(activeStep); }, [activeStep])

  return (
    <ThemeProvider theme={theme}>
      <Box 
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-around'}
      sx={{ 
        minHeight: '150px',
        width: '100%'
      }}>

      {/* <Box>
        <LogoBox 
          mb={4}
          sx={{
            width: 72,
            margin: '0 auto'
          }}>
          <img width="100%" src={logoShape} />
        </LogoBox>
      </Box> */}

        <AutoPlaySwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {data.map((step, index) => (
            <div className="xyz" key={`data-${index}`}>
              {Math.abs(activeStep - index) <= 2 ? (
                <ResponsiveSignUpBox
                  key={`AutoPlaySwipeableViews-${index}`}
                  sx={{
                    display: 'block',
                    overflow: 'hidden'
                  }}
                  alt={step.label}
                >
                    <Typography 
                    sx={{
                      height: 125,
                      margin: '0 auto'
                    }}
                    textAlign={'center'}
                    color="white"
                    variant='h4'>{data[activeStep].label}</Typography>
                </ResponsiveSignUpBox>
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>

        <Box display='flex' justifyContent="center">
          <StepperProgress 
          steps={[1,2,3]} 
          onClick={setActiveStep}
          step={activeStep}/>
        </Box>

      </Box>
    </ThemeProvider>
  );
}


OnboardingCarousel.propTypes = {
};

OnboardingCarousel.defaultProps = {};
