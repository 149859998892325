import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button as MuiButton,
  Container,
  IconButton,
  InputAdornment,
  Stack,
  SvgIcon,
  Typography,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import AppContext from "../../AppContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import { SearchTextField } from "../basic/SearchTextField";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import { Button } from "../basic/Button";
import { Switch } from "../basic/Switch";
import Search from "@mui/icons-material/Search";
import RoomIcon from "@mui/icons-material/Room";
import { generateClient } from "aws-amplify/api";
import searchActivitiesFuzzySorted from "./queries/searchActivitiesFuzzySorted";
import searchActivities from "./queries/searchActivities";
import monthmap from "../utils/monthmap";
import { keywordQueryActivities } from "./queries/siteSearch";

const client = generateClient();

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

/**
 * Primary UI component for user interaction
 */
export const SearchActivities = ({ ...props }) => {
  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const [state, setState] = useState({
    loading: true,
    inequality: "gte",
    runLoadActivitiesBySwitch: true,
  });
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      // Search fuzzy if keywords exist
      if (state.runLoadActivitiesBySwitch === true) {
        await loadActivitiesBySwitch(state.keyword ? true : false);
      }
    })();
  }, [
    globalState?.userData?.dynamoDB?.givenName,
    state.inequality,
    state.keyword,
  ]);

  var timeoutId = useRef(null);

  const loadActivitiesBySwitch = async (fuzzy = false) => {
    if (
      // globalState?.activitiesData ||
      !globalState?.userData?.dynamoDB?.givenName
    ) {
      return;
    }
    //if(state.query) return;

    setState({
      ...state,
      loading: true,
    });

    try {
      const res = await client.graphql({
        query: fuzzy
          ? searchActivitiesFuzzySorted(
              `keyword: "${keywordQueryActivities(state.keyword)}", 

              inequality: "${state.inequality}", 
              limit: 10,
              rangeTrue: true,
              direction: ${state.inequality === "gte" ? "asc" : "desc"}`
            )
          : searchActivities(
              `sort: {
                field: activityStart, 
                direction: ${state.inequality === "gte" ? "asc" : "desc"}
              }, 
              filter: {
                activityStart: {${
                  state.inequality
                }: "${new Date().toISOString()}"}
              },
              userId: "${globalState?.userData?.userId}",
              limit: 10`
            ),
      });

      if (
        res.data.searchActivitiesFuzzySorted?.items ||
        res.data.search?.items
      ) {

        setGlobalState({
          ...globalState,
          activitiesData: fuzzy
            ? [...res.data.searchActivitiesFuzzySorted?.items]
            : [...res.data.search?.items],
          nextToken: fuzzy
            ? res.data.searchActivitiesFuzzySorted?.nextToken
            : res.data.search?.nextToken,
        });
      }

      // setState({ ...state, loading: false });
    } catch (error) {
      console.error("Error fetching data:", error);
      // setState({ ...state, loading: false });
    }

    // Set the timeout id in state
    setState({ ...state, loading: false });
  };

  const onSearchActivities = async (event) => {
    const keyword = event.currentTarget.value;

    if (!keyword) {
      setState({
        ...state,
        loading: true,
        keyword: "",
        runLoadActivitiesBySwitch: true,
      });
      return;
    }

    // Clear previous search invocation if called prior to timeout
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    // Set new timeout id
    timeoutId.current = setTimeout(async () => {
      setState({
        ...state,
        loading: true,
        keyword: keyword,
      });

      // try {
      const res = await client.graphql({
        query: searchActivitiesFuzzySorted(
          `keyword: "${keywordQueryActivities(keyword)}", 
          currentDateTime: "${new Date().toISOString()}", 
          inequality: "${state.inequality}", 
          limit: 10,
          direction: ${state.inequality === "gte" ? "asc" : "desc"},
          rangeTrue: true
          `
        ),
      });

      setGlobalState({
        ...globalState,
        activitiesData: [...res.data.searchActivitiesFuzzySorted?.items],
        fuzzy: true,
        nextToken: res.data.searchActivitiesFuzzySorted?.nextToken,
      });

      // console.log("res.data.searchActivitiesFuzzySorted", res.data.searchActivitiesFuzzySorted);
      // } catch (error) {
      //   console.error("Error fetching data:", error);
      // }

      // Set the timeout id in state
      setState({
        ...state,
        keyword: keyword,
        loading: false,
        runLoadActivitiesBySwitch: false,
      });
    }, [800]);
  };

  const loadMore = async () => {
    setState({
      ...state,
      loadingMore: true,
    });

    try {
      const res = await client.graphql({
        query: globalState?.fuzzy
          ? searchActivitiesFuzzySorted(
              `keyword: "${keywordQueryActivities(state.keyword)}", 
              currentDateTime: "${new Date().toISOString()}", 
              inequality: "${state.inequality}", 
              limit: 10,
              rangeTrue: true,
              direction: ${state.inequality === "gte" ? "asc" : "desc"}
              ` +
                (globalState?.nextToken
                  ? `, nextToken: "${globalState?.nextToken}"`
                  : ``)
            )
          : searchActivities(
              `sort: {
                field: activityStart, 
                direction: ${state.inequality === "gte" ? "asc" : "desc"}
              }, 
              filter: {
                activityStart: {${
                  state.inequality
                }: "${new Date().toISOString()}"}
              },
              userId: "${globalState?.userData?.userId}",
              limit: 10` +
                (globalState?.nextToken &&
                  `, nextToken: "${globalState?.nextToken}"`)
            ),
      });

      setGlobalState({
        ...globalState,
        activitiesData: [
          ...globalState?.activitiesData,
          ...(res.data.search?.items ||
            res.data.searchActivitiesFuzzySorted?.items),
        ],
        nextToken:
          res.data.search?.nextToken ||
          res.data.searchActivitiesFuzzySorted?.nextToken,
      });

      // setState({ ...state, loading: false });
    } catch (error) {
      console.error("Error fetching data:", error);
      // setState({ ...state, loading: false });
    }

    // Set the timeout id in state
    setState({ ...state, loadingMore: false });
  };

  const selectActivityHandler = (activityObj) => {
    return () => {

      p.submission.selectedActivityAttachment = activityObj;

      setGlobalState({
        ...globalState,
        submission: p.submission
      });
      navigate("/create-post");
    };
  };

  const onSwitch = (switchIndex) => {
    // Set to gte if switch is upcoming and to lte if recent
    setState({
      ...state,
      loading: true,
      inequality: switchIndex === 0 ? "gte" : "lte",
      runLoadActivitiesBySwitch: true,
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"sm"}>
        <Box>
          <ScreenNavHeader title="Search Activities" to="/create-post" />

          <Box mb={2.5}>
            <SearchTextField
              fullWidth={true}
              size="medium"
              placeholder="Search"
              sx={{ borderRadius: "100%" }}
              onChange={onSearchActivities}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="Search" edge="end">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>

        <Stack py={2} mb={2} direction={"row"} justifyContent={"space-between"}>
          <Switch
            defaultIs0={true}
            disabled={state.switchDisabled || state.loading}
            width={200}
            size="small"
            optionA={"Upcoming"}
            optionB={"Recent"}
            callback={onSwitch}
          ></Switch>
          <Button>Create Activity</Button>
        </Stack>

        {state.loading &&
          [1, 2, 3, 4, 5, 6, 7, 8, 9].map((v, i) => (
            <Stack
              key={`skeleton-${i}`}
              mb={2}
              px={1}
              spacing={1}
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Stack spacing={1} direction={"column"}>
                <Skeleton variant="rounded" sx={{ height: 24, width: 250 }} />
                <Skeleton variant="rounded" sx={{ height: 24, width: 400 }} />
              </Stack>
              <Skeleton variant="rounded" sx={{ height: 64, width: 48 }} />
            </Stack>
          ))}

        {!state.loading &&
          globalState?.activitiesData
            ?.filter(
              (activityObj) =>
                activityObj.address && activityObj.address !== "undefined"
            )
            .map((activityObj, i) => (
              <Box
                key={`activityObj-${i}`}
                fullWidth
                onClick={selectActivityHandler(activityObj)}
                variant="text"
                component={MuiButton}
                color="text.primary"
                sx={{
                  textTransform: "none",
                  textAlign: "left",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                mb={4}
              >
                <Stack
                  sx={{ width: "100%" }}
                  direction="row"
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Stack direction="column" spacing={1}>
                    <Typography variant="body1">{activityObj.name}</Typography>
                    <Stack direction="row">
                      <SvgIcon sx={{ width: 20, height: 20, color: "#75777F" }}>
                        <RoomIcon />
                      </SvgIcon>
                      <Typography variant="body2">
                        {activityObj.address}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack direction={"row"} alignItems={"center"} spacing={2}>
                    <Stack direction="column">
                      <Typography variant="body1">
                        {new Date(activityObj.activityStart).toLocaleTimeString(
                          "en-US",
                          { hour12: false, hour: "2-digit", minute: "2-digit" }
                        )}
                      </Typography>
                      <Typography variant="body1">
                        {new Date(activityObj.activityEnd).toLocaleTimeString(
                          "en-US",
                          { hour12: false, hour: "2-digit", minute: "2-digit" }
                        )}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={"column"}
                      sx={{ background: "#B9DEF3", borderRadius: 2 }}
                      p={1}
                    >
                      <Typography variant="h5">
                        {new Intl.NumberFormat("en-US", {
                          minimumIntegerDigits: 2,
                        }).format(
                          new Date(activityObj.activityStart).getDate()
                        )}
                      </Typography>
                      <Typography variant="body2" textAlign={"center"}>
                        {monthmap[
                          new Date(activityObj.activityStart).getMonth()
                        ].slice(0, 3)}
                        {/* /{`${new Date(
                        activityObj.activityStart
                      ).getFullYear()}`.slice(2, 4)} */}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            ))}

        <Stack alignItems={"center"}>
          {globalState?.activitiesData?.length > 0 &&
            !state.loadingMore &&
            !state.loading &&
            globalState?.nextToken && (
              <Button onClick={loadMore}>Load more</Button>
            )}

          {state.loadingMore && <CircularProgress sx={{ color: "#2D9CDB" }} />}
        </Stack>
      </Container>
    </ThemeProvider>
  );
};

export const SearchActivitiesLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
      <SearchActivities {...props} />
    </ResponsiveLayoutSidebar>
  );
};

SearchActivities.propTypes = {};

SearchActivities.defaultProps = {
  color: "primary",
};
