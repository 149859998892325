import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button as MuiButton,
  ButtonGroup,
  Container,
  InputAdornment,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { Select } from "../basic/Select";
import { TextField } from "../basic/TextField";
import { AppDialog } from "../complex/AppDialog";
import PropTypes from "prop-types";
import currencies from "../utils/currencies.json";

const paymentCasingMap = {
  "CASH ONSITE": "Cash Onsite",
  "CARD ONSITE": "Card Onsite",
  ONLINE: "Online",
};

function capitalizeWords(sentence) {
  if(!sentence) return;
  return sentence.split(' ').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');
}

function PaymentDialog(props) {
  const [state, setState] = useState({
    buttonDisabled: true,
    payment: {
      paymentMethod: props?.p.submission?.paymentMethod,
      currency: props?.p.submission?.currency,
      price: props?.p.submission?.price,
      link: {
        ...props?.p.submission?.payment?.link,
        url: props?.p.submission?.payment?.link?.url,
      },
    },
  });

  const localP = useRef({ payment: {} });

  useEffect(() => {
    localP.current.payment = {
      paymentMethod: props?.p.submission?.payment?.paymentMethod,
      currency: props?.p.submission?.payment?.currency,
      price: props?.p.submission?.payment?.price,
      link: props?.p.submission?.payment?.link,
    }

    if (!state.payment?.currency || !state.payment?.paymentMethod) {
      setState({
        ...state,
        payment: localP.current.payment,
      });
    }
  }, []);

  useEffect(() => {
    if (state.doValidate === false) return;

    if (
      localP.current.payment?.currency &&
      (localP.current.payment?.price === 0 ||
        localP.current.payment?.price > 0) &&
      localP.current.payment?.paymentMethod
    ) {
      if (localP.current.payment?.paymentMethod === "ONLINE") {
        if (localP.current.payment?.link?.url?.length >= 7) {
          setState({
            ...state,
            buttonDisabled: false,
            doValidate: false,
          });
        } else {
          setState({
            ...state,
            buttonDisabled: true,
            doValidate: false,
          });
        }
      } else {
        setState({
          ...state,
          buttonDisabled: false,
          doValidate: false,
        });
      }
    } else {
      setState({
        ...state,
        buttonDisabled: true,
        doValidate: false,
      });
    }
  }, [state]);

  const confirmPaymentDetails = () => {
    props.onConfirmCallback(state.payment);
  };

  return (
    <AppDialog
      {...props}
      buttonDisabled={state.buttonDisabled}
      maxButtonWidth={275}
      onConfirm={confirmPaymentDetails}
    >
      <Box pt={2} px={3} sx={{ maxWidth: 275, margin: "0 auto" }}>
        <Stack direction={"row"} spacing={2} mb={2}>
          <Select
            callback={(v) => {
              localP.current.payment = {
                ...localP.current.payment,
                currency: v,
              };
              setState({
                ...state,
                payment: localP.current.payment,
                doValidate: true,
              });
            }}
            value={state.payment?.currency}
            label="Currency*"
            options={Object.keys(currencies)}
          />
          <TextField
            onChange={(event) => {
              localP.current.payment = {
                ...localP.current.payment,
                price: event.currentTarget.value,
              };
              setState({
                ...state,
                payment: localP.current.payment,
                doValidate: true,
              });
            }}
            type="number"
            isRequired
            value={state.payment?.price}
            size="small"
            label={"Price*"}
          />
        </Stack>
        <Stack direction="column" spacing={2}>
          <Select
            callback={(v) => {
              localP.current.payment = {
                ...localP.current.payment,
                paymentMethod: v.toUpperCase(),
              };
              setState({
                ...state,
                payment: localP.current.payment,
                doValidate: true,
              });
            }}
            value={capitalizeWords(paymentCasingMap[state.payment?.paymentMethod?.replace("_", " ")])}
            label="Payment Method*"
            options={["Cash Onsite", "Card Onsite", "Online"]}
          />
          {state.payment?.paymentMethod === "Online".toUpperCase() && (
            <TextField
              placeholder="https://"
              fullWidth
              size="small"
              label="Link"
              onChange={(event) => {
                localP.current.payment.link = {
                  ...localP.current.payment?.link,
                  url: event.currentTarget.value,
                };

                setState({
                  ...state,
                  payment: {
                    ...localP.current.payment,
                    link: {
                      url: localP.current.payment.link?.url
                    }
                  },
                  doValidate: true,
                });
              }}
              value={state?.payment?.link?.url}
            />
          )}
        </Stack>
      </Box>
    </AppDialog>
  );
}

PaymentDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default PaymentDialog;
