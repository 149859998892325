const onCreatePost = () => `subscription MySubscription {
  onCreatePost {
      createdByID
      reactionsCount
      status
      text
      commentsCount
      createdAt
      id
      images {
        items {
          assetEntityID
          url
          id
          index
        }
      }
      userObject {
        id
        phoneNumber
        givenName
        familyName
        numberOfVotes
        sumOfVotes
        images(limit: 1) {
          items {
            id
            index
            url
          }
        }
      }
      link {
        imageUrl
        title
        url
      }
      postTagUsers {
        items {
          user {
            id
            givenName
            familyName
            phoneNumber
            images(limit: 1) {
              items {
                id
                url
                index
              }
            }
          }
        }
      }
      postObject {
        createdByID
        reactionsCount
        status
        text
        commentsCount
        createdAt
        id
        activityObject {
        activityEnd
        activityStart
        address
        description
        currency
        id
        name
        locationDescription
        numberOfParticipants
        price
        privacy
        interest {
          id
          parentID
          parentInterest {
            id
            title
          }
        }
        userObject {
          id
          phoneNumber
          givenName
          familyName
          numberOfVotes
          sumOfVotes
          images(limit: 1) {
            items {
              id
              index
              url
            }
          }
        }
        activityAttendance {
          items {
            activityID
            createdByID
            status
            initStatus
            user {
              id
              familyName
              givenName
              phoneNumber
              images(limit: 1) {
                items {
                  id
                  index
                  url
                }
              }
            }
          }
        }
      }
        postTagUsers {
        items {
          user {
            id
            givenName
            familyName
            phoneNumber
            images(limit: 1) {
              items {
                id
                url
                index
              }
            }
          }
        }
      }
        userObject {
          id
          phoneNumber
          givenName
          familyName
          numberOfVotes
          sumOfVotes
          images {
            items {
              id
              index
              url
            }
          }
        }
        link {
          imageUrl
          title
          url
        }
        images {
          items {
            assetEntityID
            url
            id
            index
          }
        }
      }
      activityObject {
        status
        createdByID
        activityEnd
        activityStart
        address
        description
        currency
        id
        name
        locationDescription
        numberOfParticipants
        price
        privacy
        interest {
          id
          parentID
          parentInterest {
            id
            title
          }
        }
        userObject {
          id
          phoneNumber
          givenName
          familyName
          numberOfVotes
          sumOfVotes
          images(limit: 1) {
            items {
              id
              index
              url
            }
          }
        }
        activityAttendance {
          items {
            activityID
            createdByID
            status
            initStatus
            user {
              id
              familyName
              givenName
              phoneNumber
              images(limit: 1) {
                items {
                  id
                  index
                  url
                }
              }
            }
          }
        }
      }
    }
}`;

export default onCreatePost;