import React, { useContext, useState } from "react";
import {
  Link as ReactLink,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  Stack,
  SvgIcon,
} from "@mui/material";
import AppContext from "../../AppContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MuiListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import { OnboardingScreenResponsiveLayout } from "../complex/OnboardingScreenResponsiveLayout";
import { SearchTextField } from "../basic/SearchTextField";
import Search from "@mui/icons-material/Search";
import countryCodes from "./countryCodes.json";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import Fuse from "fuse.js";
import transitions from "./transitions.json";

const fuseOptions = {
  // isCaseSensitive: false,
  // includeScore: false,
  // shouldSort: true,
  // includeMatches: false,
  // findAllMatches: false,
  // minMatchCharLength: 1,
  // location: 0,
  threshold: 0.4,
  // distance: 100,
  // useExtendedSearch: false,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  // fieldNormWeight: 1,
  keys: ["name", "code", "code2", "dial_code"],
};
const fuse = new Fuse(countryCodes, fuseOptions);

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

export const ListItemButton = styled(MuiListItemButton)(
  ({ theme }) => `
  &.Mui-selected,
  &.Mui-selected:hover{
    background: #d6eefb
  }
  &:hover {
    background: transparent;
  }
`
);

const ResponsiveList = styled(List)(({ theme }) => ({
  maxHeight: "calc(100vh - 240px)",
  overflowY: "scroll",
  [theme.breakpoints.down("md")]: {
    maxHeight: "calc(100vh - 180px)",
  },
}));

/**
 * Primary UI component for user interaction
 */
export const InternationalCodeSelectionScreen = ({ ...props }) => {
  const [globalState, setGlobalState, q, p] = useContext(AppContext);

  const navigate = useNavigate();
  const location = useLocation();

  const [state, setState] = useState({
    searchPattern: "",
    data: countryCodes,
  });

  const handleCountrySearch = (event) => {
    setState({
      ...state,
      searchPattern: event.currentTarget.value,
      data: fuse.search(event.currentTarget.value),
    });
  };

  const selectCountryCodeHandler = (countryObj) => {
    return () => {
      p.authFlow = {
        ...p.authFlow,
        countryObj,
      };
      navigate(transitions[location.pathname].confirm);
    };
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"sm"}>
        <Box>
          <Stack
            sx={{ minWidth: 300 }}
            mb={2}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            spacing={2}
          >
            <IconButton
              onClick={() => navigate(transitions[location.pathname].return)}
              sx={{ marginRight: -4 }}
            >
              <SvgIcon>
                <ArrowBackIcon />
              </SvgIcon>
            </IconButton>

            <SearchTextField
              size="medium"
              placeholder="Search countries"
              sx={{ borderRadius: "100%", flexGrow: 1 }}
              onChange={handleCountrySearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="Search" edge="end">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <ResponsiveList>
            {((state.data.length > 0 && state.data) || countryCodes).map(
              (objOriginal, i) => {
                var countryObj = objOriginal.item || objOriginal;

                return (
                  <ListItem
                    key={`list-item-${i}`}
                    disablePadding
                    secondaryAction={countryObj["dial_code"]}
                  >
                    <ListItemButton
                      onClick={selectCountryCodeHandler(countryObj)}
                      selected={false}
                    >
                      <ListItemIcon>
                        <span
                          className={`fi fi-${countryObj[
                            "code"
                          ]?.toLowerCase()}`}
                        ></span>
                      </ListItemIcon>
                      <ListItemText primary={countryObj["name"]} />
                    </ListItemButton>
                  </ListItem>
                );
              }
            )}
          </ResponsiveList>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export const InternationalCodeSelectionScreenLayoutMain = (props) => {
  return (
    <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
      <InternationalCodeSelectionScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

export const InternationalCodeSelectionScreenLayout = (props) => {
  return (
    <OnboardingScreenResponsiveLayout
      reponsivePositionBoxSx={{
        position: "static",
        top: "initial",
        left: "initial",
        transform: "translate(0,0) !important",
      }}
    >
      <InternationalCodeSelectionScreen {...props} />
    </OnboardingScreenResponsiveLayout>
  );
};

InternationalCodeSelectionScreen.propTypes = {};

InternationalCodeSelectionScreen.defaultProps = {
  color: "primary",
};
