const onCreateChatChannelMessage = () => `subscription MySubscription {
  onCreateChatChannelMessage {
    id
    content {
      data
      text
    }
    channelID
    createdByID
    updatedAt
    createdBy {
      id
    }
  }
}`;

export default onCreateChatChannelMessage;
