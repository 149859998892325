const listInterests = (criteria) => `query MyQuery {
  listInterests(${criteria}) {
    items {
      id
      image
      note
      parentID
      title
      type
      parentInterest {
        id
        image
        note
        parentID
        title
        type
      }
    }
    nextToken
  }
}`;

export default listInterests;