import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../AppContext";
import { Link as ReactLink } from "react-router-dom";
import {
  Box,
  Button as MUIButton,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button } from "../basic/Button";
import PropTypes from "prop-types";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import { ResponsiveToaster } from "../basic/ResponsiveToaster.jsx";
import { OnboardingScreenResponsiveLayout } from "../complex/OnboardingScreenResponsiveLayout";
import { styled } from "@mui/system";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import IosShareIcon from "@mui/icons-material/IosShare";
import copyToClipboard from "../utils/copyToClipboard.js";

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

const StyledBox = styled(Box)(
  ({ theme }) => `
  &.MuiBox-root {
    max-width: 320px;
    border-radius: 16px;
    border: solid 2px #B9DEF3;
    padding: ${theme.spacing(4)};
    text-transform: none;
    text-align: left;
  }
  &.selected {
    opacity: 1;
    background: rgba(25, 118, 210, 0.04)
  }
  &.deselected {
    opacity: 0.5;
  }
`
);

/**
 * Primary UI component for user interaction
 */
export const ProfileCreatedScreen = ({ ...props }) => {
  const [state, setState] = useState({
    toasterMessage: "",
  });
  const [globalState, setGlobalState, q, p] = useContext(AppContext);

  useEffect(() => {
    p.journey = "complete";
    p.loggedIn = true;
    p.onboarded = true;

    (async () => {
      // q.updateState(globalState);
      // const newP = await q.getUserData(p);
      // p.userData = newP.userData;
      setGlobalState({
        ...globalState,
        onboarded: true
      })
    })();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"xs"}>
        <Stack spacing={8}>
          <Stack alignItems={"center"}>
            <Box
              mb={2}
              sx={{ width: 128 }}
              component={"img"}
              src={`${process.env.REACT_APP_WEBSITE}/green-check.png`}
            />
            <Typography
              gutterBottom
              textAlign={"center"}
              fontWeight={700}
              variant="h3"
            >
              Enjoy
              <br />
              Sociogram
            </Typography>
            <Typography textAlign={"center"} variant="body1">
              You have successfully registered. Welcome to Sociogram.
            </Typography>
          </Stack>

          <Button
            component={ReactLink}
            to="/"
            startIcon={ChevronRightIcon}
            fullWidth
          >
            Finish
          </Button>
        </Stack>
      </Container>
      <ResponsiveToaster
        severity={"info"}
        toasterMessage={state.toasterMessage}
        setToasterMessage={() => setState({ ...state, toasterMessage: "" })}
      />
    </ThemeProvider>
  );
};

export const ProfileCreatedScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
      <ProfileCreatedScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

export const ProfileCreatedScreenOnboardingLayout = (props) => {
  return (
    <OnboardingScreenResponsiveLayout hideLogo={true}>
      <ProfileCreatedScreen {...props} />
    </OnboardingScreenResponsiveLayout>
  );
};

ProfileCreatedScreen.propTypes = {};

ProfileCreatedScreen.defaultProps = {
  color: "primary",
};
