import React from "react";
import ReactDOM from "react-dom";
import { RouterProvider } from "react-router-dom";
import "./index.css";
import Router from "./Router";
// import Router2 from "./Router2";
// import Mqtt5 from './stories/complex/PubSubMonitor.jsx';
import { Amplify } from "aws-amplify";
import config from "./amplifyconfiguration.json";

import { createRoot } from "react-dom/client";

Amplify.configure(config, {
  Storage: {
    S3: {
      prefixResolver: () => "app",
    },
  },
});
const domNode = document.getElementById("root");
const root = createRoot(domNode);
// root.render(<RouterProvider router={Router} />);

// root.render(<Mqtt5 />);
root.render(<Router />);
