import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../../AppContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  Skeleton,
} from "@mui/material";
import { Button } from "../basic/Button";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import SelectLocationMapComponent from "../complex/SelectLocationMapComponent";

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

/**
 * Primary UI component for user interaction
 */
export const SelectLocationScreen = () => {

  const [state, setState] = useState();
  const [globalState, setGlobalState, q, p] = useContext(AppContext);

  const navigate = useNavigate();

  useEffect(() => {
    p.prevPage = p.currentPage || "select-location";
    p.currentPage = "select-location";
  }, []);

  const selectLocationCallback = (location) => {
    p.submission = {
      ...p.submission,
      location: {
        ...p.submission?.location,
        q: location.name,
        longitude: location.lng,
        latitude: location.lat,
      },
    };

    setState({
      ...state,
      submission: p.submission,
    })
  }

  const confirmLocationCallback = () => {
    if (p.prevPage === "create-activity") {
      navigate("/create-activity");
    } else if (p.prevPage === "edit-activity") {
      navigate(`/edit-activity/${p.editActivityID}`);
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"sm"}>
        <Box mb={0}>
          <ScreenNavHeader title="Choose the location">
            <Button onClick={confirmLocationCallback} size={"large"} disabled={!state?.submission?.location?.q && !p?.submission?.location?.q}>
              Confirm
            </Button>
          </ScreenNavHeader>
        </Box>
      </Container>
      <Box>
        <SelectLocationMapComponent
         p={p}
         selectLocationCallback={selectLocationCallback} />
      </Box>
    </ThemeProvider>
  );
};

export const SelectLocationScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar pb={0} waitForUserData={props.waitForUserData}>
      <SelectLocationScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

SelectLocationScreen.propTypes = {};

SelectLocationScreen.defaultProps = {
  color: "primary",
};
