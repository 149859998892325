import React from "react";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import { IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PropTypes from "prop-types";

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

/**
 * Primary UI component for user interaction
 */
export const ScreenNavHeader = ({ ...props }) => {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <Stack
        sx={{ ...props.sx, minWidth: 300 }}
        mb={2}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <IconButton
          disabled={props.backButtonDisabled || false}
          onClick={() => {
            if (props.onClick) {
              return props.onClick();
            } else {
              props.to ? navigate(props.to) : navigate(-1);
            }
          }}
          sx={{ marginRight: -4 }}
        >
          <SvgIcon>
            <ArrowBackIcon />
          </SvgIcon>
        </IconButton>

        <Typography
          sx={{ margin: "0 auto" }}
          textAlign={"center"}
          variant="h6"
          fontWeight={700}
        >
          {props.title}
        </Typography>

        {props.children}
      </Stack>
    </ThemeProvider>
  );
};

ScreenNavHeader.propTypes = {
  title: PropTypes.string,
};

ScreenNavHeader.defaultProps = {
  title: "Choose category",
};
