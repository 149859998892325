const updateConnectionStatus = (sortedUserIDSPK, status) => `mutation MyMutation {
  updateConnectionStatus(input: {status: ${status}, token: "${sortedUserIDSPK}"}) {
    id
    requesterID
    updatedAt
  }
}`;

// Example sortedUserIDSPK (sender#receiver)
// a749bf95-77c0-4178-b7ac-5bb78f1e137d#e7d31b48-48ef-47e5-9807-1ba0709b996b

export default updateConnectionStatus;