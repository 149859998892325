import * as React from "react";
import { Tabs as MUITabs } from "@mui/material";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { styled } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import "./tabs.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2D9CDB",
    },
    error: {
      main: "#EE3F3F",
    },
    // secondary: {
    //     main: '#0C2F43'
    // }
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: "none",
          // backgroundColor: '#ff0000',
          // '& .MuiTabs-root': {
          //     background: 'green'
          // },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 700,
          color: "#0C2F43",
          borderTop: "solid 3px transparent",
          borderRadius: "16px 16px 0px 0px",
          "&.Mui-selected": {
            color: "#0C2F43",
            borderTop: "solid 3px #8EC8E9",
            background: "#F9F9F9",
          },
          // '& .MuiTab-root': {
          //     background: 'green'
          // },
          // '&.Mui-selected': {
          //     backgroundColor: 'green', // Custom background color for selected Tab
          //     color: '#fff', // Text color for selected Tab
          // },
        },
      },
    },
  },
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
            background: "#F9F9F9",
            minHeight: {xs: "calc(100vh - 224px)", sm: "calc(100vh - 194px)"},
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Tabs = (props) => {
  const [value, setValue] = React.useState(props.defaultTab || 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (props.callback) {
      props?.callback(newValue);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
          <MUITabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              style: { display: "none" },
            }}
          >
            {props.tabs?.map((tabObj, i) => (
              <Tab
                sx={{ width: `${(1 / props.tabs?.length) * 100}%` }}
                label={`${tabObj.label} ${
                  typeof tabObj?.resultsCount === "number"
                    ? `(${tabObj?.resultsCount})`
                    : ""
                }`}
                {...a11yProps(i)}
              />
            ))}
          </MUITabs>
        </Box>
        {props.tabs?.map((tabObj, i) => (
          <CustomTabPanel
            sx={{
              marginLeft: { xs: "-14px", sm: "-24px", md: `-24px` },
              marginRight: { xs: "-14px", sm: "-24px", md: `-24px` },
            }}
            value={value}
            index={i}
          >
            {tabObj.content}
          </CustomTabPanel>
        ))}
      </Box>
    </ThemeProvider>
  );
};

Tabs.propTypes = {
  /**
   * Optional click handler
   */
  onChange: PropTypes.func,
};

Tabs.defaultProps = {};
