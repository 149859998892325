import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button as MuiButton,
  Container,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  SvgIcon,
  Typography,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import AppContext from "../../AppContext";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import { SearchTextField } from "../basic/SearchTextField";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import { ActivityPostCard } from "../complex/ActivityPostCard";
import { Button } from "../basic/Button";
import { Switch } from "../basic/Switch";
import timestampdifference from "../utils/timestampdifference";
import Search from "@mui/icons-material/Search";
import { generateClient } from "aws-amplify/api";
import searchPostsFuzzy from "./queries/searchPostsFuzzy";
import listPostsByStatus from "./queries/listPostsByStatus";
import monthmap from "../utils/monthmap";

const client = generateClient();

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

const ResponsiveStack = styled(Stack)(({ theme }) => ({
  //maxWidth: 530,
  // marginLeft: 'auto',
  // marginRight: 'auto',
  // borderBottom: 'solid 1px rgba(0,0,0,0.15)',
  background: "white",
  [theme.breakpoints.down("md")]: {
    paddingLeft: 16,
    paddingRight: 16,
    borderBottom: 0,
    marginBottom: 8,
  },
  "&:hover": {
    cursor: "pointer",
    // background: 'rgba(0,0,0,0.025)'
  },
}));

const ResponsiveDivider = styled(Divider)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

/**
 * Primary UI component for user interaction
 */
export const SearchPosts = ({ ...props }) => {
  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const [state, setState] = useState({
    loading: true,
    inequality: "gte",
    runLoadPostsBySwitch: true,
  });
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!globalState?.userData?.dynamoDB?.givenName) return;
      // Search fuzzy if keywords exist
      if (state.runLoadPostsBySwitch === true) {
        await loadPostsBySwitch(state.keyword ? true : false);
      }
    })();
  }, [
    globalState?.userData?.dynamoDB?.givenName,
    state.inequality,
    state.keyword,
  ]);

  var timeoutId = useRef(null);

  const loadPostsBySwitch = async (fuzzy = false) => {
    if (
      // globalState?.postsData ||
      !globalState?.userData?.dynamoDB?.givenName
    ) {
      return;
    }
    //if(state.query) return;

    setState({
      ...state,
      loading: true,
    });

    try {
      const res = await client.graphql({
        query: fuzzy
          ? searchPostsFuzzy(
              state.keyword,
              globalState?.userData?.userId,
              state.nextToken ? `"${state.nextToken}"` : ""
            )
          : listPostsByStatus(
              globalState?.userData?.userId,
              state.nextToken ? `"${state.nextToken}"` : ""
            ),
      });

      // console.log("res", res.data.listPostsByStatus);

      if (res.data.searchPostsFuzzy || res.data.listPostsByStatus?.items) {
        setGlobalState({
          ...globalState,
          postsData: fuzzy
            ? [...res.data.searchPostsFuzzy]
            : [...res.data.listPostsByStatus?.items],
        });

        setState({
          ...state,
          loading: false,
          nextToken: fuzzy
            ? res.data.searchPostsFuzzy?.nextToken
            : res.data.listPostsByStatus?.nextToken,
        });
      }

      // setState({ ...state, loading: false });
    } catch (error) {
      console.error("Error fetching data:", error);
      // Set the timeout id in state
      setState({ ...state, loading: false });
      // setState({ ...state, loading: false });
    }

  };

  const onSearchPosts = async (event) => {
    const keyword = event.currentTarget.value;

    if (!keyword) {
      setState({
        ...state,
        loading: true,
        keyword: "",
        runLoadPostsBySwitch: true,
      });
      return;
    }

    // Clear previous search invocation if called prior to timeout
    if (timeoutId.current) {
      // console.log("canceling", timeoutId.current);
      clearTimeout(timeoutId.current);
    }

    // Set new timeout id
    timeoutId.current = setTimeout(async () => {
      setState({
        ...state,
        loading: true,
        keyword: keyword,
      });

      // try {
      const res = await client.graphql({
        query: searchPostsFuzzy(
          keyword,
          globalState?.userData?.userId,
          state.nextToken ? `"${state.nextToken}"` : null
        ),
      });

      setGlobalState({
        ...globalState,
        postsData: [...res.data?.searchPostsFuzzy],
        fuzzy: true,
      });

      // Set the timeout id in state
      setState({
        ...state,
        keyword: keyword,
        loading: false,
        runLoadPostsBySwitch: false,
        nextToken: res.data?.searchPostsFuzzy?.nextToken,
      });
    }, [800]);
  };

  const loadMore = async () => {
    setState({
      ...state,
      loadingMore: true,
    });

    try {
      const res = await client.graphql({
        query: globalState?.fuzzy
          ? searchPostsFuzzy(
              state.keyword,
              globalState?.userData?.userId,
              state.nextToken ? `"${state.nextToken}"` : null
            )
          : listPostsByStatus(
              globalState?.userData?.userId,
              state.nextToken ? `"${state.nextToken}"` : null
            ),
      });

      setGlobalState({
        ...globalState,
        postsData: [
          ...globalState?.postsData,
          ...(res.data.listPostsByStatus?.items || res.data.searchPostsFuzzy),
        ],
      });

      setState({
        ...state,
        loadingMore: false,
        nextToken:
          res.data.listPostsByStatus?.nextToken ||
          res.data.searchPostsFuzzy?.nextToken,
      });

      // setState({ ...state, loading: false });
    } catch (error) {
      console.error("Error fetching data:", error);
      // setState({ ...state, loading: false });
      // Set the timeout id in state
      setState({ ...state, loadingMore: false });
    }
  };

  const selectActivityHandler = (postObj) => {
    return () => {
      p.submission.selectedPostAttachment = postObj;

      setGlobalState({
        ...globalState,
        submission: p.submission,
      });
      navigate("/create-post");
    };
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"sm"}>
        <Box>
          <ScreenNavHeader title="Search Posts" to="/create-post" />

          <Box mb={2.5}>
            <SearchTextField
              fullWidth={true}
              size="medium"
              placeholder="Search"
              sx={{ borderRadius: "100%" }}
              onChange={onSearchPosts}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="Search" edge="end">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>

        {/* <Stack py={2} mb={2} direction={"row"} justifyContent={"end"}>
          <Button>Create Post</Button>
        </Stack> */}

        {state.loading &&
          [1, 2, 3, 4, 5, 6, 7, 8, 9].map((v, i) => (
            <ResponsiveStack
              sx={{ "&:hover": { background: "none" } }}
              key={`skeleton-activity-${i}`}
              direction={"column"}
              spacing={1}
              mb={0}
              py={2}
              px={4}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={1}
                sx={{ width: "100%" }}
              >
                <Skeleton
                  animation={"wave"}
                  variant="circular"
                  width={48}
                  height={48}
                />
                <Stack
                  direction={"row"}
                  spacing={1}
                  flexGrow={1}
                  justifyContent={"space-between"}
                >
                  <Stack direction={"column"} spacing={1}>
                    <Skeleton variant="rounded" width={160} height={10} />
                    <Skeleton
                      variant="rounded"
                      width={100}
                      height={10}
                      sx={{ flexGrow: 1 }}
                    />
                  </Stack>
                  {/* <Skeleton variant="rounded" width={20} height={6} /> */}
                </Stack>
              </Stack>
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={"100%"}
                height={170}
              />
            </ResponsiveStack>
          ))}

        {!state.loading &&
          globalState?.postsData?.map((itemObj, i) => {
            const {
              id,
              createdByID,
              status,
              text,
              createdAt,
              interest,
              images,
              link,
              userObject,
              nextToken,
              activityObject,
              postObject,
              commentsCount,
              reactions,
              reactionsCount,
              numberOfVotes,
              sumOfVotes,
              privacy,
              postTagUsers,
            } = itemObj;

            const startTimestamp = activityObject
              ? new Date(activityObject.activityStart)
              : null;
            const endTimestamp = activityObject
              ? new Date(activityObject.activityEnd)
              : null;
            var start = { sDay: 0, sMonth: 0, sHour: 0, sMinute: 0 };
            var end = { eDay: 0, eMonth: 0, eHour: 0, eMinute: 0 };

            if (startTimestamp) {
              start = {
                sDay: startTimestamp.getDate(),
                sMonth: startTimestamp.getMonth(),
                sHour: String(startTimestamp.getHours()).padStart(2, "0"),
                sMinute: String(startTimestamp.getMinutes()).padStart(2, "0"),
              };
            }
            if (endTimestamp) {
              end = {
                eDay: endTimestamp.getDate(),
                eMonth: endTimestamp.getMonth(),
                eHour: String(endTimestamp.getHours()).padStart(2, "0"),
                eMinute: String(endTimestamp.getMinutes()).padStart(2, "0"),
              };
            }

            const { sDay, sMonth, sHour, sMinute } = start;
            const { eDay, eMonth, eHour, eMinute } = end;

            return (
              <>
                <ResponsiveStack
                  onClick={selectActivityHandler(itemObj)}
                  key={`feedcard-${i}`}
                  pt={2}
                  px={4}
                >
                  <ActivityPostCard
                    id={id}
                    userAvatar={
                      userObject.images.items.length > 0 &&
                      userObject.images.items[0]?.url
                    }
                    userName={`${userObject?.givenName} ${userObject?.familyName}`}
                    image={images?.items?.[0]?.url}
                    imagesData={images?.items}
                    text={text}
                    commentsCount={commentsCount}
                    reactionsCount={reactionsCount}
                    likeReactionID={reactions?.items?.[0]?.id}
                    liked={
                      reactions?.items?.[0]?.type === "HEART" ? true : false
                    }
                    shareType={
                      (activityObject && "activity") ||
                      (postObject && "post") ||
                      (link && "link") ||
                      null
                    }
                    createdAt={createdAt}
                    postTagUsers={postTagUsers}
                    rating={
                      Math.round(
                        (userObject.sumOfVotes / userObject.numberOfVotes) * 100
                      ) / 100
                    }
                    activityObject={
                      activityObject && {
                        category: activityObject.interest.parentInterest.title,
                        userName: `${activityObject.userObject.givenName} ${activityObject.userObject.familyName}`,
                        text: activityObject.description,
                        currency: activityObject.currency || "",
                        price: activityObject.price
                        ? parseFloat(
                            activityObject.price
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "FREE",
                        day: sDay,
                        month: sMonth,
                        privacy: activityObject.privacy,
                        startTime: `${sHour}:${sMinute}`,
                        endTime: `${eHour}:${eMinute}`,
                        participants: activityObject.numberOfParticipants,
                        rsvped: activityObject.activityAttendance.items.length,
                        address: activityObject.address,
                        userAvatar:
                          activityObject.userObject.images.items &&
                          activityObject.userObject.images.items[0]?.url,
                        attendees:
                          activityObject.activityAttendance.items.slice(0, 2),
                        remainingDays: timestampdifference(
                          new Date(),
                          endTimestamp
                        ),
                      }
                    }
                    postObject={
                      postObject && {
                        imageSrc:
                          postObject?.activityObject?.userObject?.images
                            ?.items?.[0]?.url,
                        username:
                          postObject.activityObject &&
                          `${postObject.activityObject.userObject.givenName} ${postObject.activityObject.userObject.familyName}`,
                        text:
                          postObject.text ||
                          (postObject.activityObject &&
                            postObject.activityObject.description),
                        type: postObject.activityObject && "Activity",
                        name:
                          postObject.activityObject &&
                          postObject.activityObject.name,
                      }
                    }
                    link={
                      link && {
                        imageSrc: link?.imageUrl,
                        name: link?.title,
                        text: " ",
                        type: "link",
                        url: link.url,
                      }
                    }
                  />
                </ResponsiveStack>
                {i < globalState?.postsData?.length - 1 && (
                  <ResponsiveDivider />
                )}
              </>
            );
          })}

        <Stack alignItems={"center"}>
          {globalState?.postsData?.length > 0 &&
            !state.loadingMore &&
            !state.loading &&
            state.nextToken && <Button onClick={loadMore}>Load more</Button>}

          {state.loadingMore && <CircularProgress sx={{color: "#2D9CDB"}}/>}
        </Stack>
      </Container>
    </ThemeProvider>
  );
};

export const SearchPostsLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
      <SearchPosts {...props} />
    </ResponsiveLayoutSidebar>
  );
};

SearchPosts.propTypes = {};

SearchPosts.defaultProps = {
  color: "primary",
};
