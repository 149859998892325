import React, { useContext, useState } from "react";
import {
  Avatar,
  Box,
  Container,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton as MuiListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Stack,
  Skeleton,
} from "@mui/material";
import AppContext from "../../AppContext";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import CheckIcon from "@mui/icons-material/Check";
import Search from "@mui/icons-material/Search";
import { SearchTextField } from "../basic/SearchTextField";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import { generateClient } from "aws-amplify/api";
import { keywordQueryUsers } from '../utils/keywordQueryUsers';
import searchUsersFuzzy from "./queries/searchUsersFuzzy";

const client = generateClient();

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

export const ListItemButton = styled(MuiListItemButton)(
  ({ theme }) => `
  &.Mui-selected,
  &.Mui-selected:hover{
    background: #d6eefb
  }
  &:hover {
    background: transparent;
  }
`
);

const ListItemButtonStateful = (props) => {
  // const [selected, setSelected] = React.useState(props.selected || false);

  // const selectItem = (evt) => {
  //   setSelected(!selected)
  // }

  return (
    <ListItemButton
      onClick={props.onClick}
      selected={props.selected}
      value={props.text}
      id={props.id}
      name={props.name}
      image={props.image}
    >
      {props.selected && (
        <ListItemIcon>
          <CheckIcon />
        </ListItemIcon>
      )}
      <Stack spacing={1} direction={"row"} alignItems={"center"}>
        {props.children}
        <ListItemText primary={props.text} sx={{ paddingLeft: 1 }} />
      </Stack>
    </ListItemButton>
  );
};

/**
 * Primary UI component for user interaction
 */
export const AddPeopleScreen = ({ ...props }) => {
  const [state, setState] = useState({
    loading: false,
  });
  const [globalState, setGlobalState] = useContext(AppContext);

  const selectItem = (event) => {
    const newSelectedUsers =
      globalState?.selectedUsers?.length > 0
        ? [...globalState?.selectedUsers]
        : [];

    const newSelectedUsersData =
      globalState?.selectedUsersData?.length > 0
        ? [...globalState?.selectedUsersData]
        : [];

    const id = event.currentTarget.getAttribute("id");
    const name = event.currentTarget.getAttribute("name");
    const image = event.currentTarget.getAttribute("image");

    if (newSelectedUsers?.includes(id)) {
      const idToRemove = newSelectedUsers.indexOf(id);
      newSelectedUsers.splice(idToRemove, 1);
      newSelectedUsersData.splice(idToRemove, 1);
    } else {
      newSelectedUsers.push(id);
      newSelectedUsersData.push({ id, name, image });
    }
    setGlobalState({
      ...globalState,
      selectedUsers: newSelectedUsers,
      selectedUsersData: newSelectedUsersData,
    });
  };

  const searchUsers = async (event) => {
    const keyword = event.currentTarget.value;

    // Clear previous search invocation if called prior to timeout
    if (state.timeoutId) {
      clearTimeout(state.timeoutId);
    }

    // Set new timeout id
    const newTimeoutId = setTimeout(async () => {
      if (!keyword) {
        setState({ ...state, query: "" });
        return;
      } else {
        setState({
          ...state,
          loading: true,
          query: keyword,
        });
      }

      try {
        const res = await client.graphql({
          query: searchUsersFuzzy(keywordQueryUsers(keyword)),
        });

        setGlobalState({
          ...globalState,
          data: [...res.data.searchUsersFuzzy],
          nextToken: res.data.searchUsersFuzzy?.nextToken,
        });

        setState({ ...state, query: keyword, loading: false });

        // console.log("res.data.searchUsersFuzzy", res.data.searchUsersFuzzy);
      } catch (error) {
        console.error("Error fetching data:", error);
        setState({ ...state, query: keyword, loading: false });
      }
    }, [800]);

    // Set the timeout id in state
    setState({ ...state, timeoutId: newTimeoutId });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box mb={2}>
        <SearchTextField
          fullWidth={true}
          size="medium"
          placeholder="Search people"
          sx={{ borderRadius: "100%" }}
          onChange={searchUsers}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="Search" edge="end">
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box
        sx={{
          width: "100%",
          minWidth: 400,
          bgcolor: "background.paper",
        }}
      >
        {state.loading &&
          [1, 2].map((v, i) => (
            <Stack
              direction="row"
              px={2}
              mb={2}
              spacing={2}
              alignItems="center"
            >
              <Skeleton
                variant="circular"
                sx={{ width: 42, height: 42 }}
              ></Skeleton>
              <Skeleton variant="rounded" width={150} height={12}></Skeleton>
            </Stack>
          ))}

        {state.query && !state.loading && globalState?.data?.length === 0 && (
          <Typography>No users found with name "{state.query}"</Typography>
        )}

        {!state.loading && (
          <nav aria-label="people">
            <List>
              {state?.query &&
                globalState?.data?.map((userObj, i) => (
                  <ListItem key={`list-item-${i}`} disablePadding>
                    <ListItemButtonStateful
                      id={userObj.id}
                      name={`${userObj.givenName} ${userObj.familyName}`}
                      image={userObj.images?.items?.[0]?.url}
                      selected={globalState?.selectedUsers?.includes(
                        userObj.id
                      )}
                      onClick={selectItem}
                      text={`${userObj.givenName} ${userObj.familyName}`}
                    >
                      <Avatar src={userObj.images?.items?.[0]?.url} />
                    </ListItemButtonStateful>
                  </ListItem>
                ))}

              {globalState?.selectedUsersData?.length > 0 &&
                !state.query &&
                globalState?.selectedUsersData?.map((userObj, i) => (
                  <ListItem disablePadding key={`list-item-selected-${i}`}>
                    <ListItemButtonStateful
                      id={userObj.id}
                      name={userObj.name}
                      image={userObj.image}
                      selected={globalState?.selectedUsers?.includes(
                        userObj.id
                      )}
                      onClick={selectItem}
                      text={`${userObj.name}`}
                    >
                      <Avatar src={userObj.image} />
                    </ListItemButtonStateful>
                  </ListItem>
                ))}
            </List>
          </nav>
        )}
      </Box>
    </ThemeProvider>
  );
};

export const AddPeopleScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
      <AddPeopleScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

AddPeopleScreen.propTypes = {};

AddPeopleScreen.defaultProps = {
  color: "primary",
};
